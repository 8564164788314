import { Formik } from "formik";
import { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { updateAssociationAsync } from "../../../../../slices/config/configSlice";
function EditAssociationModal(props) {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const id = props?.data[0]?.id;
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="">
          <>
            <Modal.Title className="me-5"> Edit Association</Modal.Title>
          </>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              name: props?.data[0]?.name,
              about: props?.data[0]?.about,
              conference_id: props?.data[0]?.conference?.id,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              about: Yup.string().required("About is required"),
              conference_id: Yup.string().required("Conference is required"),
            })}
            onSubmit={(values) => {
              dispatch(
                updateAssociationAsync({ association_id: id, data: values })
              );
            }}
            validateOnChange
            validateOnBlur
            validateOnSubmit
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              values,
              handleChange,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Name"
                        className="my-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        {errors.name && touched.name ? (
                          <small className="text-danger">{errors.name}</small>
                        ) : null}
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Conference"
                        className="my-2"
                      >
                        <Form.Select
                          aria-label="Floating label select example"
                          name="conference_id"
                          value={values.conference_id}
                          onChange={handleChange}
                        >
                          <option value="">Select Conference</option>
                          {config?.allConferenceResponse?.data?.items.map(
                            (conference, index) => (
                              <option key={index} value={conference.id}>
                                {conference.name}
                              </option>
                            )
                          )}
                        </Form.Select>
                        {errors.conference_id && touched.conference_id ? (
                          <small className="text-danger">
                            {errors.conference_id}
                          </small>
                        ) : null}
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="About"
                        className="my-3"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="About"
                          name="about"
                          value={values.about}
                          onChange={handleChange}
                          style={{ height: "200px" }}
                        />
                        {errors.about && touched.about ? (
                          <small className="text-danger">{errors.about}</small>
                        ) : null}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  type="submit"
                  className=" my-4 py-3  border-0  bg-color-1"
                >
                  <span className="text-center">Update</span>
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      ;
    </>
  );
}
export default EditAssociationModal;
