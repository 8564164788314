import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/style/style.scss";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./pages/auth/login";
import ErrorPage from "./error-page";
import { Provider } from "react-redux";
import Loading from "./util/loading";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import store from "./store/store";
import SuperAdminDashboard from "./pages/admin/dashboard";
import Church from "./pages/admin/church/church";
import AddChurch from "./pages/admin/church/add-church";
import ViewChurch from "./pages/admin/church/view-church";
import ViewMember from "./pages/admin/church/view-member";
import ChurchAdminDashboard from "./pages/church-admin/dashboard";
import Members from "./pages/church-admin/member";
import UserProfile from "./pages/members/profile";
import MembershipRegistration from "./pages/members/membership";
import UserRegister from "./pages/members/user-register";
import UserVerify from "./pages/members/user-verify";
import UserNotify from "./pages/members/user-notify";
import UserLogin from "./pages/members/user-login";
import MemberDashboard from "./pages/members/dashboard";
import UserWelcome from "./pages/members/user-welcome";
import SuperAdminSettings from "./pages/admin/settings/settings";
import UserForgetPassword from "./pages/members/user-forget-password";
import UserResetPassword from "./pages/members/user-reset-password";
import UserAddChurch from "./pages/church-admin/user-add-church";
import AdminUsers from "./pages/admin/user";
import LandingPage from "./pages/landing-page/landing-page";
import PrivacyPolicy from "./pages/landing-page/privacy-policy";
import TermsAndConditions from "./pages/landing-page/terms-and-conditions";
import FinancialPolicy from "./pages/landing-page/financial-policy-terms";
const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin-login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin-dashboard",
    element: <SuperAdminDashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin-users",
    element: <AdminUsers />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin-settings",
    element: <SuperAdminSettings />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/churches",
    element: <Church />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/add-church",
    element: <AddChurch />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/view-church",
    element: <ViewChurch />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-member",
    element: <ViewMember />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/church-admin-dashboard",
    element: <ChurchAdminDashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/church-admin-members",
    element: <Members />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/user-profile",
    element: <UserProfile />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/membership-registration",
    element: <MembershipRegistration />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/user-registration",
    element: <UserRegister />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/user-verify",
    element: <UserVerify />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/user-notify",
    element: <UserNotify />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/user-login",
    element: <UserLogin />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/user-forget-password",
    element: <UserForgetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: `/user-reset-password/:token`,
    element: <UserResetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/user-dashboard",
    element: <MemberDashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/user-welcome",
    element: <UserWelcome />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/user-add-church",
    element: <UserAddChurch />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/privacy-policy-page",
    element: <PrivacyPolicy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/terms-and-conditions-page",
    element: <TermsAndConditions />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/financial-policy-page",
    element: <FinancialPolicy />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer hideProgressBar />
      <Loading />
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
