import React from "react";
import Navigation from "./navigation";
import Footer from "./footer";

function LandingLayout({ children }) {
  return (
    <div>
      <Navigation />
      <div>{children}</div>
      <Footer />
    </div>
  );
}

export default LandingLayout;
