import {
  DeleteRequest,
  GetRequest,
  PostRequest,
  PutRequest,
} from "../../util/apiMethods";

export const getUsers = async (role, status, page, size) => {
  const response = await GetRequest(
    `manage/users?role=${role}&status=${status}&page=${page}&size=${size}`
  );
  return response;
};

export const getUserById = async (user_id, detailed) => {
  const response = await GetRequest(
    `manage/users/${user_id}?detailed=${detailed}`
  );
  return response;
};

export const getUserRoles = async () => {
  const response = await GetRequest("manage/users/roles");
  return response;
};

export const activateChurchMember = async (member_id, detailed) => {
  const response = await PutRequest(
    `moderate/church/members/${member_id}/membership/activate?detailed=${detailed}`
  );
  return response;
};

export const getAuthUserProfileStatus = async () => {
  const response = await GetRequest("/users/me/profile_completeness_status");
  return response;
};

export const getAllUsersDetails = async (detailed, page, size) => {
  const response = await GetRequest(
    `/users?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const createUser = async (body) => {
  const response = await PostRequest("/users", body);
  return response;
};

export const updateUser = async (id, body) => {
  const response = await PutRequest(`/users/${id}`, body);
  return response;
};

export const viewUserActivityLog = async (id, page) => {
  const response = await GetRequest(`/users/${id}/activity_log?page=${page}`);
  return response;
};

export const deleteUser = async (id) => {
  const response = await DeleteRequest(`/users/${id}`);
  return response;
};
export const activateUser = async (id) => {
  const response = await PutRequest(`/users/${id}/activate`);
  return response;
};

export const deactivateUser = async (id) => {
  const response = await PutRequest(`/users/${id}/deactivate`);
  return response;
};

export const memberRegister = async (body) => {
  const response = await PostRequest("/registrations/members", body);
  return response;
};

export const pastorRegister = async (body) => {
  const response = await PostRequest("/registrations/pastors", body);
  return response;
};

export const getAuthUser = async (detailed) => {
  const response = await GetRequest(`/users/me?detailed=${detailed}`);
  return response;
};

export const updateAuthUserbasicInfo = async (body) => {
  const response = await PutRequest("/users/me/basic_profile", body);
  return response;
};

export const updateAuthUserChurchInfo = async (body) => {
  const response = await PutRequest("users/me/church", body);
  return response;
};
