import { Button, Col, Image, Offcanvas, Row } from "react-bootstrap";
import bible from "../../assets/Baptist One Components/bible.png";
import hymnal from "../../assets/Baptist One Components/hymnal.png";
import news from "../../assets/Baptist One Components/news.png";
import forum from "../../assets/Baptist One Components/forum.png";
import ministries from "../../assets/Baptist One Components/ministries.png";
import donation from "../../assets/Baptist One Components/donation.png";
import media from "../../assets/Baptist One Components/media.png";
import findChurch from "../../assets/Baptist One Components/find-church.png";
import resources from "../../assets/Baptist One Components/resources.png";
import discipleship from "../../assets/Baptist One Components/discipleship.png";
import profile from "../../assets/Baptist One Components/profile-2.png";
import settings from "../../assets/Baptist One Components/settings.png";
import convention from "../../assets/Baptist One Components/convention.png";
import supportIcon from "../../assets/images/support-icon.png";
import feedbackIcon from "../../assets/images/feedback-icon.png";
import logoutIcon from "../../assets/images/logout-iocn.png";
import logo from "../../assets/images/baptust-one-logo-2.png";
import { logoutAsync } from "../../slices/auth/authSlice";
import { useDispatch } from "react-redux";

function Sidebar(props) {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(logoutAsync());

    await localStorage.removeItem("token");
    await localStorage.removeItem("user");
    await localStorage.removeItem("loading");

    window.location.href = "/user-login";
  };

  return (
    <div>
      <Offcanvas {...props} className="offcanvas-size-xl">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Image src={logo} width={150} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mt-5">
            <Row>
              <Col md={3} xs={3} sm={3}>
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={bible} />
                  </div>

                  <small className="">Bible</small>
                </div>
              </Col>
              <Col md={3} xs={3} sm={3}>
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={hymnal} />
                  </div>

                  <small className="">Hymnal</small>
                </div>
              </Col>
              <Col md={3} xs={3} sm={3}>
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={news} />
                  </div>

                  <small className="">News</small>
                </div>
              </Col>
              <Col md={3} xs={3} sm={3}>
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={forum} />
                  </div>

                  <small className="">Forum</small>
                </div>
              </Col>
            </Row>
          </div>

          <div className="my-4">
            <Row>
              <Col md={3} xs={3} sm={3}>
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={ministries} />
                  </div>

                  <small className="">Ministries</small>
                </div>
              </Col>
              <Col md={3} xs={3} sm={3}>
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={donation} />
                  </div>

                  <small className="">Donation</small>
                </div>
              </Col>
              <Col md={3} xs={3} sm={3}>
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={media} />
                  </div>

                  <small className="">Media</small>
                </div>
              </Col>

              <Col md={3} xs={3} sm={3} className="">
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={findChurch} />
                  </div>

                  <small className="">Find Church</small>
                </div>
              </Col>
            </Row>
          </div>

          <div className="my-4">
            <Row>
              <Col md={3} xs={3} sm={3}>
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={resources} />
                  </div>

                  <small className="">Resources</small>
                </div>
              </Col>
              <Col md={3} xs={3} sm={3}>
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={discipleship} />
                  </div>

                  <small className="">Discipleship</small>
                </div>
              </Col>
              <Col md={3} xs={3} sm={3}>
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={profile} />
                  </div>

                  <small className="">Profile</small>
                </div>
              </Col>

              <Col md={3} xs={3} sm={3} className="">
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={settings} />
                  </div>

                  <small className="">Settings</small>
                </div>
              </Col>
            </Row>
          </div>

          <div className="my-4">
            <Row>
              <Col md={3} xs={3} sm={3}>
                <div div className="bg-transparent border-0 text-center">
                  <div className="bg-color-3 dash-icon">
                    <Image src={convention} />
                  </div>

                  <small className="">Convention</small>
                </div>
              </Col>
            </Row>
          </div>
          <hr />
          <Row className="bg-color-3 py-3">
            <Col md={3} xs={3}>
              <div div className="bg-transparent border-0 text-center">
                <div className="">
                  <Image src={supportIcon} />
                </div>

                <small className="">Support</small>
              </div>
            </Col>
            <Col md={3} xs={3}>
              <div div className="bg-transparent border-0 text-center">
                <div className="">
                  <Image src={feedbackIcon} />
                </div>

                <small className="">Feedback</small>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6} xs={6}>
              <Button
                className="mt-4"
                variant="transparent"
                onClick={() => handleLogout()}
              >
                {" "}
                <span className="text-danger">
                  {" "}
                  <Image src={logoutIcon} /> Logout
                </span>
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
export default Sidebar;
