import { GetRequest, PutRequest } from "../../util/apiMethods";

export const getAllChurchMembers = async (detailed, status, page, size) => {
  const response = await GetRequest(
    `/moderate/church/members?status=${status}&detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getChurchMemberById = async (member_id, detailed) => {
  const response = await GetRequest(
    `/moderate/church/members/${member_id}?detailed=${detailed}`
  );
  return response;
};

export const activateChurchMembership = async (member_id, detailed) => {
  const response = await PutRequest(
    `moderate/church/members/${member_id}/membership/activate?detailed=${detailed}`
  );
  return response;
};
