import { DeleteRequest, PostRequest, PutRequest } from "../../util/apiMethods";

export const login = async (credentials, platform) => {
  const response = await PostRequest(
    `/auth/login?platform=${platform}`,
    credentials
  );
  return response;
};

export const forgetPassword = async (credentials) => {
  const response = await PostRequest("/auth/reset", credentials);
  return response;
};

export const resetPassword = async (credentials, token) => {
  const response = await PutRequest(`/auth/reset/${token}`, credentials);
  return response;
};

export const verifyEmail = async (credentials, token) => {
  const response = await PutRequest(
    `/auth/email_verification/${token}`,
    credentials
  );
  return response;
};

export const verifyEmailOtp = async (credentials) => {
  const response = await PostRequest("/auth/email_verification", credentials);
  return response;
};

export const logout = async () => {
  const response = await DeleteRequest("/logout");
  return response;
};
