import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addAssociation,
  addConference,
  addConvention,
  getAllUserAssociation,
  getAllUserConference,
  getAllUserConvention,
  getAssociationByConferenceSerialNumber,
  getAssociationByConventionSerialNumber,
  getAssociationById,
  getAssociationBySerialNumber,
  getAssociationsByConferenceId,
  getAssociationsByConventionId,
  getChurchesByAssociationId,
  getChurchesByAssociationSerialNumber,
  getChurchesByConferenceId,
  getChurchesByConferenceSerialNumber,
  getChurchesByConventionId,
  getChurchesByConventionSerialNumber,
  getConferenceByConventionSerialNumber,
  getConferenceById,
  getConferenceBySerialNumber,
  getConferencesByConventionId,
  getConventionById,
  getConventionBySerialNumber,
  updateAssociation,
  updateConference,
  updateConvention,
} from "../../services/config/configService";

// CONVENTION

export const fetchAllConventionAsync = createAsyncThunk(
  "convention/fetch/all",
  async ({ detailed, page, size }) => {
    const response = await getAllUserConvention(detailed, page, size);
    return response;
  }
);

export const addConventionAsync = createAsyncThunk(
  "convention/add",
  async (values) => {
    const response = await addConvention(values);
    return response;
  }
);

export const updateConventionAsync = createAsyncThunk(
  "convention/update",
  async ({ convention_id, data }) => {
    const response = await updateConvention(convention_id, data);
    return response;
  }
);

export const getConventionByIdAsync = createAsyncThunk(
  "convention/fetch/by/id",
  async ({ convention_id, detailed }) => {
    const response = await getConventionById(convention_id, detailed);
    return response;
  }
);

export const getAssociatonByConventionIdAsync = createAsyncThunk(
  "convention/fetch/association",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getAssociationsByConventionId(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getChurchByConventionIdAsync = createAsyncThunk(
  "convention/fetch/church",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getChurchesByConventionId(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getConferenceByConventionIdAsync = createAsyncThunk(
  "convention/fetch/conference",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getConferencesByConventionId(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getConventionBySerialNumberAsync = createAsyncThunk(
  "convention/fetch/serial/number",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getConventionBySerialNumber(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getAssociationByConventionSerialNumberAsync = createAsyncThunk(
  "convention/fetch/association/serial/number",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getAssociationByConventionSerialNumber(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getChurchByConventionSerialNumberAsync = createAsyncThunk(
  "convention/fetch/church/serial/number",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getChurchesByConventionSerialNumber(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getConferenceByConventionSerialNumberAsync = createAsyncThunk(
  "convention/fetch/conference/serial/number",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getConferenceByConventionSerialNumber(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

// CONFERENCE

export const fetchAllConferenceAsync = createAsyncThunk(
  "conference/fetch/all",
  async ({ detailed, page, size }) => {
    const response = await getAllUserConference(detailed, page, size);
    return response;
  }
);

export const addConferenceAsync = createAsyncThunk(
  "conference/add",
  async (values) => {
    const response = await addConference(values);
    return response;
  }
);

export const updateConferenceAsync = createAsyncThunk(
  "conference/update",
  async ({ conference_id, data }) => {
    const response = await updateConference(conference_id, data);
    return response;
  }
);

export const getConferenceByIdAsync = createAsyncThunk(
  "conference/fetch/by/id",
  async ({ convention_id, detailed }) => {
    const response = await getConferenceById(convention_id, detailed);
    return response;
  }
);

export const getAssociationsByConferenceIdAsync = createAsyncThunk(
  "conference/fetch/association",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getAssociationsByConferenceId(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getChurchByConferenceIdAsync = createAsyncThunk(
  "conference/fetch/church",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getChurchesByConferenceId(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getConferenceBySerialNumberAsync = createAsyncThunk(
  "conference/fetch/serial/number",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getConferenceBySerialNumber(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getAssociationByConferenceSerialNumberAsync = createAsyncThunk(
  "conference/fetch/association/serial/number",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getAssociationByConferenceSerialNumber(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getChurchByConferenceSerialNumberAsync = createAsyncThunk(
  "conference/fetch/church/serial/number",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getChurchesByConferenceSerialNumber(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

// ASSOCIATION

export const fetchAllAssociationAsync = createAsyncThunk(
  "association/fetch/all",
  async ({ detailed, page, size }) => {
    const response = await getAllUserAssociation(detailed, page, size);
    return response;
  }
);

export const addAssociationAsync = createAsyncThunk(
  "association/add",
  async (values) => {
    const response = await addAssociation(values);
    return response;
  }
);

export const updateAssociationAsync = createAsyncThunk(
  "association/update",
  async ({ association_id, data }) => {
    const response = await updateAssociation(association_id, data);
    return response;
  }
);

export const getAssociationByIdAsync = createAsyncThunk(
  "association/fetch/by/id",
  async ({ convention_id, detailed }) => {
    const response = await getAssociationById(convention_id, detailed);
    return response;
  }
);

export const getChurchByAssociationIdAsync = createAsyncThunk(
  "association/fetch/church",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getChurchesByAssociationId(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getAssociationBySerialNumberAsync = createAsyncThunk(
  "association/fetch/serial/number",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getAssociationBySerialNumber(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

export const getChurchByAssociationSerialNumberAsync = createAsyncThunk(
  "association/fetch/church/serial/number",
  async ({ convention_id, detailed, page, size }) => {
    const response = await getChurchesByAssociationSerialNumber(
      convention_id,
      detailed,
      page,
      size
    );
    return response;
  }
);

const configSlice = createSlice({
  name: "config",
  initialState: {
    // CONVENTION

    addConventionResponse: {},
    allConventionResponse: {},
    getConventionByIdResponse: {},
    updateConventionResponse: {},
    getAssociationsByConventionIdResponse: {},
    getChurchesByConventionIdResponse: {},
    getConferencesByConventionIdResponse: {},
    getConventionBySerialNumberResponse: {},
    getAssociationByConventionSerialNumberResponse: {},
    getChurchesByConventionSerialNumberResponse: {},
    getConferenceByConventionSerialNumberResponse: {},

    // CONFERENCE
    addConferenceResponse: {},
    allConferenceResponse: {},
    getConferenceByIdResponse: {},
    updateConferenceResponse: {},
    getAssociationsByConferenceIdResponse: {},
    getChurchesByConferenceIdResponse: {},
    getConferenceBySerialNumberResponse: {},
    getAssociationByConferenceSerialNumberResponse: {},
    getChurchesByConferenceSerialNumberResponse: {},

    // ASSOCIATION

    addAssociationResponse: {},
    allAssociationResponse: {},
    getAssociationByIdResponse: {},
    updateAssociationResponse: {},
    getChurchesByAssociationIdResponse: {},
    getAssociationBySerialNumberResponse: {},
    getChurchesByAssociationSerialNumberResponse: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    // CONVENTION
    builder.addCase(fetchAllConventionAsync.fulfilled, (state, action) => {
      state.allConventionResponse = action.payload;
    });

    builder.addCase(addConventionAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.addConventionResponse = action.payload;
        state.allConventionResponse.data.items.unshift({
          id: action.payload?.data?.id,
          sn: action.payload?.data?.sn,
          name: action.payload?.data?.name,
          about: action.payload?.data?.about,
          country: action.payload?.data?.country,
        });
        toast.success(action?.payload?.message);
      }
    });
    builder.addCase(addConventionAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });

    builder.addCase(updateConventionAsync.fulfilled, (state, action) => {
      toast.success(action?.payload?.message);
      state.updateConventionResponse = action.payload;
    });
    builder.addCase(updateConventionAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });

    builder.addCase(getConventionByIdAsync.fulfilled, (state, action) => {
      state.getConventionByIdResponse = action.payload;
    });
    builder.addCase(getConventionByIdAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(
      getAssociatonByConventionIdAsync.fulfilled,
      (state, action) => {
        state.getAssociationsByConventionIdResponse = action.payload;
      }
    );
    builder.addCase(
      getAssociatonByConventionIdAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );

    builder.addCase(getChurchByConventionIdAsync.fulfilled, (state, action) => {
      state.getChurchesByConventionIdResponse = action.payload;
    });
    builder.addCase(getChurchByConventionIdAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(
      getConferenceByConventionIdAsync.fulfilled,
      (state, action) => {
        state.getConferencesByConventionIdResponse = action.payload;
      }
    );
    builder.addCase(
      getConferenceByConventionIdAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );

    builder.addCase(
      getConventionBySerialNumberAsync.fulfilled,
      (state, action) => {
        state.getConferencesByConventionIdResponse = action.payload;
      }
    );
    builder.addCase(
      getConventionBySerialNumberAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );

    builder.addCase(
      getAssociationByConventionSerialNumberAsync.fulfilled,
      (state, action) => {
        state.getAssociationByConventionSerialNumberResponse = action.payload;
      }
    );
    builder.addCase(
      getAssociationByConventionSerialNumberAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );

    builder.addCase(
      getChurchByConventionSerialNumberAsync.fulfilled,
      (state, action) => {
        state.getAssociationByConventionSerialNumberResponse = action.payload;
      }
    );
    builder.addCase(
      getChurchByConventionSerialNumberAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );

    builder.addCase(
      getConferenceByConventionSerialNumberAsync.fulfilled,
      (state, action) => {
        state.getConferenceByConventionSerialNumberResponse = action.payload;
      }
    );
    builder.addCase(
      getConferenceByConventionSerialNumberAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );

    // CONFERENCE
    builder.addCase(fetchAllConferenceAsync.fulfilled, (state, action) => {
      state.allConferenceResponse = action.payload;
    });

    builder.addCase(addConferenceAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.addConferenceResponse = action.payload;
        state.allConferenceResponse.data.items.unshift({
          id: action.payload?.data?.id,
          sn: action.payload?.data?.sn,
          name: action.payload?.data?.name,
          state: action.payload?.data?.state,
        });
        toast.success(action?.payload?.message);
      }
    });
    builder.addCase(addConferenceAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });

    builder.addCase(updateConferenceAsync.fulfilled, (state, action) => {
      toast.success(action?.payload?.message);
      state.updateConventionResponse = action.payload;
    });
    builder.addCase(updateConferenceAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });

    builder.addCase(getConferenceByIdAsync.fulfilled, (state, action) => {
      state.getConferenceByIdResponse = action.payload;
    });
    builder.addCase(getConferenceByIdAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(
      getAssociationsByConferenceIdAsync.fulfilled,
      (state, action) => {
        state.getAssociationsByConventionIdResponse = action.payload;
      }
    );
    builder.addCase(
      getAssociationsByConferenceIdAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );

    builder.addCase(getChurchByConferenceIdAsync.fulfilled, (state, action) => {
      state.getChurchesByConferenceIdResponse = action.payload;
    });
    builder.addCase(getChurchByConferenceIdAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(
      getConferenceBySerialNumberAsync.fulfilled,
      (state, action) => {
        state.getConferenceBySerialNumberResponse = action.payload;
      }
    );
    builder.addCase(
      getConferenceBySerialNumberAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );

    builder.addCase(
      getAssociationByConferenceSerialNumberAsync.fulfilled,
      (state, action) => {
        state.getAssociationByConventionSerialNumberResponse = action.payload;
      }
    );
    builder.addCase(
      getAssociationByConferenceSerialNumberAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );

    builder.addCase(
      getChurchByConferenceSerialNumberAsync.fulfilled,
      (state, action) => {
        state.getChurchesByConferenceSerialNumberResponse = action.payload;
      }
    );
    builder.addCase(
      getChurchByConferenceSerialNumberAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );

    // ASSOCIATION
    builder.addCase(fetchAllAssociationAsync.fulfilled, (state, action) => {
      state.allAssociationResponse = action.payload;
    });

    builder.addCase(addAssociationAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.addAssociationResponse = action.payload;
        state.allAssociationResponse.data.items.unshift({
          id: action.payload?.data?.id,
          sn: action.payload?.data?.sn,
          name: action.payload?.data?.name,
          conference: action.payload?.data?.conference?.name,
          convention: action.payload?.data?.conference?.convention?.name,
        });
        toast.success(action?.payload?.message);
      }
    });
    builder.addCase(addAssociationAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });

    builder.addCase(updateAssociationAsync.fulfilled, (state, action) => {
      toast.success(action?.payload?.message);
      state.updateConventionResponse = action.payload;
    });
    builder.addCase(updateAssociationAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });

    builder.addCase(getAssociationByIdAsync.fulfilled, (state, action) => {
      state.getAssociationByIdResponse = action.payload;
    });
    builder.addCase(getAssociationByIdAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(
      getChurchByAssociationIdAsync.fulfilled,
      (state, action) => {
        state.getChurchesByAssociationIdResponse = action.payload;
      }
    );
    builder.addCase(getChurchByAssociationIdAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(
      getAssociationBySerialNumberAsync.fulfilled,
      (state, action) => {
        state.getAssociationBySerialNumberResponse = action.payload;
      }
    );
    builder.addCase(
      getAssociationBySerialNumberAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );

    builder.addCase(
      getChurchByAssociationSerialNumberAsync.fulfilled,
      (state, action) => {
        state.getChurchesByAssociationSerialNumberResponse = action.payload;
      }
    );
    builder.addCase(
      getChurchByAssociationSerialNumberAsync.rejected,
      (state, action) => {
        toast.error(action.payload.message);
      }
    );
  },
});

export default configSlice.reducer;
