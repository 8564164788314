import React, { useEffect } from "react";
import MemberLayout from "../../components/layouts/member-layout";
import { Card, Col, Container, ProgressBar, Row, Image } from "react-bootstrap";
import bible from "../../assets/Baptist One Components/bible.png";
import hymnal from "../../assets/Baptist One Components/hymnal.png";
import news from "../../assets/Baptist One Components/news.png";
import forum from "../../assets/Baptist One Components/forum.png";
import media from "../../assets/Baptist One Components/media.png";
import convention from "../../assets/Baptist One Components/convention.png";
import resources from "../../assets/Baptist One Components/resources.png";
import ministries from "../../assets/Baptist One Components/ministries.png";

import { FaStar } from "react-icons/fa";
import { PiLockKeyOpenFill } from "react-icons/pi";
import pastorImg from "../../assets/images/pastor-img.png";
import conventionImg from "../../assets/images/convention-img.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAuthUsersDetailsAsync,
  getAuthUserProfileStatusAsync,
} from "../../slices/user/userSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function MemberDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userProfileStatus = useSelector(
    (state) => state.users.getAuthUserProfileStatusResponse
  );

  const handleUserInfo = () => {
    dispatch(fetchAuthUsersDetailsAsync({ detailed: "true" }))
      .then((response) => {
        if (response.payload.status === true) {
          navigate("/user-profile");
        } else {
        }
      })
      .catch((error) => {
        toast.error("Error fetching user details:", error);
      });
  };

  useEffect(() => {
    try {
      dispatch(fetchAuthUsersDetailsAsync({ detailed: "true" }));
    } catch (error) {
      console.log(error);
    }

    try {
      dispatch(getAuthUserProfileStatusAsync());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  return (
    <MemberLayout>
      <Container>
        {userProfileStatus?.data?.is_completed ? null : (
          <>
            <div className="mt-3">
              <small className="font-small fw-bold">
                {" "}
                COMPLETE VERIFICATION
              </small>
            </div>
            <div className="">
              <Card className="bg-color-1 text-white my-3 rounded">
                <Card.Body>
                  <div className="">
                    <Row>
                      <Col md={6} sm={6} xs={6}>
                        <small>
                          {" "}
                          {userProfileStatus?.data?.total_completed} of 2
                        </small>
                      </Col>
                      <Col md={6} sm={6} xs={6}>
                        <div className="text-end">
                          <small>
                            {" "}
                            {userProfileStatus?.data?.percentage_completion} %
                          </small>
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <ProgressBar
                        variant="warning"
                        now={userProfileStatus?.data?.percentage_completion}
                        className="progress-bar-custom"
                      />
                    </div>
                  </div>
                  <div className="bg-color-5 rounded mt-2 p-2">
                    {" "}
                    <Row className="mt-3">
                      <Col md={2} sm={2} xs={2}>
                        <PiLockKeyOpenFill className="text-color-2" />
                      </Col>
                      <Col md={10} xs={10} sm={10}>
                        <h6 className=" text-color-2 fw-bold">
                          Membership Registration
                        </h6>
                        <p className=" text-color-2 font-small line-height">
                          In order to experience the full potential of this app,
                          we need to verify your membership and make sure you
                          belong to a local Baptist Church.
                        </p>

                        <small
                          variant="transparent"
                          size="sm"
                          onClick={() => handleUserInfo()}
                          className="text-color-2 font-small fw-bold"
                        >
                          Continue
                        </small>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </>
        )}

        <div className="mt-5">
          <Row>
            <Col md={3} xs={3} sm={3}>
              <div div className="bg-transparent border-0 text-center">
                <div className="bg-color-3 dash-icon">
                  <Image src={bible} />
                </div>

                <small className="">Bible</small>
              </div>
            </Col>
            <Col md={3} xs={3} sm={3}>
              <div div className="bg-transparent border-0 text-center">
                <div className="bg-color-3 dash-icon">
                  <Image src={hymnal} />
                </div>

                <small className="">Hymnal</small>
              </div>
            </Col>
            <Col md={3} xs={3} sm={3}>
              <div div className="bg-transparent border-0 text-center">
                <div className="bg-color-3 dash-icon">
                  <Image src={news} />
                </div>

                <small className="">News</small>
              </div>
            </Col>
            <Col md={3} xs={3} sm={3}>
              <div div className="bg-transparent border-0 text-center">
                <div className="bg-color-3 dash-icon">
                  <Image src={forum} />
                </div>

                <small className="">Forum</small>
              </div>
            </Col>
          </Row>
        </div>
        <div className="my-4">
          <Row>
            <Col md={3} xs={3} sm={3}>
              <div div className="bg-transparent border-0 text-center">
                <div className="bg-color-3 dash-icon">
                  <Image src={media} />
                </div>

                <small className="">Media</small>
              </div>
            </Col>
            <Col md={3} xs={3} sm={3}>
              <div div className="bg-transparent border-0 text-center">
                <div className="bg-color-3 dash-icon">
                  <Image src={convention} />
                </div>

                <small className="">Convention</small>
              </div>
            </Col>
            <Col md={3} xs={3} sm={3}>
              <div div className="bg-transparent border-0 text-center">
                <div className="bg-color-3 dash-icon">
                  <Image src={resources} />
                </div>

                <small className="">Resources</small>
              </div>
            </Col>

            <Col md={3} xs={3} sm={3} className="">
              <div div className="bg-transparent border-0 text-center">
                <div className="bg-color-3 dash-icon">
                  <Image src={ministries} />
                </div>

                <small className="">Ministries</small>
              </div>
            </Col>
          </Row>
        </div>
        <hr />
        <div className="mb-5">
          <div className="my-4">
            <h5>
              Trending <FaStar className="text-warning" />
            </h5>
          </div>
          <Card>
            <Row>
              <Col md={4} xs={4} sm={4}>
                <Card.Img
                  src={pastorImg}
                  alt="Address image"
                  className="img-fluid rounded-start"
                />
              </Col>
              <Col md={8} xs={8} sm={8}>
                <div className="py-1">
                  <Card.Text className="line-height">
                    <h6>Pastor's Conner</h6>
                    <small className="text-muted font-small">
                      11/11/2023 | 2min ago
                    </small>
                    <br />
                    <p className="line-height">
                      Esteemed members of the Faith Baptist...
                    </p>
                  </Card.Text>
                </div>
              </Col>
            </Row>
          </Card>

          <Card className="my-3">
            <Row>
              <Col md={4} xs={4} sm={4}>
                <Card.Img
                  src={conventionImg}
                  alt="Address image"
                  className="img-fluid rounded-start"
                />
              </Col>
              <Col md={8} xs={8} sm={8}>
                <div className="py-1">
                  <Card.Text className="line-height">
                    <h6>Convention Must...</h6>
                    <small className="text-muted font-small">
                      11/11/2023 | 2min ago
                    </small>
                    <br />
                    <p className="line-height">
                      President, Nigerian Baptist Convention...
                    </p>
                  </Card.Text>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </Container>
    </MemberLayout>
  );
}

export default MemberDashboard;
