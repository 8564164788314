import Navigation from "../navbar/navigation";
import Footer from "../footer/footer";

function MemberLayout({ children }) {
  return (
    <div>
      <Navigation />
      <div className="mb-5"> {children} </div>
      <Footer />
    </div>
  );
}
export default MemberLayout;
