import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  activateChurch,
  createChurch,
  deactivateChurch,
  deleteChurch,
  getAllChurch,
  getAllChurchMembers,
  getAllUserChurches,
  getChurchById,
  updateChurch,
  updateChurchHistory,
  userCreateChurch,
} from "../../services/church/churchService";

export const fetchAllChurchAsync = createAsyncThunk(
  "church/fetch/all",
  async ({ detailed, page, size, status }) => {
    const response = await getAllChurch(detailed, status, page, size);
    return response;
  }
);

export const fetchChurchDetailsByIdAsync = createAsyncThunk(
  "church/details/by/id",
  async ({ detailed, church_id }) => {
    const response = await getChurchById(church_id, detailed);
    return response;
  }
);

export const addChurchAsync = createAsyncThunk("church/add", async (values) => {
  const createdUser = await createChurch(values);
  return createdUser;
});

export const updateChurchAsync = createAsyncThunk(
  "church/update",
  async ({ church_id, data }) => {
    const response = await updateChurch(church_id, data);
    return response;
  }
);

export const deleteChurchAsync = createAsyncThunk(
  "church/delete",
  async (church_id) => {
    const response = await deleteChurch(church_id);
    return response;
  }
);

export const activateChurchAsync = createAsyncThunk(
  "church/activate",
  async ({ church_id }) => {
    const response = await activateChurch(church_id);
    return response;
  }
);
export const deactivateChurchAsync = createAsyncThunk(
  "church/deactivate",
  async ({ church_id }) => {
    const response = await deactivateChurch(church_id);
    return response;
  }
);

export const fetchAllUserChurchAsync = createAsyncThunk(
  "church/fetch/all/users",
  async ({ detailed, page, size }) => {
    const response = await getAllUserChurches(detailed, page, size);
    return response;
  }
);

export const getAllChurchMembersAsync = createAsyncThunk(
  "church/all/members",
  async ({ church_id, detailed, status, page, size }) => {
    const response = await getAllChurchMembers(
      church_id,
      detailed,
      status,
      page,
      size
    );
    return response;
  }
);

export const userAddChurchAsync = createAsyncThunk(
  "church/add/user",
  async (values) => {
    const createdUser = await userCreateChurch(values);
    return createdUser;
  }
);

export const updateChurchHistoryAsync = createAsyncThunk(
  "church/update/history",
  async ({ data }) => {
    const response = await updateChurchHistory(data);
    return response;
  }
);

const churchSlice = createSlice({
  name: "church",
  initialState: {
    addChurchResponse: {},
    userAddChurchResponse: {},
    allChurchResponse: {},
    churchDetailsByIdResponse: {},
    updateChurchResponse: {},
    deleteChurchResponse: {},
    activateChurchResponse: {},
    deactivateChurchResponse: {},
    allUserChurchResponse: {},
    updateChurchHistoryResponse: {},
    getAllChurchMembersResponse: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllChurchAsync.fulfilled, (state, action) => {
      state.allChurchResponse = action.payload;
    });

    builder.addCase(fetchChurchDetailsByIdAsync.fulfilled, (state, action) => {
      state.churchDetailsByIdResponse = action.payload;
    });
    builder.addCase(fetchChurchDetailsByIdAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(addChurchAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.addChurchResponse = action.payload;
        toast.success(action.payload.message);
      }
    });

    builder.addCase(addChurchAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(updateChurchAsync.fulfilled, (state, action) => {
      state.updateChurchResponse = action.payload;
      toast.success(action.payload.message);
    });
    builder.addCase(updateChurchAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(deleteChurchAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.deactivateChurchResponse = action.payload;

        state.allChurchResponse.data.items.pop({
          id: action.payload?.data?.id,
          contact_email: action.payload?.data?.contact_email,
          contact_name: action.payload?.data?.contact_name,
          is_active: action.payload?.data?.is_active,
          position: action.payload?.data?.position,
          role: action.payload?.data?.role,
          name: action.payload?.data?.name,
          id_card_number: action.payload.data.id_card_number,
        });
        toast.success(action.payload.message);
      }
    });

    builder.addCase(activateChurchAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.activateChurchResponse = action.payload;
        state.churchDetailsByIdResponse = action.payload;

        // state.allChurchResponse.data.items.unshift({
        //   id: action.payload?.data?.id,
        //   email: action.payload?.data?.email,
        //   department: action.payload?.data?.department,
        //   is_active: action.payload?.data?.is_active,
        //   position: action.payload?.data?.position,
        //   role: action.payload?.data?.role,
        //   name: action.payload?.data?.name,
        //   image: action.payload?.data?.image,
        //   id_card_number: action.payload.data.id_card_number,
        // });
        toast.success(action?.payload?.message);
      }
    });
    builder.addCase(activateChurchAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });

    builder.addCase(deactivateChurchAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.deactivateChurchResponse = action.payload;
        state.churchDetailsByIdResponse = action.payload;

        // state.allChurchResponse.data.items.unshift({
        //   id: action.payload?.data?.id,
        //   email: action.payload?.data?.email,
        //   department: action.payload?.data?.department,
        //   is_active: action.payload?.data?.is_active,
        //   position: action.payload?.data?.position,
        //   role: action.payload?.data?.role,
        //   name: action.payload?.data?.name,
        //   image: action.payload?.data?.image,
        //   id_card_number: action.payload.data.id_card_number,
        // });
        toast.success(action.payload.message);
      }
    });
    builder.addCase(deactivateChurchAsync.rejected, (state, action) => {
      toast.error("Error: Failed please try again");
    });

    builder.addCase(fetchAllUserChurchAsync.fulfilled, (state, action) => {
      state.allUserChurchResponse = action.payload;
    });

    builder.addCase(fetchAllUserChurchAsync.rejected, (state, action) => {
      state.allUserChurchResponse = action.payload;
    });

    builder.addCase(getAllChurchMembersAsync.fulfilled, (state, action) => {
      state.getAllChurchMembersResponse = action.payload;
    });

    builder.addCase(getAllChurchMembersAsync.rejected, (state, action) => {
      // state.allUserChurchResponse = action.payload;
      toast.error("Error: Failed please try again");
    });

    builder.addCase(userAddChurchAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.userAddChurchResponse = action.payload;
        toast.success(action.payload.message);
      }
    });

    builder.addCase(userAddChurchAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(updateChurchHistoryAsync.fulfilled, (state, action) => {
      state.updateChurchHistoryResponse = action.payload;
      toast.success(action.payload.message);
    });
    builder.addCase(updateChurchHistoryAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });
  },
});

export default churchSlice.reducer;
