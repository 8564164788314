import { Formik } from "formik";
import { useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  addConferenceAsync,
  updateConferenceAsync,
} from "../../../../../slices/config/configSlice";
function EditConferenceModal(props) {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.data.countriesReponse);
  const config = useSelector((state) => state.config);
  const id = props?.data[0]?.id;

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="">
          <>
            <Modal.Title className="me-5"> Edit Conference</Modal.Title>
          </>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              name: props?.data[0]?.name,
              about: props?.data[0]?.about,
              convention_id: props?.data[0]?.convention.id,
              state: props?.data[0]?.state,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              about: Yup.string().required("About is required"),
              convention_id: Yup.string().required("Convention is required"),
              state: Yup.string().required("Country is required"),
            })}
            onSubmit={(values) => {
              dispatch(
                updateConferenceAsync({ conference_id: id, data: values })
              );
            }}
            validateOnChange
            validateOnBlur
            validateOnSubmit
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              values,
              handleChange,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Name"
                        className="my-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        {errors.name && touched.name ? (
                          <small className="text-danger">{errors.name}</small>
                        ) : null}
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Convention"
                        className="my-2"
                      >
                        <Form.Select
                          aria-label="Floating label select example"
                          name="convention_id"
                          value={values.convention_id}
                          onChange={handleChange}
                        >
                          <option value="">Select Convention</option>
                          {config?.allConventionResponse?.data?.items.map(
                            (convention, index) => (
                              <option key={index} value={convention.id}>
                                {convention.name}
                              </option>
                            )
                          )}
                        </Form.Select>
                        {errors.convention_id && touched.convention_id ? (
                          <small className="text-danger">
                            {errors.convention_id}
                          </small>
                        ) : null}
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="State"
                        className="my-2"
                      >
                        <Form.Select
                          aria-label="Floating label select example"
                          name="state"
                          value={values.state}
                          onChange={handleChange}
                        >
                          <option>Select State</option>
                          {countries?.data?.length > 0 ? (
                            countries.data[0]?.states?.map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>
                              No states available
                            </option>
                          )}
                        </Form.Select>
                        {errors.state && touched.state ? (
                          <small className="text-danger">{errors.state}</small>
                        ) : null}
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="About"
                        className="my-3"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="About"
                          name="about"
                          value={values.about}
                          onChange={handleChange}
                          style={{ height: "200px" }}
                        />
                        {errors.about && touched.about ? (
                          <small className="text-danger">{errors.about}</small>
                        ) : null}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  type="submit"
                  className=" my-4 py-3  border-0  bg-color-1"
                >
                  <span className="text-center">Update</span>
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      ;
    </>
  );
}
export default EditConferenceModal;
