import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Nav,
  Image,
  FloatingLabel,
} from "react-bootstrap";
import logo from "../../assets/images/baptust-one-logo.png";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CloseButton from "../../assets/images/icons/close.svg";
import { AiFillPlusCircle } from "react-icons/ai";
import info from "../../assets/Baptist One Components/info.png";
import {
  fetchAllUserChurchAsync,
  updateChurchHistoryAsync,
  userAddChurchAsync,
} from "../../slices/church/churchSlice";
import { getAllCountriesAsync } from "../../slices/data/dataSlice";
import {
  fetchAllAssociationAsync,
  fetchAllConferenceAsync,
} from "../../slices/config/configSlice";

function UserAddChurch() {
  const [activeTab, setActiveTab] = useState("basic profile");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const countries = useSelector((state) => state.data.countriesReponse);
  const churches = useSelector((state) => state.church.allUserChurchResponse);
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setActiveTab("basic-profile");
  }, []);

  const tabOrder = [
    "basic-profile",
    "church-history",
    "pastors",
    "subscription",
    "bank-details",
  ];

  const handleTabSelect = (key) => {
    setActiveTabIndex(tabOrder.indexOf(key));
    setActiveTab(key);
  };

  const handleBackButtonClick = () => {
    const previousTabIndex = activeTabIndex - 1;

    if (previousTabIndex >= 0) {
      const previousTab = tabOrder[previousTabIndex];
      setActiveTabIndex(previousTabIndex);
      setActiveTab(previousTab);
    }
  };

  const createChurch = useSelector((state) => state.auth.createChurchResponse);

  // const navigate = useNavigate();
  // if (createChurch?.status === "success") {
  //   navigate("/churches");
  // }

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    try {
      dispatch(getAllCountriesAsync());
    } catch (error) {
      console.log(error);
    }

    try {
      dispatch(
        fetchAllUserChurchAsync({
          detailed: false,
          page: "all",
          size: "all",
        })
      );
    } catch (error) {
      console.log(error);
    }

    try {
      dispatch(
        fetchAllConferenceAsync({
          detailed: false,
          page: "all",
          size: "all",
        })
      );
    } catch (error) {
      console.log(error);
    }

    try {
      dispatch(
        fetchAllAssociationAsync({
          detailed: false,
          page: "all",
          size: "all",
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  return (
    <Container fluid className="bg-color-8">
      <Row>
        <Col md={6}>
          <div className="church-reg-img d-sm-none d-none d-md-block">
            <Image src={logo} width={350} className="mt-5 pt-5 ms-5 mb-5" />
            <div className="mx-5 mt-5">
              <h2 className="text-white fw-bold">
                Empowering Worship, <br /> Enriching Lives
              </h2>
            </div>
            <div className="mt-3 mx-5 mb-5">
              <small className="text-white">
                “I appeal to you, dear brothers and sisters, by the authority of
                our Lord <br /> Jesus Christ, to live in harmony with each
                other. Let there be no <br /> divisions in the church. Rather,
                be of one mind, united in thought and <br /> purpose.” - 1
                Corinthians 1:10
              </small>
            </div>

            <div className="mt-5 pt-5 mx-5">
              <h2 className="text-white fw-bold">Church Registration</h2>
            </div>
          </div>
        </Col>
        <Col md={6} className="p-3">
          <div>
            <span>
              <h5>
                CHURCH REGISTRATION
                <Image
                  src={CloseButton}
                  width={25}
                  className="float-end"
                  onClick={handleBack}
                />
              </h5>
            </span>
          </div>
          <hr class="row-divider"></hr>

          <Nav
            variant="underline"
            activeKey={activeTab}
            onSelect={handleTabSelect}
          >
            <Nav.Item className="text-color-6">
              <Nav.Link
                eventKey="basic-profile"
                style={{
                  color: "black",
                  textDecoration: "none",
                  borderBottom:
                    activeTab === "basic-profile"
                      ? "2px solid #CAAF74"
                      : "2px solid transparent",
                }}
              >
                Basic Profile
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="church-history"
                style={{
                  color: "black",
                  textDecoration: "none",
                  borderBottom:
                    activeTab === "church-history"
                      ? "2px solid #CAAF74"
                      : "2px solid transparent",
                }}
              >
                History
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="pastors"
                style={{
                  color: "black",
                  textDecoration: "none",
                  borderBottom:
                    activeTab === "pastors"
                      ? "2px solid #CAAF74"
                      : "2px solid transparent",
                }}
              >
                Pastors
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="subscription"
                style={{
                  color: "black",
                  textDecoration: "none",
                  borderBottom:
                    activeTab === "subscription"
                      ? "2px solid #CAAF74"
                      : "2px solid transparent",
                }}
              >
                Subscription
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="bank-details"
                style={{
                  color: "black",
                  textDecoration: "none",
                  borderBottom:
                    activeTab === "bank-details"
                      ? "2px solid #CAAF74"
                      : "2px solid transparent",
                }}
              >
                Add Bank Details
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div>
            {activeTab === "basic-profile" && (
              <Formik
                initialValues={{
                  name: "",
                  landmark: "",
                  street: "",
                  contact_name: "",
                  city: "",
                  contact_email: "",
                  state: "",
                  contact_phone: "",
                  country: "",
                  contact_role: "",
                  association_id: "",
                  conference_id: "",
                }}
                // validationSchema={Yup.object({
                //   name: Yup.string().required('Required'),
                //   landmark: Yup.string().required('Required'),
                //   street: Yup.string().required('Required'),
                //   contact_name: Yup.string().required('Required'),
                //   city: Yup.string().required('Required'),
                //   contact_email: Yup.string().email('Email is Required'),
                //   state: Yup.string().required('Required'),
                //   contact_phone: Yup.string().required('Required'),
                //   country: Yup.string().required('Required'),
                //   contact_role: Yup.string().required('Required'),
                // })}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(userAddChurchAsync(values));
                  const tabOrder = [
                    "basic-profile",
                    "church-history",
                    "pastors",
                    "subscription",
                    "bank-details",
                  ];
                  const currentIndex = tabOrder.indexOf(activeTab);
                  const nextTab = tabOrder[currentIndex + 1];

                  if (nextTab) {
                    setActiveTab(nextTab);
                  } else {
                    navigate("/churches");
                  }

                  setSubmitting(false);
                }}
                validateOnChange
                validateOnBlur
                validateOnSubmit
              >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mt-3 text-start">
                      <p>BASIC PROFILE</p>
                    </div>
                    <Row>
                      <Col md="6" className="mt-2">
                        <label className="text-color-7">
                          <p>CHURCH NAME</p>
                        </label>
                        <Field
                          type="text"
                          name="name"
                          className={`form-control ${
                            touched.name && errors.name ? "is-invalid" : ""
                          }`}
                          required
                        />
                        {touched.name && errors.name && (
                          <div className="invalid-feedback">{errors.name}</div>
                        )}
                      </Col>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>LANDMARK </p>
                        </label>
                        <Field
                          type="text"
                          name="landmark"
                          className={`form-control ${
                            touched.landmark && errors.landmark
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                        />
                        {touched.landmark && errors.landmark && (
                          <div className="invalid-feedback">
                            {errors.landmark}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>STREET </p>
                        </label>
                        <Field
                          type="text"
                          name="street"
                          className={`form-control ${
                            touched.street && errors.street ? "is-invalid" : ""
                          }`}
                          required
                        />
                        {touched.street && errors.street && (
                          <div className="invalid-feedback">
                            {errors.street}
                          </div>
                        )}
                      </Col>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>CONTACT PERSON </p>
                        </label>
                        <Field
                          type="text"
                          name="contact_name"
                          className={`form-control ${
                            touched.contact_name && errors.contact_name
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                        />
                        {touched.contact_name && errors.contact_name && (
                          <div className="invalid-feedback">
                            {errors.contact_name}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>CITY </p>
                        </label>
                        <Field
                          type="text"
                          name="city"
                          className={`form-control ${
                            touched.city && errors.city ? "is-invalid" : ""
                          }`}
                          required
                        />
                        {touched.city && errors.city && (
                          <div className="invalid-feedback">{errors.city}</div>
                        )}
                      </Col>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>CONTACT PERSON EMAIL </p>
                        </label>
                        <Field
                          type="email"
                          name="contact_email"
                          className={`form-control ${
                            touched.contact_email && errors.contact_email
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                        />
                        {touched.contact_email && errors.contact_email && (
                          <div className="invalid-feedback">
                            {errors.contact_email}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>STATE </p>
                        </label>
                        <Field
                          as="select"
                          name="state"
                          className={`form-control ${
                            touched.state && errors.state ? "is-invalid" : ""
                          }`}
                        >
                          <option>Select State</option>
                          {countries?.data?.length > 0 ? (
                            countries.data[0]?.states?.map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>
                              No states available
                            </option>
                          )}
                        </Field>
                        {touched.state && errors.state && (
                          <div className="invalid-feedback">{errors.state}</div>
                        )}
                      </Col>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>CONTACT PERSON PHONE NUMBER </p>
                        </label>
                        <Field
                          type="tel"
                          name="contact_phone"
                          className={`form-control ${
                            touched.contact_phone && errors.contact_phone
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                        />
                        {touched.contact_phone && errors.contact_phone && (
                          <div className="invalid-feedback">
                            {errors.contact_phone}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>COUNTRY </p>
                        </label>
                        <Field
                          as="select"
                          name="country"
                          className={`form-control ${
                            touched.country && errors.country
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option>Select Country</option>
                          {countries?.data?.map((country, index) => (
                            <option key={index} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </Field>
                        {touched.country && errors.country && (
                          <div className="invalid-feedback">
                            {errors.country}
                          </div>
                        )}
                      </Col>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>CONTACT PERSON ROLE </p>
                        </label>
                        <Field
                          type="text"
                          name="contact_role"
                          className={`form-control ${
                            touched.contact_role && errors.contact_role
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                        />
                        {touched.contact_role && errors.contact_role && (
                          <div className="invalid-feedback">
                            {errors.contact_role}
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row className="">
                      <Col md={6} className="">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9">Conference</span>
                          }
                          className="my-3"
                        >
                          <Field
                            as="select"
                            name="conference_id"
                            className={`form-control ${
                              touched.conference_id && errors.conference_id
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select Conference</option>
                            {config?.allConferenceResponse?.data?.items?.map(
                              (church, index) => (
                                <option key={index} value={church.id}>
                                  {church.name}
                                </option>
                              )
                            )}
                          </Field>

                          {errors.conference_id && touched.conference_id && (
                            <small className="text-danger">
                              {errors.conference_id}
                            </small>
                          )}
                        </FloatingLabel>
                      </Col>

                      <Col md={6} className="">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9">Association</span>
                          }
                          className="my-3"
                        >
                          <Field
                            as="select"
                            name="association_id"
                            className={`form-control ${
                              touched.association_id && errors.association_id
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select Association</option>
                            {config?.allAssociationResponse?.data?.items?.map(
                              (association, index) => (
                                <option key={index} value={association.id}>
                                  {association.name}
                                </option>
                              )
                            )}
                          </Field>

                          {errors.association_id && touched.association_id && (
                            <small className="text-danger">
                              {errors.association_id}
                            </small>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>Other Additional Document</p>
                        </label>
                        <Field
                          type="file"
                          name="file"
                          className="form-control"
                          onChange={handleChange}
                          isInvalid={!!errors.file}
                          placeHolder="Other Additional Document"
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-between mt-4 ">
                      <Col md={3} sm={3} xs={3}>
                        <Button className="pri-btn " onClick={handleBack}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={3} sm={3} xs={3}>
                        <Button type="submit" className="save-btn ">
                          Save
                        </Button>
                      </Col>
                      <Col md={3} sm={3} xs={3}>
                        <Button className=" float-md-end main-btn">Next</Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            )}
          </div>
          <div>
            {activeTab === "church-history" && (
              <Formik
                initialValues={{
                  date_planted: "",
                  date_organized: "",
                  mother_church_id: "",
                  documents: [],
                }}
                validationSchema={Yup.object({
                  date_planted: Yup.string().required("Required"),
                  date_organized: Yup.string().required("Required"),
                  mother_church_id: Yup.string().required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(updateChurchHistoryAsync({ data: values }));
                  const tabOrder = [
                    "basic-profile",
                    "church-history",
                    "pastors",
                    "subscription",
                    "bank-details",
                  ];
                  const currentIndex = tabOrder.indexOf(activeTab);
                  const nextTab = tabOrder[currentIndex + 1];

                  if (nextTab) {
                    setActiveTab(nextTab);
                  } else {
                    navigate("/churches");
                  }

                  setSubmitting(false);
                }}
                validateOnChange
                validateOnBlur
                validateOnSubmit
              >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mt-3 text-start">
                      <p>CHURCH HISTORY</p>
                    </div>
                    <div>
                      <Row>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>DATE PLANTED </p>
                          </label>
                          <Field
                            type="date"
                            name="date_planted"
                            className={`form-control ${
                              touched.date_planted && errors.date_planted
                                ? "is-invalid"
                                : ""
                            }`}
                            required
                          />
                          {touched.date_planted && errors.date_planted && (
                            <div className="invalid-feedback">
                              {errors.date_planted}
                            </div>
                          )}
                        </Col>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>DATE ORGANISED </p>
                          </label>
                          <Field
                            type="date"
                            name="date_organized"
                            className={`form-control ${
                              touched.date_organized && errors.date_organized
                                ? "is-invalid"
                                : ""
                            }`}
                            required
                          />
                          {touched.date_organized && errors.date_organized && (
                            <div className="invalid-feedback">
                              {errors.date_organized}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>MOTHER CHURCH </p>
                          </label>
                          <Field
                            as="select"
                            name="mother_church_id"
                            className={`form-control ${
                              touched.mother_church_id &&
                              errors.mother_church_id
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select Church</option>
                            {churches?.data?.items?.map((church, index) => (
                              <option key={index} value={church.id}>
                                {church.name}
                              </option>
                            ))}
                          </Field>
                          {touched.mother_church_id &&
                            errors.mother_church_id && (
                              <div className="invalid-feedback">
                                {errors.mother_church_id}
                              </div>
                            )}
                        </Col>
                      </Row>
                      <div className="mt-2">
                        <small className="fw-bold text-color-7 mt-2">
                          DOCUMENT
                        </small>
                      </div>
                      <Row>
                        <div className="d-flex">
                          <Image
                            src={info}
                            className="mx-2 mt-2"
                            height={25}
                            width={25}
                          />
                          <div>
                            <small
                              style={{ fontSize: "12px", color: "#21225C80" }}
                            >
                              Be rest assured, this is just for verification
                              purpose. <br />
                              Your data is safe and highly secured{" "}
                            </small>
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>Church Registration Certificate</p>
                          </label>
                          <Field
                            type="file"
                            name="churchReg"
                            className="form-control"
                            onChange={handleChange}
                            isInvalid={!!errors.churchReg}
                          />
                        </Col>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>Signed Statement of Faith</p>
                          </label>
                          <Field
                            type="file"
                            name="statement"
                            className="form-control"
                            onChange={handleChange}
                            isInvalid={!!errors.statement}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>Pastoral Leadership Structure</p>
                          </label>
                          <Field
                            type="file"
                            name="leadership"
                            className="form-control"
                            onChange={handleChange}
                            isInvalid={!!errors.leadership}
                          />
                        </Col>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>Statement of Agreement</p>
                          </label>
                          <Field
                            type="file"
                            name="agreement"
                            className="form-control"
                            onChange={handleChange}
                            isInvalid={!!errors.agreement}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>Other Additional Document</p>
                          </label>
                          <Field
                            type="file"
                            name="file"
                            className="form-control"
                            onChange={handleChange}
                            isInvalid={!!errors.file}
                            placeHolder="Other Additional Document"
                          />
                        </Col>
                      </Row>
                    </div>
                    <Row className="justify-content-between mt-4 ">
                      <Col md={2}>
                        <Button
                          className="pri-btn  my-1"
                          onClick={handleBackButtonClick}
                        >
                          Back
                        </Button>
                      </Col>
                      <Col md={2}>
                        <Button type="submit" className="save-btn my-1">
                          Save
                        </Button>
                      </Col>
                      <Col md={6}>
                        <Button
                          type="submit"
                          className=" my-1 float-md-end main-btn"
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            )}
          </div>
          <div>
            {activeTab === "pastors" && (
              <Formik
                initialValues={{
                  name: "",
                  position: "",
                  phoneNumber: "",
                  email: "",
                }}
                validationSchema={Yup.object({
                  name: Yup.string().required("Required"),
                  position: Yup.string().required("Required"),
                  phoneNumber: Yup.string().required("Required"),
                  email: Yup.string().required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values);
                  const tabOrder = [
                    "basic-profile",
                    "church-history",
                    "pastors",
                    "subscription",
                    "bank-details",
                  ];
                  const currentIndex = tabOrder.indexOf(activeTab);
                  const nextTab = tabOrder[currentIndex + 1];

                  if (nextTab) {
                    setActiveTab(nextTab);
                  } else {
                    navigate("/churches");
                  }

                  setSubmitting(false);
                }}
                validateOnChange
                validateOnBlur
                validateOnSubmit
              >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mt-3 text-start">
                      <p>PASTORS</p>
                    </div>
                    <Row>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>NAME</p>
                        </label>
                        <Field
                          type="text"
                          name="name"
                          className={`form-control ${
                            touched.name && errors.name ? "is-invalid" : ""
                          }`}
                          required
                        />
                        {touched.name && errors.name && (
                          <div className="invalid-feedback">{errors.name}</div>
                        )}
                      </Col>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>POSITION</p>
                        </label>
                        <Field
                          type="text"
                          name="position"
                          className={`form-control ${
                            touched.position && errors.position
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                        />
                        {touched.position && errors.position && (
                          <div className="invalid-feedback">
                            {errors.position}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>PHONE NUMBER</p>
                        </label>
                        <Field
                          type="text"
                          name="phoneNumber"
                          className={`form-control ${
                            touched.phoneNumber && errors.phoneNumber
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                        />
                        {touched.phoneNumber && errors.phoneNumber && (
                          <div className="invalid-feedback">
                            {errors.phoneNumber}
                          </div>
                        )}
                      </Col>
                      <Col md="6" className="mt-2">
                        <label>
                          <p>EMAIL</p>
                        </label>
                        <Field
                          type="text"
                          name="email"
                          className={`form-control ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                          required
                        />
                        {touched.email && errors.email && (
                          <div className="invalid-feedback">{errors.email}</div>
                        )}
                      </Col>
                    </Row>

                    <div className="mt-2">
                      <small className="fw-bold text-color-7 mt-2">
                        DOCUMENT
                      </small>
                    </div>
                    <Row>
                      <div className="d-flex">
                        <Image
                          src={info}
                          className="mx-2 mt-2"
                          height={25}
                          width={25}
                        />
                        <div>
                          <small
                            style={{ fontSize: "12px", color: "#21225C80" }}
                          >
                            Be rest assured, this is just for verification
                            purpose. <br />
                            Your data is safe and highly secured{" "}
                          </small>
                        </div>
                      </div>
                    </Row>
                    <div>
                      <Row>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>Pastor Appointment Letter</p>
                          </label>
                          <Field
                            type="file"
                            name="appointment"
                            className="form-control"
                            onChange={handleChange}
                            isInvalid={!!errors.appointment}
                          />
                        </Col>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>Ordination Certificate</p>
                          </label>
                          <Field
                            type="file"
                            name="ordination"
                            className="form-control"
                            onChange={handleChange}
                            isInvalid={!!errors.ordination}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>Other Additional Document</p>
                          </label>
                          <Field
                            type="file"
                            name="additional"
                            className="form-control"
                            onChange={handleChange}
                            isInvalid={!!errors.additional}
                          />
                        </Col>
                        <Col md="6" className="mt-2">
                          <label>
                            <p>Other Additional Document</p>
                          </label>
                          <Field
                            type="file"
                            name="other"
                            className="form-control"
                            onChange={handleChange}
                            isInvalid={!!errors.other}
                          />
                        </Col>
                      </Row>

                      <Button className="more-btn  mt-2">
                        {" "}
                        <AiFillPlusCircle className="mx-1" />
                        Add More
                      </Button>
                    </div>
                    <Row className="justify-content-between mt-4">
                      <Col sm={2}>
                        <Button
                          className="pri-btn  my-1"
                          onClick={handleBackButtonClick}
                        >
                          Back
                        </Button>
                      </Col>

                      <Col sm={2}>
                        <Button type="submit" className="save-btn  my-1">
                          Save
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Button
                          type="submit"
                          className="my-1 float-md-end main-btn"
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            )}
          </div>
          <div>
            {activeTab === "subscription" && (
              <Formik
                initialValues={{
                  notifications: "",
                  donations: "",
                  annual: "",
                }}
                validationSchema={Yup.object({
                  notifications: Yup.string().required("Required"),
                  donations: Yup.string().required("Required"),
                  annual: Yup.string().required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values);
                  const tabOrder = [
                    "basic-profile",
                    "church-history",
                    "pastors",
                    "subscription",
                    "bank-details",
                  ];
                  const currentIndex = tabOrder.indexOf(activeTab);
                  const nextTab = tabOrder[currentIndex + 1];

                  if (nextTab) {
                    setActiveTab(nextTab);
                  } else {
                    navigate("/churches");
                  }
                  setSubmitting(false);
                }}
                validateOnChange
                validateOnBlur
                validateOnSubmit
              >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mt-4 text-start">
                      <p>SUBSCRIPTION</p>
                    </div>
                    <div className="">
                      <Row>
                        <Col md="6" className="mt-3">
                          <span>
                            {" "}
                            <Field
                              type="checkbox"
                              name="notifications"
                              className="custom-checkbox"
                            />
                            <small className="mx-1 ">
                              Opt-in for receiving notifications and updates
                            </small>
                          </span>
                        </Col>
                        <Col md="6" className="mt-3">
                          <span>
                            {" "}
                            <Field
                              type="checkbox"
                              name="donations"
                              className="custom-checkbox"
                            />
                            <small className="mx-1 ">
                              Opt-in for receiving receiving donations
                            </small>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mt-3">
                          <span>
                            {" "}
                            <Field
                              type="checkbox"
                              name="annual"
                              className="custom-checkbox"
                            />
                            <small className="mx-1 ">
                              I wish to participate in using the App to pay my
                              annual due
                            </small>
                          </span>
                        </Col>
                      </Row>

                      <Card className="sub-card">
                        <Card.Body className="text-center">
                          <small>Yearly Subscription</small>
                          <h4>N 50,000</h4>
                          <small>
                            Full Church Management System Forum In-App Finance
                            Management
                          </small>
                        </Card.Body>
                      </Card>

                      <Row className="mt-5">
                        <Col>
                          <span>
                            {" "}
                            <Field
                              type="checkbox"
                              name="agreement"
                              className="custom-checkbox"
                            />
                            <small className="mx-2">
                              I agree to all <a href="#">terms </a> and{" "}
                              <a href="#">conditions </a>
                            </small>
                          </span>
                        </Col>
                      </Row>
                    </div>
                    <span className="pt-5 mt-5">
                      <Button type="submit" className="pay-btn mt-5 p-2 px-4">
                        Proceed to make payment
                      </Button>

                      <Button className="pri-btn mt-5 mx-3 p-2 px-3">
                        Skip
                      </Button>
                    </span>
                  </Form>
                )}
              </Formik>
            )}
          </div>
          <div>
            {activeTab === "bank-details" && (
              <Formik
                initialValues={{
                  accountName: "",
                  bankName: "",
                  accountNumber: "",
                }}
                validationSchema={Yup.object({
                  accountName: Yup.string().required("Required"),
                  bankName: Yup.string().required("Required"),
                  accountNumber: Yup.string().required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values);
                  const tabOrder = [
                    "basic-profile",
                    "church-history",
                    "pastors",
                    "subscription",
                    "bank-details",
                  ];
                  const currentIndex = tabOrder.indexOf(activeTab);
                  const nextTab = tabOrder[currentIndex + 1];

                  if (nextTab) {
                    setActiveTab(nextTab);
                  } else {
                    navigate("/churches");
                  }

                  setSubmitting(false);
                }}
                validateOnChange
                validateOnBlur
                validateOnSubmit
              >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className=" mt-4 text-start">
                      <p>BANK DETAILS</p>
                    </div>
                    <Row>
                      <Col md="6" className="mt-3">
                        <label>
                          <p>ACCOUNT NAME </p>
                        </label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                          required
                        />
                      </Col>
                      <Col md="6" className="mt-3">
                        <label>
                          <p>BANK NAME </p>
                        </label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mt-3">
                        <label>
                          <p>ACCOUNT NUMBER</p>
                        </label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                          required
                        />
                      </Col>
                    </Row>
                    <Card className="mt-5 bank-card">
                      <Card.Body className="text-center">
                        <Row>
                          <Col md={3} className="mt-2">
                            <p>Account Name</p>
                            <h6>NRABC</h6>
                          </Col>
                          <Col md={3} className="mt-2">
                            <p>Bank</p>
                            <h6>GTBank</h6>
                          </Col>
                          <Col md={3} className="mt-2">
                            <p>Account Number</p>
                            <h6>0000000000</h6>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Row className="justify-content-between mt-5">
                      <Col md={2}>
                        <Button
                          className="pri-btn my-3"
                          onClick={handleBackButtonClick}
                        >
                          Back
                        </Button>
                      </Col>

                      <Col md={2}>
                        <Button type="submit" className="save-btn my-3">
                          Save
                        </Button>
                      </Col>
                      <Col md={6}>
                        <Button
                          type="submit"
                          className="my-3 float-md-end main-btn"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default UserAddChurch;
