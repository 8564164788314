import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { verifyEmailAsync } from "../../slices/auth/authSlice";
import { toast } from "react-toastify";

function UserVerify() {
  const navigate = useNavigate();
  const handleGoLogin = () => {
    window.location.href = "/user-login";
  };
  const userLogin = useSelector((state) => state.auth.loginResponse);
  const memberEmail = useSelector((state) => state.users.addMemberResponse);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    try {
      let email = userLogin?.meta?.email || memberEmail?.meta?.email;
      dispatch(
        verifyEmailAsync({
          credentials: {
            email: email,
          },
          token: otp,
        })
      ).then((res) => {
        console.log("restttt", res?.payload?.status);
        if (res?.payload?.status) {
          navigate("/user-notify");
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <div className="mobile-container">
      <Container>
        <div className="text-center my-4 pt-5">
          <h6>OTP Verification</h6>
          <p>
            Account activation OTP has been sent to{" "}
            {memberEmail?.meta?.email || userLogin?.meta?.email} provided.
            Please enter the OTP below
          </p>
        </div>
        <div className="text-center otp-container">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputStyle={"otp-box"}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <div className="text-center mt-4">
          <p>Code will expire in 09:39</p>
          <p>Didn't receive code? Resend</p>
        </div>
        <div className="d-grid gap-2">
          <Button
            type="submit"
            className=" my-4 py-3  border-0  bg-color-2"
            onClick={() => handleSubmit()}
          >
            <span className="text-center"> Submit</span>
          </Button>
        </div>{" "}
        <div className="mb-3 text-center">
          <div className="  p-2 text-muted">
            Already have an account?{" "}
            <span
              className=" fw-bold text-color-2"
              onClick={() => handleGoLogin()}
            >
              Sign in
            </span>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default UserVerify;
