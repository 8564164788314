import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { useState } from "react";
import Sidebar from "../offcanvas/sidebar";
import bell from "../../assets/Baptist One Components/notification-bell.png";

function Navigation() {
  const [modalSiddbar, setModalSidebar] = useState(false);

  return (
    <>
      <Sidebar
        show={modalSiddbar}
        onHide={() => setModalSidebar(false)}
        placement={"start"}
      />
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary shadow"
        sticky="top"
      >
        <Container>
          <Navbar.Brand onClick={() => setModalSidebar(true)}>
            <Navbar.Toggle
              // aria-controls="responsive-navbar-nav"
              onClick={() => setModalSidebar(true)}
            />
            <span className="ms-2">Home</span>
          </Navbar.Brand>

          {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
          <div className="d-inline">
            <Nav className="mx-auto"></Nav>

            <Nav className="">
              <Nav.Link href="#" className="mx-2">
                <Image src={bell} />
              </Nav.Link>
            </Nav>
          </div>

          {/* </Navbar.Collapse> */}
        </Container>
      </Navbar>
    </>
  );
}
export default Navigation;
