import React from "react";
import Logo from "../../../assets/images/main-logo.png";
import { Image, Container, Navbar, Button } from "react-bootstrap";
import playStoreImg from "../../../assets/images/play-store.png";
import appStoreImg from "../../../assets/images/apple-store.png";

function Navigation() {
  return (
    <Navbar className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">
          <Image src={Logo} width={190} />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <Button variant="transparent">
              <Image src={playStoreImg} width={90} />
            </Button>
            <Button variant="transparent">
              <Image src={appStoreImg} className="img-fluid" width={100} />
            </Button>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
