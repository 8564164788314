import { Button, Card, Col, Container, Row } from "react-bootstrap";
import AdminLayout from "../../components/layouts/admin-layout";
import { HiHome } from "react-icons/hi";
import { Link } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import advertimg from "../../assets/images/advert-img.png";
import addressImg from "../../assets/images/address-img.png";
import verseImg from "../../assets/images/verse-img.png";

function ChurchAdminDashboard() {
  return (
    <AdminLayout>
      <Container>
        <section>
          <Row className="my-3">
            <Col md={6}>
              <div>
                <span className="m-2 fw-bold">
                  <HiHome />
                </span>
                Home
              </div>
            </Col>
            <Col md={6}>
              <div className="text-end">
                {/* <Button
                  className="rounded-pill  px-3 bg-color-1 border-0"
                  as={Link}
                  to="/add-user"
                >
                  {" "}
                  <AiFillPlusCircle className="m-1" />
                  Add Member
                </Button> */}
              </div>
            </Col>
          </Row>
        </section>
        <section>
          <Row>
            <Col md={12}>
              <Card className=" text-white border-0 rounded-0 bg-transparent">
                <Card.Img src={advertimg} alt="Advert image" className="" />
                <Card.ImgOverlay>
                  <Card.Title className="text-center display-3">
                    Advert
                  </Card.Title>
                </Card.ImgOverlay>
              </Card>
            </Col>
          </Row>
        </section>
        <section>
          <Row className="my-5">
            <Col md={3}>
              <Card className=" text-white border-0 ">
                <Card.Img
                  src={addressImg}
                  alt="Address image"
                  className="img-fluid"
                />
                <Card.ImgOverlay>
                  <Card.Title className="">Welcome Address</Card.Title>
                  <Card.Title className="text-white display-6 fw-lighter">
                    {" "}
                    MY YEAR OF <br /> GREAT JOY{" "}
                  </Card.Title>
                  <Card.Text className="text-white"> 1 John 5:3</Card.Text>
                </Card.ImgOverlay>
                <Card.Body>
                  <div className="mb-4">
                    <h6 className="text-black">2024 MY YEAR OF GREAT JOY</h6>
                    <p>Esteemed members of the Faith Baptist...</p>
                  </div>
                  <div className="d-grid gap-2">
                    <Button
                      className="rounded  px-3 bg-color-1 border-0"
                      as={Link}
                      to="#"
                    >
                      {" "}
                      Update
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Row>
                  <Col md={8}>
                    <Card.Body>
                      <Card.Title>Verse of the Day</Card.Title>
                      <Card.Text>
                        "In fact, this is love for God: to keep his commands.
                        And his commands are not burdensome," - 1 John 5:3
                      </Card.Text>
                      <Button
                        className="rounded-pill  px-3 bg-color-1 border-0"
                        as={Link}
                        to="#"
                      >
                        {" "}
                        Update
                      </Button>
                    </Card.Body>
                  </Col>
                  <Col md={4}>
                    <Card.Img
                      src={verseImg}
                      alt="Address image"
                      className="img-fluid rounded-start"
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </section>
      </Container>
    </AdminLayout>
  );
}

export default ChurchAdminDashboard;
