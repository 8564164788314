import { Button, Container, FloatingLabel, Form, Image } from "react-bootstrap";
import logo from "../../assets/images/baptust-one-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync, verifyEmailOtpAsync } from "../../slices/auth/authSlice";

function UserLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.auth.loginResponse);

  if (userLogin?.status === true) {
    window.location.href = "/user-dashboard";
  }

  if (userLogin?.custom_status === 440) {
    const email = userLogin?.meta?.email;

    dispatch(
      verifyEmailOtpAsync({
        credentials: {
          email: email,
        },
      })
    );
    navigate("/user-verify");
  }

  return (
    <div className="mobile-container bg-purple">
      <Container>
        <div className="mt-5 pt-5 text-center">
          <Image src={logo} className="img-fluid" />
        </div>
        <div className="text-center pt-4 mt-5">
          <h6 className="text-white">Welcome</h6>
          <p>Please Sign in</p>
        </div>

        <div className="mt-3 px-3">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Invalid email")
                .required("Email is required"),
              password: Yup.string()
                .required("No password provided.")
                .min(6, "Password is too short - should be 6 chars minimum."),
            })}
            onSubmit={(values) => {
              dispatch(
                loginAsync({
                  credentials: values,
                  platform: "app",
                })
              );
            }}
            validateOnChange
            validateOnBlur
            validateOnSubmit
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              values,
              handleChange,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email Address"
                    className="my-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    {errors.email && touched.email ? (
                      <small className="text-danger">{errors.email}</small>
                    ) : null}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Password"
                    className="my-3"
                  >
                    <Form.Control
                      placeholder="Enter your password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      className="p-3 password-input"
                      type="password"
                    />
                    {errors.password && touched.password ? (
                      <small className="text-danger">{errors.password}</small>
                    ) : null}
                  </FloatingLabel>
                </Form.Group>
                <div className="text-end">
                  <Link
                    to="/user-forget-password"
                    className=" text-start text-white my-5"
                  >
                    Forgot Password? <span className="text-warning">Reset</span>
                  </Link>
                </div>
                <div className="d-grid gap-2">
                  <Button
                    type="submit"
                    className=" my-4 py-3  border-0  bg-color-2"
                  >
                    <span className="text-center">Sign in</span>
                  </Button>
                </div>
                <div className="my-4 text-center">
                  <Link to="/user-registration" className="  p-2 text-white">
                    Don't have an account?{" "}
                    <span className=" fw-bold text-warning">Register</span>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
}

export default UserLogin;
