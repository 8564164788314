import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import AdminLayout from "../../../components/layouts/admin-layout";
import { Link, useNavigate } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { FaBriefcase, FaSearch, FaBars, FaThLarge } from "react-icons/fa";

import { Form, FormControl, Table } from "react-bootstrap";
import { HiDotsHorizontal } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  activateChurchAsync,
  deactivateChurchAsync,
  fetchAllChurchAsync,
  fetchChurchDetailsByIdAsync,
  getAllChurchMembersAsync,
} from "../../../slices/church/churchSlice";

function Church(onSearch) {
  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    onSearch(searchTerm);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const churchDetails = useSelector((state) => state.church.allChurchResponse);

  const handleView = async (id) => {
    try {
      // Fetch church details
      const detailsResponse = await dispatch(
        fetchChurchDetailsByIdAsync({ church_id: id, detailed: true })
      );

      // Fetch all church members
      await dispatch(
        getAllChurchMembersAsync({
          church_id: id,
          detailed: true,
          status: "all",
          page: "all",
          size: 10,
        })
      );

      // Check if detailsResponse is truthy before accessing its properties
      if (detailsResponse && detailsResponse.payload.status === true) {
        navigate("/view-church");
      } else {
        // Handle the case where the status is not true or detailsResponse is falsy
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error, for example, show a toast notification
    }
  };

  const handleActivate = async (id) => {
    try {
      dispatch(activateChurchAsync({ church_id: id }));
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleDeactivate = async (id) => {
    try {
      dispatch(deactivateChurchAsync({ church_id: id }));
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    try {
      dispatch(
        fetchAllChurchAsync({
          detailed: false,
          status: "all",
          page: "all",
          size: 10,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);
  return (
    <AdminLayout>
      <Container fluid>
        <Row className="my-3">
          <Col md={6}>
            <div>
              <span className="m-2 fw-bold">
                <FaBriefcase />
              </span>
              Churches
            </div>
          </Col>
          <Col md={6}>
            <div className="text-end">
              <Button
                className="rounded-pill  px-3 bg-color-1 border-0"
                as={Link}
                to="/add-church"
              >
                {" "}
                <AiFillPlusCircle className="m-1" />
                Add Church
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>Churches</Col>
          <Col md={3}>
            <Form className="d-flex position-relative overflow-hidden">
              <FaSearch className="search-icon" />
              <FormControl
                type="search"
                placeholder="      Search Churches"
                className="rounded-pill "
                onChange={handleSearch}
              />
            </Form>
          </Col>
          <Col md={2}>
            <div className="text-center tab-icons rounded-pill">
              <FaThLarge className="mx-3" />

              <FaBars />
            </div>
          </Col>
          <Col className="">
            <Row>
              <Col>Sort by:</Col>
              <Col>Location</Col>
              <Col>Marital Status</Col>
              <Col> Ministry</Col>
              <Col>Date</Col>
            </Row>
          </Col>
        </Row>
        <Table borderless responsive className="mt-4 p-5">
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>NAME</th>
              <th>COUNTRY</th>
              <th>CITY</th>
              <th>ASSOCIATION</th>
              <th>CONFERENCE</th>
              <th> CONVENTION</th>
              <th>CONTACT PERSON</th>
              <th>STATUS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {churchDetails?.data?.items?.map((church, index) => (
              <tr key={index}>
                <td> {index + 1} </td>
                <td> {church?.sn} </td>
                <td> {church?.name} </td>
                <td> {church?.country} </td>
                <td>{church.city} </td>
                <td>{church?.association?.name} </td>
                <td>{church?.association?.conference?.name}</td>
                <td>{church?.association?.conference?.convention?.name} </td>
                <td>{church?.contact_name}</td>
                <td>
                  {church?.is_active === true ? (
                    <div className="outline-success" size="sm">
                      Active{" "}
                    </div>
                  ) : null}

                  {church?.is_active === false ? (
                    <div className="px-2  rounded outline-success" size="sm">
                      Not Active{" "}
                    </div>
                  ) : null}
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" className=" border-0">
                      <HiDotsHorizontal />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="small"
                        onClick={() => handleView(church.id)}
                      >
                        View
                      </Dropdown.Item>

                      {church && (
                        <>
                          {church.is_active ? (
                            <Dropdown.Item
                              className="small"
                              onClick={() => handleDeactivate(church.id)}
                            >
                              Deactivate
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              className="small"
                              onClick={() => handleActivate(church.id)}
                            >
                              Activate
                            </Dropdown.Item>
                          )}
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </AdminLayout>
  );
}

export default Church;
