import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  forgetPassword,
  login,
  logout,
  resetPassword,
  verifyEmail,
  verifyEmailOtp,
} from "../../services/auth/authService";

export const loginAsync = createAsyncThunk(
  "users/login",
  async ({ credentials, platform }) => {
    const response = await login(credentials, platform);

    return response;
  }
);

export const forgetPasswordAsync = createAsyncThunk(
  "users/forget/password",
  async (credentials) => {
    const response = await forgetPassword(credentials);

    return response;
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "users/reset/password",
  async ({ credentials, token }, thunkAPI) => {
    try {
      const response = await resetPassword(credentials, token);
      return response;
    } catch (error) {
      // Handle errors here
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const verifyEmailAsync = createAsyncThunk(
  "users/email/verify",
  async ({ credentials, token }, thunkAPI) => {
    try {
      const response = await verifyEmail(credentials, token);
      return response;
    } catch (error) {
      // Handle errors here
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const verifyEmailOtpAsync = createAsyncThunk(
  "users/email/verify/otp",
  async ({ credentials }, thunkAPI) => {
    try {
      const response = await verifyEmailOtp(credentials);
      return response;
    } catch (error) {
      // Handle errors here
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const logoutAsync = createAsyncThunk("users/logout", async () => {
  const response = await logout();

  return response;
});

const userSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    loginResponse: {},
    forgotPasswordResponse: {},
    resetPasswordResponse: {},
    verifyEmailResponse: {},
    verifyEmailOtpResponse: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.loginResponse = action.payload;
      toast(action.payload.message);
      localStorage.setItem("token", action?.payload?.token);
      localStorage.setItem("user", JSON.stringify(action?.payload?.data));
    });

    builder.addCase(loginAsync.pending, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(loginAsync.rejected, (state, action) => {
      toast.error("Login failed !");
    });
    builder.addCase(forgetPasswordAsync.fulfilled, (state, action) => {
      state.forgotPasswordResponse = action.payload;
      toast.success("Check your email to complete");
    });
    builder.addCase(forgetPasswordAsync.rejected, (state, action) => {
      toast.error("Not found");
    });
    builder.addCase(resetPasswordAsync.fulfilled, (state, action) => {
      state.resetPasswordResponse = action.payload;
      toast.success("Password Reset was Successful");
    });

    builder.addCase(verifyEmailAsync.fulfilled, (state, action) => {
      state.verifyEmailResponse = action.payload;
      toast.success(action?.payload?.message);
    });

    builder.addCase(verifyEmailAsync.rejected, (state, action) => {
      toast.error("Email Verification Failed");
    });

    builder.addCase(verifyEmailOtpAsync.fulfilled, (state, action) => {
      state.verifyEmailOtpResponse = action.payload;
      toast.success(action?.payload?.message);
    });

    builder.addCase(verifyEmailOtpAsync.rejected, (state, action) => {
      toast.error("Email Verification Failed");
    });

    builder.addCase(logoutAsync.fulfilled, (state, action) => {
      state.resetPasswordResponse = action.payload;
      toast.success(action.payload.message);
    });
  },
});

export default userSlice.reducer;
