import { Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/baptust-one-logo-2.png";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { HiUser, HiUserGroup, HiUsers } from "react-icons/hi";
import { FaBriefcase, FaCog, FaPhoneVolume } from "react-icons/fa";
import { BiSolidCalendar, BiSolidDashboard } from "react-icons/bi";
import { IoWallet } from "react-icons/io5";
import { PiChartLineFill } from "react-icons/pi";
import { MdPhoneInTalk } from "react-icons/md";
function ChurchSideBar() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <>
      <aside
        className="sidebar shadow"
        style={{ left: showMenu ? "0" : "-390px" }}
      >
        <div className="bg-white py-2 text-center mb-5 pt-5">
          <Image src={logo} width={120} />
        </div>
        <div className="sidebar-toggle shadow" id="m-tog" onClick={toggleMenu}>
          <div className="text-center mt-1 p-1 text-white h3">
            <GiHamburgerMenu />
          </div>
        </div>

        <Link
          to="/church-admin-dashboard"
          className={
            location.pathname === "/church-admin-dashboard"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2 fw-bold">
            <BiSolidDashboard className="sidebar-icon" />
          </span>
          Home
        </Link>
        <Link
          to="/admin-dashboard"
          className={
            location.pathname === "/admin-dashboard"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2 fw-bold">
            <BiSolidDashboard className="sidebar-icon" />
          </span>
          Dashboard
        </Link>

        <Link
          to="/church-admin-members"
          className={
            location.pathname === "/church-admin-members"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className=" p-2">
            <HiUser className="sidebar-icon" />
          </span>
          Members
        </Link>
        <Link
          to="/ministries"
          className={
            location.pathname === "/ministries"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2">
            <FaBriefcase className="sidebar-icon" />
          </span>
          Ministries
        </Link>
        <Link
          to="/activities"
          className={
            location.pathname === "/activities"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2">
            <BiSolidCalendar className="sidebar-icon" />
          </span>
          Activities
        </Link>
        <Link
          to="/attentance"
          className={
            location.pathname === "/attentance"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2">
            <HiUserGroup className="sidebar-icon" />
          </span>
          Attentance
        </Link>

        <Link
          to="/finance"
          className={
            location.pathname === "/finance"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2">
            <IoWallet className="sidebar-icon" />
          </span>
          Finance
        </Link>
        <Link
          to="/report"
          className={
            location.pathname === "/report"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2">
            <PiChartLineFill className="sidebar-icon" />
          </span>
          Report
        </Link>

        <Link
          to="/admin-settings"
          className={
            location.pathname === "/admin-settings"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2">
            <FaCog className="sidebar-icon" />
          </span>
          Settings
        </Link>
        <Link
          to="/support"
          className={
            location.pathname === "/support"
              ? "active-side sidebar-link"
              : "sidebar-link"
          }
        >
          <span className="m-2">
            <MdPhoneInTalk className="sidebar-icon" />
          </span>
          Support
        </Link>
      </aside>
    </>
  );
}
export default ChurchSideBar;
