import React from 'react'
import LandingLayout from './components/landing-page-layout'
import { Container, Image } from "react-bootstrap"
import Back from "../../assets/landing-page-images/back.svg"
import { useNavigate } from "react-router-dom";

function FinancialPolicy() {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <LandingLayout>
            <Container>
                <Image src={Back} onClick={handleBack} />
                <h1 className='text-center fw-bold display-4'>Financial Policy and Terms</h1>
                <div className='mt-5 pb-5 mb-5'>
                    <p className='font-16 fw-bold text-black'>Effective Date: March 1, 2024</p>
                    <p className='font-16 text-black'>This Financial Policy and Terms outlines the fees, payment methods, donation processing, and related financial aspects of accessing and using the BaptistOne Church Management System. This includes subscription fees, donation processing fees, and security measures. </p>
                    <p className='font-16 text-black'> 1. Subscription Fees: </p>
                    <p className='font-16 text-black'> The CMS requires a monthly/annual subscription fee for each church or organization subscribing. Subscription fees cover the cost of maintaining and developing the CMS platform, providing secure data storage, and offering ongoing support. Current subscription pricing details are available on our website and are subject to change based on updates and feature additions. Payment for subscriptions can be made through secure payment gateways integrated within the CMS, using accepted credit cards or debit cards.</p>
                    <p className='font-16 text-black'> 2. Donation Processing: </p>
                    <p className='font-16 text-black'> The CMS facilitates secure online donations to affiliated churches and organizations through an integrated payment gateway. We partner with reputable payment processors who comply with industry-standard security protocols (PCI DSS) to ensure the safety of donor information and transaction data. We charge a processing fee for each donation completed through the CMS. This fee covers the cost of payment processing services and platform maintenance. Churches and organizations retain 100% of the donated amount after deducting the processing fee. Donation details, including transaction records and donor information (excluding sensitive data like credit card numbers), are accessible to authorized church administrators within the CMS for transparency and accounting purposes. </p>
                    <p className='font-16 text-black'> 3. Security and Data Protection:</p>
                    <p className='font-16 text-black'> We take financial security and data protection seriously. We employ industry-standard encryption and security measures to protect donor information and transaction data. We partner with trusted payment processors who adhere to strict security protocols. We do not store sensitive financial information like credit card numbers on our servers. We regularly review and update our security practices to ensure they remain robust and effective. </p>
                    <p className='font-16 text-black'> 4. Taxes and Reporting: </p>
                    <p className='font-16 text-black'> Churches and organizations are solely responsible for reporting and paying any applicable taxes on donations received through the CMS. We do not issue tax receipts or handle tax reporting on behalf of churches or organizations. We recommend that churches and organizations consult with a tax professional for guidance on compliance with tax regulations. </p>
                    <p className='font-16 text-black'> 5. Payment Disputes and Refunds:</p>
                    <p className='font-16 text-black'> In case of a payment dispute or chargeback related to a donation, the church or organization will be responsible for resolving the issue directly with the donor. We are not responsible for mediating or resolving individual payment disputes between donors and churches/organizations. However, we offer support if requested, by providing transaction details and assisting with communication with the payment processor. Subscription fee is not refundable.</p>
                    <p className='font-16 text-black'> 6. Changes to Financial Policy and Terms:</p>
                    <p className='font-16 text-black'> We reserve the right to modify this Financial Policy and Terms at any time by posting the updated version on our website. Continued use of the CMS after changes are posted constitutes your acceptance of the updated terms.</p>
                    <p className='font-16 text-black'> 7. Contact Us: </p>
                    <p className='font-16 text-black'> For any questions regarding financial matters, subscription fees, or donation processing, please contact us at support@rokswood.com</p>
                </div>
            </Container>
        </LandingLayout>
    )
}

export default FinancialPolicy