import React from 'react'
import LandingLayout from './components/landing-page-layout'
import { Container, Image } from "react-bootstrap"
import Back from "../../assets/landing-page-images/back.svg"
import { useNavigate } from "react-router-dom";

function TermsAndConditions() {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <LandingLayout>
            <Container>
                <Image src={Back} onClick={handleBack} />
                <h1 className='text-center fw-bold display-4'>Terms & Conditions</h1>
                <div className='mt-5 pb-5 mb-5'>
                    <p className='font-16 fw-bold text-black'>Effective Date: March 1, 2024</p>
                    <p className='font-16 text-black'>Welcome to BaptistOne! These Terms and Conditions (“Terms”) govern your access and use of our church management system (“System”), provided by BaptistOne (“we” or “us”). By accessing or using the System, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the System.</p>
                    <p className='font-16 text-black'> 1. Eligibility: </p>
                    <p className='font-16 text-black'>You must be at least 13 years of age to use the System. By using the System, you represent and warrant that you have the right, authority, and capacity to enter into this Agreement and to abide by all of its terms and conditions. </p>
                    <p className='font-16 text-black'> 2. User Accounts:</p>
                    <p className='font-16 text-black'> You are responsible for creating, maintaining, and protecting the confidentiality of your account credentials. You are responsible for all activities that occur under your account. You agree to comply with all applicable laws and regulations in connection with your use of the System.</p>
                    <p className='font-16 text-black'> 3. User Content:</p>
                    <p className='font-16 text-black'> You are solely responsible for any content you post or share on the System (“User Content”). You represent and warrant that you have all necessary rights to post or share User Content and that doing so will not violate any laws or rights of others. We reserve the right to remove or modify any User Content that we deem inappropriate or unlawful. </p>
                    <p className='font-16 text-black'> 4. Prohibited Uses: </p>
                    <p className='font-16 text-black'> You may not use the System for any illegal or unauthorized purpose, including: Infringing on the intellectual property rights of others. Harassing, threatening, or abusing others. Distributing spam or harmful content. Attempting to gain unauthorized access to the System. </p>
                    <p className='font-16 text-black'> 5. Intellectual Property: </p>
                    <p className='font-16 text-black'> The System and all content and materials therein are protected by intellectual property rights, including copyrights, trademarks, and trade secrets. You agree not to use any content or materials from the System without our prior written permission. </p>
                    <p className='font-16 text-black'> 6. Disclaimer of Warranties: </p>
                    <p className='font-16 text-black'> THE SYSTEM IS PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE SYSTEM WILL BE UNINTERRUPTED, SECURE, OR ERROR-FREE. WE DO NOT WARRANT THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SYSTEM WILL BE ACCURATE OR RELIABLE. </p>
                    <p className='font-16 text-black'> 7. Limitation of Liability: </p>
                    <p className='font-16 text-black'> WE WILL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SYSTEM, INCLUDING DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES. YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISSATISFACTION WITH THE SYSTEM IS TO DISCONTINUE USE OF THE SYSTEM. </p>
                    <p className='font-16 text-black'> 8. Termination: </p>
                    <p className='font-16 text-black'> We may terminate your access to the System at any time, for any reason, with or without notice. </p>
                    <p className='font-16 text-black'> 9. Governing Law:</p>
                    <p className='font-16 text-black'> These Terms shall be governed by and construed in accordance with the laws of Federal Republic of Nigeria. </p>
                    <p className='font-16 text-black'> 10. Entire Agreement:</p>
                    <p className='font-16 text-black'> These Terms constitute the entire agreement between you and us with respect to the System and supersede all prior or contemporaneous communications and proposals, whether oral or written. </p>
                    <p className='font-16 text-black'> 11. Amendments: </p>
                    <p className='font-16 text-black'> We may amend these Terms at any time by posting the amended Terms on the System. Your continued use of the System after the amended Terms are posted constitutes your acceptance of the amended Terms. </p>
                    <p className='font-16 text-black'> 12. Contact Us:</p>
                    <p className='font-16 text-black'> If you have any questions about these Terms, please contact us at support@rokswood.com.</p>
                </div>

            </Container>
        </LandingLayout>
    )
}

export default TermsAndConditions