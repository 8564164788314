import {
  Button,
  Card,
  Container,
  FloatingLabel,
  Form,
  Image,
} from "react-bootstrap";
import logo from "../../assets/images/baptust-one-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { forgetPasswordAsync } from "../../slices/auth/authSlice";

function UserForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoLogin = () => {
    window.location.href = "/user-login";
  };

  return (
    <div className="mobile-container bg-purple">
      <Container>
        <div className="mt-5 pt-5 text-center">
          <Image src={logo} className="img-fluid" />
        </div>
        <div className="text-center pt-4 mt-5">
          <h6 className="text-white">Forgot your password?</h6>
          <p>Please fill the form below </p>
        </div>

        <div className="mt-3 px-3">
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Invalid email")
                .required("Email is required"),
            })}
            onSubmit={(values) => {
              dispatch(forgetPasswordAsync(values));
              navigate("/user-login");
            }}
            validateOnChange
            validateOnBlur
            validateOnSubmit
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              values,
              handleChange,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email Address"
                    className="my-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    {errors.email && touched.email ? (
                      <small className="text-danger">{errors.email}</small>
                    ) : null}
                  </FloatingLabel>
                </Form.Group>

                <div className="d-grid gap-2">
                  <Button
                    type="submit"
                    className=" my-4 py-3  border-0  bg-color-2"
                  >
                    <span className="text-center">Submit</span>
                  </Button>
                </div>
                <div className="my-4 text-center">
                  <div className="  p-2 text-white">
                    Don't have an account?{" "}
                    <span
                      className=" fw-bold text-warning"
                      onClick={() => handleGoLogin()}
                    >
                      Register
                    </span>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
}

export default UserForgetPassword;
