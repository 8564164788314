import AdminNavigation from "../navbar/admin-navigation";
import ChurchSideBar from "../sidebar/church-sidebar";
import SideBar from "../sidebar/sidebar";

function AdminLayout({ children }) {
  const user = localStorage.getItem("user");
  let loginUser = null;
  if (user) {
    try {
      loginUser = JSON.parse(user);
    } catch (error) {
      console.error("Error parsing 'user' from localStorage:", error);
    }
  }

  return (
    <div className="real">
      <div className="cont-div">
        {loginUser?.role === "church admin" ? <ChurchSideBar /> : null}
        {loginUser?.role === "admin" ? <SideBar /> : null}
      </div>
      <main className="content">
        <AdminNavigation />
        <div className="p-3">{children}</div>
      </main>
    </div>
  );
}
export default AdminLayout;
