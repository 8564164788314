import { GetRequest, PostRequest, PutRequest } from "../../util/apiMethods";

// CONVENTION
export const addConvention = async (body) => {
  const response = await PostRequest("/manage/conventions", body);
  return response;
};

export const updateConvention = async (convention_id, body) => {
  const response = await PutRequest(
    `/manage/conventions/${convention_id}`,
    body
  );
  return response;
};

export const getAllUserConvention = async (detailed, page, size) => {
  const response = await GetRequest(
    `/conventions?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getConventionById = async (convention_id, detailed) => {
  const response = await GetRequest(
    `/conventions/${convention_id}?detailed=${detailed}`
  );
  return response;
};

export const getAssociationsByConventionId = async (
  convention_id,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conventions/${convention_id}/associations?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getChurchesByConventionId = async (
  convention_id,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conventions/${convention_id}/churches?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getConferencesByConventionId = async (
  convention_id,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conventions/${convention_id}/conferences?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getConventionBySerialNumber = async (
  convention_sn,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conventions/${convention_sn}?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getAssociationByConventionSerialNumber = async (
  convention_sn,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conventions/${convention_sn}/associations?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getChurchesByConventionSerialNumber = async (
  convention_sn,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conventions/${convention_sn}/churches?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getConferenceByConventionSerialNumber = async (
  convention_sn,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conventions/${convention_sn}/conferences?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

// CONFERENCE

export const addConference = async (body) => {
  const response = await PostRequest("/manage/conferences", body);
  return response;
};

export const updateConference = async (conference_id, body) => {
  const response = await PutRequest(
    `/manage/conferences/${conference_id}`,
    body
  );
  return response;
};

export const getAllUserConference = async (detailed, page, size) => {
  const response = await GetRequest(
    `/conferences?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getConferenceById = async (conference_id, detailed) => {
  const response = await GetRequest(
    `/conferences/${conference_id}?detailed=${detailed}`
  );
  return response;
};

export const getAssociationsByConferenceId = async (
  conference_id,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conferences/${conference_id}/associations?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getChurchesByConferenceId = async (
  conference_id,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conferences/${conference_id}/churches?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getConferenceBySerialNumber = async (
  conference_sn,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conferences/${conference_sn}?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getAssociationByConferenceSerialNumber = async (
  conference_sn,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conferences/${conference_sn}/associations?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getChurchesByConferenceSerialNumber = async (
  conference_sn,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/conferences/${conference_sn}/churches?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

// ASSOCIATION

export const addAssociation = async (body) => {
  const response = await PostRequest("/manage/associations", body);
  return response;
};

export const updateAssociation = async (association_id, body) => {
  const response = await PutRequest(
    `/manage/associations/${association_id}`,
    body
  );
  return response;
};

export const getAllUserAssociation = async (detailed, page, size) => {
  const response = await GetRequest(
    `/associations?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getAssociationById = async (association_id, detailed) => {
  const response = await GetRequest(
    `/associations/${association_id}?detailed=${detailed}`
  );
  return response;
};

export const getChurchesByAssociationId = async (
  association_id,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/associations/${association_id}/churches?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getAssociationBySerialNumber = async (
  association_sn,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/associations/${association_sn}?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getChurchesByAssociationSerialNumber = async (
  association_sn,
  detailed,
  page,
  size
) => {
  const response = await GetRequest(
    `/associations/${association_sn}/churches?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};
