import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  createUser,
  updateUser,
  deleteUser,
  getUserById,
  viewUserActivityLog,
  activateUser,
  deactivateUser,
  memberRegister,
  pastorRegister,
  getAuthUser,
  updateAuthUserbasicInfo,
  updateAuthUserChurchInfo,
  getUsers,
  getUserRoles,
  activateChurchMember,
  getAuthUserProfileStatus,
} from "../../services/user/userService";

export const fetchUsersAsync = createAsyncThunk(
  "users/fetchUsers",
  async ({ role, status, page, size }) => {
    const response = await getUsers(role, status, page, size);
    return response;
  }
);

export const fetchUserByIdAsync = createAsyncThunk(
  "users/fetchUsers/by/id",
  async ({ detailed, user_id }) => {
    const response = await getUserById(user_id, detailed);
    return response;
  }
);

export const fetchUserRolesAsync = createAsyncThunk(
  "users/fetchUsers/role",
  async () => {
    const response = await getUserRoles();
    return response;
  }
);

export const activateChurchMemberAsync = createAsyncThunk(
  "users/activate/member",
  async ({ member_id, detailed }) => {
    const response = await activateChurchMember(member_id, detailed);
    return response;
  }
);

export const getAuthUserProfileStatusAsync = createAsyncThunk(
  "users/profile/status",
  async () => {
    const response = await getAuthUserProfileStatus();
    return response;
  }
);

export const addUser = createAsyncThunk("users/createUser", async (values) => {
  const createdUser = await createUser(values);
  return createdUser;
});

export const updateUserAsync = createAsyncThunk(
  "users/update",
  async ({ id, data }) => {
    const response = await updateUser(id, data);
    return response;
  }
);

export const viewUserActivityLogAsync = createAsyncThunk(
  "users/view/activity/log",
  async ({ id, page }) => {
    const response = await viewUserActivityLog(id, page);
    return response;
  }
);

export const deleteUserAsync = createAsyncThunk("users/delete", async (id) => {
  const response = await deleteUser(id);
  return response;
});
export const activateUserAsync = createAsyncThunk(
  "users/activate",
  async (id) => {
    const response = await activateUser(id);
    return response;
  }
);
export const deactivateUserAsync = createAsyncThunk(
  "users/deactivate",
  async (id) => {
    const response = await deactivateUser(id);
    return response;
  }
);

export const addMemberAsync = createAsyncThunk(
  "member/register",
  async (values) => {
    const response = await memberRegister(values);
    return response;
  }
);

export const addPastorAsync = createAsyncThunk(
  "pastor/register",
  async (values) => {
    const response = await pastorRegister(values);
    return response;
  }
);

export const fetchAuthUsersDetailsAsync = createAsyncThunk(
  "users/auth/user/details",
  async ({ detailed }) => {
    const response = await getAuthUser(detailed);
    return response;
  }
);

export const updateAuthUserBasicInfoAsync = createAsyncThunk(
  "users/auth/update/basic/info",
  async ({ data }) => {
    const response = await updateAuthUserbasicInfo(data);
    return response;
  }
);

export const updateAuthUserChurchInfoAsync = createAsyncThunk(
  "users/auth/update/church/info",
  async ({ data }) => {
    const response = await updateAuthUserChurchInfo(data);
    return response;
  }
);

const userSlice = createSlice({
  name: "users",
  initialState: {
    allUsersResponse: {},
    allUserByIdResponse: {},
    userRolesResponse: {},
    activateChurchMemberResponse: {},
    addUserResponse: {},
    userDetailsByIdResponse: {},
    getAuthUserProfileStatusResponse: {},
    updateUserResponse: {},
    deleteUserResponse: {},
    activateUserResponse: {},
    deactivateUserResponse: {},
    viewUserActivityLogResponse: {},
    addMemberResponse: {},
    addPastorResponse: {},
    authUserResponse: {},
    updateAuthUserBasicInfoResponse: {},
    updateAuthUserChurchInfoResponse: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsersAsync.fulfilled, (state, action) => {
      state.allUsersResponse = action.payload;
    });

    builder.addCase(fetchUserByIdAsync.fulfilled, (state, action) => {
      state.allUserByIdResponse = action.payload;
    });

    builder.addCase(fetchUserRolesAsync.fulfilled, (state, action) => {
      state.userRolesResponse = action.payload;
    });
    builder.addCase(activateChurchMemberAsync.fulfilled, (state, action) => {
      state.activateChurchMemberResponse = action.payload;
      toast.success(action?.payload?.message);
    });

    builder.addCase(activateChurchMemberAsync.rejected, (state, action) => {
      toast.error("Error: Confirmation not successful");
    });

    builder.addCase(addUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.addUserResponse = action.payload;

        state.allUserDetails.data.items.unshift({
          id: action.payload?.data?.id,
          email: action.payload?.data?.email,
          department: action.payload?.data?.department,
          is_active: action.payload?.data?.is_active,
          position: action.payload?.data?.position,
          role: action.payload?.data?.role,
          name: action.payload?.data?.name,
          image: action.payload?.data?.image,
          id_card_number: action.payload.data.id_card_number,
        });
        toast.success(action.payload.message);
      }
    });

    builder.addCase(addUser.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    // builder.addCase(fetchUsersDetailsById.fulfilled, (state, action) => {
    //   state.userDetailsByIdResponse = action.payload;
    // });
    builder.addCase(updateUserAsync.fulfilled, (state, action) => {
      state.userDetailsByIdResponse = action.payload;
      toast.success(action.payload.message);
    });
    builder.addCase(viewUserActivityLogAsync.fulfilled, (state, action) => {
      state.viewUserActivityLogResponse = action.payload;
    });

    builder.addCase(
      getAuthUserProfileStatusAsync.fulfilled,
      (state, action) => {
        state.getAuthUserProfileStatusResponse = action.payload;
      }
    );
    builder.addCase(getAuthUserProfileStatusAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });

    builder.addCase(deleteUserAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.deleteUserResponse = action.payload;

        state.allUserDetails.data.items.pop({
          id: action.payload?.data?.id,
          email: action.payload?.data?.email,
          department: action.payload?.data?.department,
          is_active: action.payload?.data?.is_active,
          position: action.payload?.data?.position,
          role: action.payload?.data?.role,
          name: action.payload?.data?.name,
          id_card_number: action.payload.data.id_card_number,
        });
        toast.success(action.payload.message);
      }
    });

    builder.addCase(activateUserAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.activateUserResponse = action.payload;

        state.allUserDetails.data.items.unshift({
          id: action.payload?.data?.id,
          email: action.payload?.data?.email,
          department: action.payload?.data?.department,
          is_active: action.payload?.data?.is_active,
          position: action.payload?.data?.position,
          role: action.payload?.data?.role,
          name: action.payload?.data?.name,
          image: action.payload?.data?.image,
          id_card_number: action.payload.data.id_card_number,
        });
        toast.success(action.payload.message);
      }
    });
    builder.addCase(activateUserAsync.rejected, (state, action) => {
      toast.error(action.payload.message);
    });

    builder.addCase(deactivateUserAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.deactivateUserResponse = action.payload;

        state.allUserDetails.data.items.unshift({
          id: action.payload?.data?.id,
          email: action.payload?.data?.email,
          department: action.payload?.data?.department,
          is_active: action.payload?.data?.is_active,
          position: action.payload?.data?.position,
          role: action.payload?.data?.role,
          name: action.payload?.data?.name,
          image: action.payload?.data?.image,
          id_card_number: action.payload.data.id_card_number,
        });
        toast.success(action.payload.message);
      }
    });
    builder.addCase(deactivateUserAsync.rejected, (state, action) => {
      toast.error("Error: Failed please try again");
    });

    builder.addCase(addMemberAsync.fulfilled, (state, action) => {
      state.addMemberResponse = action.payload;
      toast.success(action?.payload?.message);
    });
    builder.addCase(addMemberAsync.rejected, (state, action) => {
      toast.error("Registration failed");
    });
    builder.addCase(addPastorAsync.fulfilled, (state, action) => {
      state.addPastorResponse = action.payload;
      toast.success(action?.payload?.message);
    });
    builder.addCase(addPastorAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });
    builder.addCase(fetchAuthUsersDetailsAsync.fulfilled, (state, action) => {
      state.authUserResponse = action.payload;
    });
    builder.addCase(fetchAuthUsersDetailsAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });
    builder.addCase(updateAuthUserBasicInfoAsync.fulfilled, (state, action) => {
      state.updateAuthUserBasicInfoResponse = action.payload;
      toast.success(action?.payload?.message);
    });
    builder.addCase(updateAuthUserBasicInfoAsync.rejected, (state, action) => {
      toast.error("Error: Please try again");
    });
    builder.addCase(
      updateAuthUserChurchInfoAsync.fulfilled,
      (state, action) => {
        state.updateAuthUserChurchInfoResponse = action.payload;
        toast.success(action?.payload?.message);
      }
    );
    builder.addCase(updateAuthUserChurchInfoAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });
  },
});

export default userSlice.reducer;
