import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  activateChurchMembership,
  getAllChurchMembers,
  getChurchMemberById,
} from "../../services/members/memberService";
import { toast } from "react-toastify";

export const fetchAllChurchMembersAsync = createAsyncThunk(
  "church/fetch/all/member",
  async ({ detailed, status, page, size }) => {
    const response = await getAllChurchMembers(detailed, status, page, size);
    return response;
  }
);

export const fetchChurchMemberByIdAsync = createAsyncThunk(
  "church/details/by/id",
  async ({ church_id, detailed }) => {
    const response = await getChurchMemberById(church_id, detailed);
    return response;
  }
);

export const activateChurchMembershipAsync = createAsyncThunk(
  "church/activate/by/id",
  async ({ member_id, detailed }) => {
    const response = await activateChurchMembership(member_id, detailed);
    return response;
  }
);

const memberSlice = createSlice({
  name: "data",
  initialState: {
    getAllChurchMembersResponse: {},
    getChurchMemberByIdResponse: {},
    activateChurchMembershipResponse: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllChurchMembersAsync.fulfilled, (state, action) => {
      state.getAllChurchMembersResponse = action.payload;
    });
    builder.addCase(fetchChurchMemberByIdAsync.fulfilled, (state, action) => {
      state.getChurchMemberByIdResponse = action.payload;
    });
    builder.addCase(
      activateChurchMembershipAsync.fulfilled,
      (state, action) => {
        state.activateChurchMembershipResponse = action.payload;
        toast.success(action?.payload?.message);
      }
    );
    builder.addCase(activateChurchMembershipAsync.rejected, (state, action) => {
      toast.error(action?.payload?.message);
    });
  },
});

export default memberSlice.reducer;
