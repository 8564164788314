import {
  Button,
  Col,
  Container,
  Dropdown,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import AdminLayout from "../../../components/layouts/admin-layout";
import { AiFillPlusCircle } from "react-icons/ai";
import { HiDotsHorizontal } from "react-icons/hi";
import { Link } from "react-router-dom";
import AddConventionModal from "./modal/convention/add-convention";
import { useEffect, useState } from "react";
import { getAllCountriesAsync } from "../../../slices/data/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllAssociationAsync,
  fetchAllConferenceAsync,
  fetchAllConventionAsync,
} from "../../../slices/config/configSlice";
import EditConventionModal from "./modal/convention/edit-convention";
import AddConferenceModal from "./modal/conference/add-conference";
import EditConferenceModal from "./modal/conference/edit-conference";
import AddAssociationModal from "./modal/association/add-association";
import EditAssociationModal from "./modal/association/edit-association";

function SuperAdminSettings() {
  const [addConventionModal, setAddConventionModal] = useState(false);
  const [editConventionModal, setEditConventionModal] = useState(false);
  const [addConferenceModal, setAddConferenceModal] = useState(false);
  const [updateConvention, setUpdateConvention] = useState([]);
  const [editConferenceModal, setEditConferenceModal] = useState(false);
  const [updateConference, setUpdateConference] = useState([]);
  const [addAssociationModal, setAddAssociationModal] = useState(false);
  const [editAssociationModal, setEditAssociationModal] = useState(false);
  const [updateAssociation, setUpdateAssociation] = useState([]);

  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);

  const handleEditConvention = (id) => {
    setEditConventionModal(true);
    const updateConvention = config?.allConventionResponse?.data?.items.filter(
      (data) => data.id === id
    );
    setUpdateConvention(updateConvention);
  };

  const handleEditConference = (id) => {
    setEditConferenceModal(true);
    const updateConference = config?.allConferenceResponse?.data?.items.filter(
      (data) => data.id === id
    );
    setUpdateConference(updateConference);
  };

  const handleEditAssociation = (id) => {
    setEditAssociationModal(true);
    const updateAssociation =
      config?.allAssociationResponse?.data?.items.filter(
        (data) => data.id === id
      );
    setUpdateAssociation(updateAssociation);
  };
  const closeAddConventionModal = () => {
    setAddConventionModal(false);
  };

  useEffect(() => {
    try {
      dispatch(getAllCountriesAsync());
    } catch (error) {
      console.log(error);
    }
    try {
      dispatch(
        fetchAllConventionAsync({
          detailed: true,
          page: "1",
          size: "10",
        })
      );
    } catch (error) {
      console.log(error);
    }
    try {
      dispatch(
        fetchAllAssociationAsync({
          detailed: true,
          page: "1",
          size: "10",
        })
      );
    } catch (error) {
      console.log(error);
    }
    try {
      dispatch(
        fetchAllConferenceAsync({
          detailed: true,
          page: "1",
          size: "10",
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  return (
    <AdminLayout>
      <AddConventionModal
        show={addConventionModal}
        onHide={() => setAddConventionModal(false)}
        closeAddConventionModal={closeAddConventionModal}
      />

      <EditConventionModal
        show={editConventionModal}
        onHide={() => setEditConventionModal(false)}
        data={updateConvention}
      />

      <AddConferenceModal
        show={addConferenceModal}
        onHide={() => setAddConferenceModal(false)}
      />
      <EditConferenceModal
        show={editConferenceModal}
        onHide={() => setEditConferenceModal(false)}
        data={updateConference}
      />

      <AddAssociationModal
        show={addAssociationModal}
        onHide={() => setAddAssociationModal(false)}
      />
      <EditAssociationModal
        show={editAssociationModal}
        onHide={() => setEditAssociationModal(false)}
        data={updateAssociation}
      />
      <Container>
        <div className="my-4">
          <h6>Settings</h6>
        </div>
        <Tabs
          defaultActiveKey="convention"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="convention" title="Convention">
            <div>
              <Row className="my-3">
                <Col md={6}>
                  <div>Convention</div>
                </Col>
                <Col md={6}>
                  <div className="text-end">
                    <Button
                      className="rounded-pill  px-3 bg-color-1 border-0"
                      onClick={() => setAddConventionModal(true)}
                    >
                      {" "}
                      <AiFillPlusCircle className="m-1" />
                      Add Convention
                    </Button>
                  </div>
                </Col>
              </Row>

              <Table borderless responsive className="mt-4 p-5">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>SN</th>
                    <th>NAME</th>
                    <th>COUNTRY</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {config?.allConventionResponse?.data?.items.map(
                    (convention, index) => (
                      <tr key={index}>
                        <td> {index + 1} </td>
                        <td>{convention.sn}</td>
                        <td>{convention.name}</td>
                        <td>{convention.country}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              className=" border-0"
                            >
                              <HiDotsHorizontal />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="small"
                                onClick={() =>
                                  handleEditConvention(convention.id)
                                }
                              >
                                Edit
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>{" "}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab eventKey="conferences" title="Conferences">
            <div>
              <Row className="my-3">
                <Col md={6}>
                  <div>Conference</div>
                </Col>
                <Col md={6}>
                  <div className="text-end">
                    <Button
                      className="rounded-pill  px-3 bg-color-1 border-0"
                      onClick={() => setAddConferenceModal(true)}
                    >
                      {" "}
                      <AiFillPlusCircle className="m-1" />
                      Add Conference
                    </Button>
                  </div>
                </Col>
              </Row>

              <Table borderless responsive className="mt-4 p-5">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>SN</th>
                    <th>NAME</th>
                    <th>STATE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {config?.allConferenceResponse?.data?.items.map(
                    (conference, index) => (
                      <tr key={index}>
                        <td> {index + 1} </td>
                        <td>{conference.sn}</td>
                        <td>{conference.name}</td>
                        <td>{conference.state}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              className=" border-0"
                            >
                              <HiDotsHorizontal />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="small"
                                onClick={() =>
                                  handleEditConference(conference.id)
                                }
                              >
                                Edit
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>{" "}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab eventKey="association" title="Association">
            <div>
              <Row className="my-3">
                <Col md={6}>
                  <div>Association</div>
                </Col>
                <Col md={6}>
                  <div className="text-end">
                    <Button
                      className="rounded-pill  px-3 bg-color-1 border-0"
                      onClick={() => setAddAssociationModal(true)}
                    >
                      {" "}
                      <AiFillPlusCircle className="m-1" />
                      Add Association
                    </Button>
                  </div>
                </Col>
              </Row>

              <Table borderless responsive className="mt-4 p-5">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>SN</th>
                    <th>NAME</th>
                    <th>CONFERENCE</th>
                    <th>CONVENTION</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {config?.allAssociationResponse?.data?.items.map(
                    (association, index) => (
                      <tr key={index}>
                        <td> {index + 1} </td>
                        <td>{association.sn}</td>
                        <td>{association.name}</td>
                        <td>{association?.conference?.name}</td>
                        <td>{association?.conference?.convention?.name}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              className=" border-0"
                            >
                              <HiDotsHorizontal />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="small"
                                onClick={() =>
                                  handleEditAssociation(association.id)
                                }
                              >
                                Edit
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>{" "}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </Tab>
        </Tabs>
      </Container>
    </AdminLayout>
  );
}

export default SuperAdminSettings;
