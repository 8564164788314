import { React, useState } from "react";
import Logo from "../../../assets/landing-page-images/logo2.svg";
import Socials from "../../../assets/landing-page-images/socials/socials.png";
import { Image, Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import CookiePolicyModal from "../cookie-policy-modal";

function Footer() {
  const [showCookiesPolicyModal, setShowCookiesPolicyModal] = useState(false);

  const handleOpenCookiesPolicyModal = () => setShowCookiesPolicyModal(true);
  const handleCloseCookiesPolicyModal = () => setShowCookiesPolicyModal(false);
  return (
    <div className="bg-color-1">
      <CookiePolicyModal
        show={showCookiesPolicyModal}
        handleClose={handleCloseCookiesPolicyModal}
      />
      <Container>
        <Row className="py-5">
          <Col md={5} className="">
            <Image src={Logo} className="img-fluid" />
            <div className="mt-3">
              <Image src={Socials} className="img-fluid" />
            </div>
          </Col>
          <Col md={2}>
            <p className="font-16 text-white">HOME</p>
            <p className="font-12 text-white">Members</p>
            <p className="font-12 text-white">Church Leaders</p>
          </Col>
          <Col md={2}>
            <p className="font-16 text-white">SUPPORT</p>
            <p className=" font-12 text-white">support@rokswood.com</p>
            <p className="  font-12 text-white">
              09120000601 (whatsapp/call) <br /> 09046648867 (call only)
            </p>
          </Col>
          <Col md={3}>
            <p className="font-16 text-white">CONTACT</p>
            <p className="font-12 text-white"> info@rokswood.com </p>
            <h6 className="font-12 text-white">Headquarters</h6>
            <p className="font-12 text-white">
              5108- 51 Ave, Wetaskiwin, Alberta, <br />
              Canada.
            </p>
            <h6 className="font-12 text-white">Cooperate Office</h6>
            <p className="font-12 text-white">
              {" "}
              Plot 1003 morija Close, off Adetokunbo <br /> Ademola Cres, North
              West, Abuja
            </p>
            <h6 className="font-12 text-white">Operation Office</h6>
            <p className="font-12 text-white">
              {" "}
              22 Eng. Ayo Adeniyi street, Eyinni, Orita <br />
              Challenge, Ibadan, Oyo State, Nigeria
            </p>
          </Col>
        </Row>
        <div className="landing-row-divider"></div>
        <Row className=" pt-2 pb-5">
          <Col md={6} className="">
            <span className="font-12 text-white pl">
              Copyright © 2024 BaptistOne
            </span>
            <span className="font-12 text-white px-5">
              Powered by: RokswoodTech
            </span>
          </Col>
          <Col md={6} className="">
            <span
              className="font-12 text-white pl "
              onClick={() => handleOpenCookiesPolicyModal()}
            >
              Cookies Policy
            </span>
            <Link to="/privacy-policy-page" className="text-muted">
              <span className="font-12 text-white px-3">Privacy Policy</span>
            </Link>
            <Link to="/terms-and-conditions-page" className="text-muted">
              <span className="font-12 text-white ">Terms & Conditions</span>
            </Link>
            <Link to="/financial-policy-page" className="text-muted">
              <span className="font-12 text-white px-3">
                Financial Policy & Terms
              </span>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
