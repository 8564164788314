import { Button, Container, Image } from "react-bootstrap";
import verifyImg from "../../assets/images/verification-icon.png";

function UserNotify() {
  const handleGoLogin = () => {
    window.location.href = "/user-login";
  };
  return (
    <div className="mobile-container bg-purple">
      <Container>
        <div className="mt-5 pt-5 text-center">
          <Image src={verifyImg} className="img-fluid" />
        </div>
        <div className="text-center">
          <p className="text-white">Account Created Successfully!</p>
        </div>
        <div className="d-grid gap-2 mt-5 pt-5">
          <Button
            type="submit"
            className=" my-4 py-3  border-0  bg-color-2"
            onClick={() => handleGoLogin()}
          >
            <span className="text-center"> Proceed to Sign in</span>
          </Button>
        </div>{" "}
      </Container>
    </div>
  );
}

export default UserNotify;
