import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Countries, Positions, Roles } from "../../services/data/dataService";

export const getAllRoles = createAsyncThunk("roles/all", async () => {
  const response = await Roles();
  return response;
});
export const getAllPosition = createAsyncThunk("position/all", async () => {
  const response = await Positions();
  return response;
});
export const getAllCountriesAsync = createAsyncThunk(
  "country/state",
  async () => {
    const response = await Countries();
    return response;
  }
);

const dataSlice = createSlice({
  name: "data",
  initialState: {
    roles: {},
    position: {},
    countriesReponse: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
    });
    builder.addCase(getAllPosition.fulfilled, (state, action) => {
      state.position = action.payload;
    });
    builder.addCase(getAllCountriesAsync.fulfilled, (state, action) => {
      state.countriesReponse = action.payload;
    });
  },
});

export default dataSlice.reducer;
