import AdminLayout from "../../../components/layouts/admin-layout";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { FaSearch, FaBars, FaThLarge, FaUsers } from "react-icons/fa";
import { Form, FormControl, Table } from "react-bootstrap";
import { HiDotsHorizontal } from "react-icons/hi";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateChurchMembershipAsync,
  fetchAllChurchMembersAsync,
} from "../../../slices/member/memberSlice";

function Members(onSearch) {
  const members = useSelector(
    (state) => state.members.getAllChurchMembersResponse
  );

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    onSearch(searchTerm);
  };
  const dispatch = useDispatch();
  const handleActivateMember = async (id) => {
    try {
      dispatch(
        activateChurchMembershipAsync({ member_id: id, detailed: false })
      );
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    try {
      dispatch(
        fetchAllChurchMembersAsync({
          detailed: false,
          status: "active",
          page: "all",
          size: 10,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  return (
    <AdminLayout>
      <Container>
        <Row className="my-3">
          <Col md={12}>
            <div>
              <span className="m-2 fw-bold">
                <FaUsers />
              </span>
              Members
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form className="d-flex position-relative overflow-hidden">
              <FaSearch className="search-icon" />
              <FormControl
                type="search"
                placeholder="      Search Users"
                className="rounded-pill "
                onChange={handleSearch}
              />
            </Form>
          </Col>
          <Col md={2}>
            <div className="text-center tab-icons rounded-pill">
              <FaThLarge className="mx-3" />

              <FaBars />
            </div>
          </Col>
          <Col className=""></Col>
        </Row>
        <Table borderless responsive className="mt-4 p-5">
          <thead>
            <tr>
              <th>#</th>
              <th>FIRST NAME</th>
              <th>LAST NAME</th>
              <th> ROLE </th>
              <th>USERNAME</th>

              <th>STATUS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {members?.data?.items?.map((member, index) => (
              <tr>
                <td> {index + 1} </td>
                <td> {member?.firstname} </td>
                <td> {member?.lastname} </td>
                <td> {member?.role} </td>
                <td> {member?.username} </td>
                <td>
                  {member?.status === "active" ? (
                    <div className="outline-success" size="sm">
                      Active{" "}
                    </div>
                  ) : null}

                  {member?.status === "inactive" ? (
                    <div className="px-2  rounded outline-success" size="sm">
                      Not Active{" "}
                    </div>
                  ) : null}
                </td>

                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" className=" border-0">
                      <HiDotsHorizontal />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="small"
                        onClick={() => handleActivateMember(member?.id)}
                      >
                        Confirm
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </AdminLayout>
  );
}

export default Members;
