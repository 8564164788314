import React from 'react'
import AdminLayout from '../../../components/layouts/admin-layout'
import { Button, Col, Container, Row, Card, Image, Table } from "react-bootstrap"
import { FaArrowAltCircleLeft } from "react-icons/fa"
import { HiDotsHorizontal } from "react-icons/hi";
import pdf from "../../../assets/images/pdf (1).svg"
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/NRABC.png"






function ViewMember() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };


    return (
        <AdminLayout>
            <Container fluid>
                <Row className='my-3'>
                    <Col md={8}>
                        <div>
                            <h4>
                                <span className='fw-bold text-color-2'>
                                    <FaArrowAltCircleLeft className='mx-2 text-color-1' onClick={handleBack} />
                                    John Doe
                                </span>
                            </h4>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="text-end">
                            <Button className="rounded-pill  px-4 py-3 deactivate-btn border-0 mx-2">
                                Deactivate
                            </Button>
                            {" "}
                            <Button className="rounded-pill  px-4 py-3 bg-color-1 border-0">
                                Reset password
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={3} className='d-flex'>
                                <Image src={logo} width={100} height={100} className='mt-3 mx-1' />
                                <div className='mt-2' >
                                    <small>Member</small>
                                    <p className='fw-bold'>John Doe</p>
                                    <small>Email</small>
                                    <p className='fw-bold'>johndoe@gmail.com</p>
                                </div>
                            </Col>

                            <Col md={2}>
                                <div className='mt-2'>
                                    <small>Phone Number</small>
                                    <p className='fw-bold'>+234 700 000 0000</p>
                                    <small>Address</small>
                                    <p className='fw-bold'>No 17, Freedom Way. Ringroad, Ibadaan</p>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className='mt-2'>
                                    <small>Date Of Birth</small>
                                    <p className='fw-bold'>00 Nov, 90</p>
                                    <small>Gender</small>
                                    <p className='fw-bold'>Male</p>
                                </div>
                            </Col>
                            <Col md={1}>
                                <div className='mt-2'>
                                    <small>Marital Status</small>
                                    <p className='fw-bold'>Married</p>
                                    <small>Family</small>
                                    <p className='fw-bold'>4</p>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className='mt-2'>
                                    <small>Joined Church</small>
                                    <p className='fw-bold'>12/02/20</p>
                                    <small>Date Registered</small>
                                    <p className='fw-bold'>12/01/24</p>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className='mt-2'>
                                    <small>Verification Status</small>
                                    <Row>
                                        <Col>
                                            <Button
                                                className=" px-3 pending border-0 mt-2 py-2 "
                                            >
                                                Pending
                                            </Button></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button
                                                className="rounded-pill  px-3 bg-color-2 border-0 mt-2 py-2"
                                            >
                                                Confirm
                                            </Button></Col>
                                    </Row>


                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Row className='mt-4'>
                    <Col md={6}>
                        <Card className='pb-4'>
                            <Card.Body>
                                <p>                        Church Details
                                </p>
                                <Row>
                                    <Col md={4}>
                                        <div className='mt-2' >
                                            <small>Church</small>
                                            <p className='fw-bold'>New Reservation Baptist Church, Ibadan</p>
                                            <small>Conference</small>
                                            <p className='fw-bold'>Ibadan Conference</p>
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <div className='mt-2'>
                                            <small>Association</small>
                                            <p className='fw-bold'>Ibadan North</p>
                                            <small>Unit Belonged</small>
                                            <p className='fw-bold'>Media & Publicity</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className='mt-2'>
                                            <small>Ministry</small>
                                            <p className='fw-bold'>MMU</p>
                                            <small>Unit Belonged</small>
                                            <p className='fw-bold'>Media & Publicity</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <Card.Body>
                                <p>                        Family
                                </p>
                                <Row>
                                    <Col md={4}>
                                        <div className='mt-2'>
                                            <small>Marital Status</small>
                                            <p className='fw-bold'>Married</p>
                                            <small>Family</small>
                                            <p className='fw-bold'>4</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className='mt-2'>
                                            <small>Wife</small>
                                            <p className='fw-bold'>Sarah Doe <br /> 12/3/1993</p>
                                            <small>Child 1</small>
                                            <p className='fw-bold'>Jane Doe <br /> 23/4/2012</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className='mt-2'>
                                            <small>Child 1</small>
                                            <p className='fw-bold'>Jane Doe <br /> 23/4/2012</p>
                                            <small>Child 1</small>
                                            <p className='fw-bold'>Jane Doe <br /> 23/4/2012</p>

                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card></Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={6}>
                        <Card>
                            <Card.Body>
                                <p>Donation History</p>
                                <Table borderless responsive className="mt-4 p-5">
                                    <thead>
                                        <tr>
                                            <th>Invoice Number</th>
                                            <th>Donation Type</th>
                                            <th>Transaction date</th>
                                            <th>Amount</th>
                                            <th>Method</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>INV123456</td>
                                            <td>Evangelism</td>
                                            <td>22/01/2024</td>
                                            <td>N120,000</td>
                                            <td>Card</td>
                                            <td>Received</td>
                                            <td>
                                                <HiDotsHorizontal />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <Card.Body>
                                <p>Documents</p>
                                <Row>
                                    <Col md={3} className='d-flex'>
                                        <Image src={pdf} width={40} className='mx-3' />
                                        <div className='mt-2'>
                                            <Link>Document.pdf</Link>
                                            <p>200KB</p>
                                        </div>
                                    </Col>
                                    <Col md={3} className='d-flex'>
                                        <Image src={pdf} width={40} className='mx-3' />
                                        <div className='mt-2'>
                                            <Link>Document.pdf</Link>
                                            <p>200KB</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3} className='d-flex'>
                                        <Image src={pdf} width={40} className='mx-3' />
                                        <div className='mt-2'>
                                            <Link>Document.pdf</Link>
                                            <p>200KB</p>
                                        </div>
                                    </Col>
                                    <Col md={3} className='d-flex'>
                                        <Image src={pdf} width={40} className='mx-3' />
                                        <div className='mt-2'>
                                            <Link>Document.pdf</Link>
                                            <p>200KB</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </AdminLayout>
    )
}

export default ViewMember