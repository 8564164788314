import { Formik } from "formik";
import { useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { addConventionAsync } from "../../../../../slices/config/configSlice";
function AddConventionModal(props) {
  const [imageUrl, setImageUrl] = useState(null);
  const dispatch = useDispatch();
  const [logoPic, setLogoPic] = useState();
  const countries = useSelector((state) => state.data.countriesReponse);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const imgInKb = file.size / 1024;

    if (imgInKb > 500) {
      toast.info("Image size must be 500kb or less");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    setImageUrl(URL.createObjectURL(file));

    reader.onloadend = () => {
      setLogoPic(reader.result);
    };
  };
  const handleCreate = (values) => {
    if (!logoPic) {
      toast.error("Logo is missing or empty.");
      return;
    }
    const payload = {
      ...values,
      logo: logoPic,
    };

    dispatch(addConventionAsync(payload));
    props.closeAddConventionModal();
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="">
          <>
            <Modal.Title className="me-5"> Add Convention</Modal.Title>
          </>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              name: "",
              about: "",
              country: "",
              logo: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              about: Yup.string().required("About is required"),
              country: Yup.string().required("Country is required"),
            })}
            onSubmit={(values) => {
              handleCreate(values);
            }}
            validateOnChange
            validateOnBlur
            validateOnSubmit
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              values,
              handleChange,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={4}>
                    <div className=" ">
                      <div className="upload-picture-sec">
                        {imageUrl && (
                          <Image
                            src={imageUrl}
                            width={100}
                            className=" upload-picture-sec"
                          />
                        )}
                      </div>
                      <div class="fileUpload btn btn-light my-3">
                        <label class="upload">
                          <input
                            className="img-upload"
                            type="file"
                            accept="image/*"
                            placeholder=""
                            onChange={handleImageUpload}
                          />
                          <small>Upload logo</small>
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Name"
                        className="my-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        {errors.name && touched.name ? (
                          <small className="text-danger">{errors.name}</small>
                        ) : null}
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Country"
                        className="my-2"
                      >
                        <Form.Select
                          aria-label="Floating label select example"
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                        >
                          <option value="">Select Country</option>
                          {countries?.data?.map((country, index) => (
                            <option key={index} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.country && touched.country ? (
                          <small className="text-danger">
                            {errors.country}
                          </small>
                        ) : null}
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="About"
                        className="my-3"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="About"
                          name="about"
                          value={values.about}
                          onChange={handleChange}
                          style={{ height: "200px" }}
                        />
                        {errors.about && touched.about ? (
                          <small className="text-danger">{errors.about}</small>
                        ) : null}
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  type="submit"
                  className=" my-4 py-3  border-0  bg-color-1"
                >
                  <span className="text-center">Create</span>
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      ;
    </>
  );
}
export default AddConventionModal;
