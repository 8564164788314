import { Formik } from "formik";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import logo from "../../assets/images/baptust-one-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { loginAsync } from "../../slices/auth/authSlice";

function Login() {
  const userLogin = useSelector((state) => state.auth.loginResponse);
  console.log("login", userLogin?.data);
  const dispatch = useDispatch();
  if (userLogin?.status === true && userLogin?.data?.role === "church admin") {
    window.location.href = "/church-admin-dashboard";
  }
  if (userLogin?.status === true && userLogin?.data?.role === "admin") {
    window.location.href = "/admin-dashboard";
  }

  return (
    <>
      <Row>
        <Col md={7} className="">
          <div className="login-img-side d-sm-none d-none d-md-block  ">
            <Image src={logo} width={300} className="mt-5 pt-5 ms-5" />
            <div className="fixed-bottom m-5">
              <small className="text-white">Powered by RokswoodTech</small>
            </div>
          </div>
        </Col>
        <Col md={5}>
          <div className="">
            <Container className="pt-5">
              <Card className="border-0  pt-5   rounded mx-5 login-card ">
                <Card.Body>
                  <div className="p-4 text-start">
                    <h1 className=" fw-bold text-color-1">Welcome </h1>
                    <p>Sign in to continue</p>
                  </div>
                  <div className="mt-3 px-3">
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .email("Invalid email")
                          .required("Email is required"),
                        password: Yup.string()
                          .required("No password provided.")
                          .min(
                            6,
                            "Password is too short - should be 6 chars minimum."
                          ),
                      })}
                      onSubmit={(values) => {
                        dispatch(
                          loginAsync({
                            credentials: values,
                            platform: "admin",
                          })
                        );
                      }}
                      validateOnChange
                      validateOnBlur
                      validateOnSubmit
                    >
                      {({
                        errors,
                        touched,
                        handleSubmit,
                        isSubmitting,
                        values,
                        handleChange,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Email Address"
                              className="my-3"
                            >
                              <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                              />
                              {errors.email && touched.email ? (
                                <small className="text-danger">
                                  {errors.email}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Password"
                              className="my-3"
                            >
                              <Form.Control
                                placeholder="Enter your password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                className="p-3 password-input"
                                type="password"
                              />
                              {errors.password && touched.password ? (
                                <small className="text-danger">
                                  {errors.password}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                          <div>
                            <Link
                              to="/forget-password"
                              className=" text-start text-color-2 my-5"
                            >
                              Forgot your Password?
                            </Link>
                          </div>
                          <div className="d-grid gap-2">
                            <Button
                              type="submit"
                              className=" my-4 py-3  border-0  bg-color-1"
                            >
                              <span className="text-center">Sign in</span>
                            </Button>
                          </div>
                          <div className="mb-3">
                            <Link
                              to="/forget-password"
                              className="  p-2 text-muted"
                            >
                              Trouble signing in?{" "}
                              <span className="text-danger fw-bold">
                                Contact support
                              </span>
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default Login;
