import { Button, Container, Image } from "react-bootstrap";
import logo from "../../assets/images/NRABC.png";
import logo2 from "../../assets/images/baptist-logo-3.png";
import { Link } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";

function UserWelcome() {
  return (
    <div className="mobile-container-welcome ">
      <Container>
        <div className=" pt-5 text-center">
          <Image src={logo} className="img-fluid" width={100} />
        </div>
        <div className="position-absolute top-50 start-50 translate-middle">
          <div>
            <div className=" pt-5 text-center">
              <Image src={logo2} className="img-fluid" />
            </div>
            <div className="text-center ">
              <p className="text-white mt-3">
                Faithful <span className="fw-bold">Connections</span> <br />
                Seamless <span className="fw-bold">Congregations.</span>{" "}
              </p>
              <br />
            </div>
          </div>
          <div className="text-center pt-5 mt-5">
            <Button
              variant="transparent"
              className="text-white"
              as={Link}
              to="/user-registration"
            >
              Get Started <br />
              <BsArrowUpRight className="text-warning h2 mt-3" />
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default UserWelcome;
