import React, { useEffect } from "react";
import { Card, Col, Container, Row, Image } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { FloatingLabel, ProgressBar } from "react-bootstrap";
import logo from "../../assets/images/member-profile.png";
import MemberLayout from "../../components/layouts/member-layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchAuthUsersDetailsAsync } from "../../slices/user/userSlice";
import { PiLockKeyOpenFill } from "react-icons/pi";
import edit from "../../assets/Baptist One Components/Edit.png";
import done from "../../assets/Baptist One Components/done.png";
import { useSelector } from "react-redux";

function UserProfile() {
  const userDetails = useSelector((state) => state.users.authUserResponse);
  const userProfileStatus = useSelector(
    (state) => state.users.getAuthUserProfileStatusResponse
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUserInfo = () => {
    dispatch(
      fetchAuthUsersDetailsAsync({
        detailed: "true",
      })
    );
    navigate("/membership-registration");
  };

  useEffect(() => {
    try {
      dispatch(fetchAuthUsersDetailsAsync({ detailed: "true" }));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);
  return (
    <MemberLayout>
      <Container>
        <Row className="text-center mt-5 ">
          <Col sm="12">
            <div className="mx-2">
              <Image
                src={userDetails?.data?.image_url}
                width={90}
                height={90}
              />
            </div>
          </Col>

          <Col sm="12">
            <div className="mt-4">
              <h6> {userDetails?.data?.firstname} </h6>
              <p> {userDetails?.data?.church} </p>
              <small className="text-color-1">
                UNIT: {userDetails?.data?.churchUnit}{" "}
              </small>
            </div>
          </Col>
        </Row>

        {userProfileStatus?.data?.is_completed ? null : (
          <div className="mt-5 mb-5">
            <small className="fw-bold mx-3">COMPLETE VERIFICATION</small>
            <Card className="mx-2 mt-2 card3">
              <Card.Body className="">
                <div className="">
                  <Row>
                    <Col md={6} sm={6} xs={6}>
                      <small>
                        {userProfileStatus?.data?.total_completed} of 3
                      </small>
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      <div className="text-end">
                        <small>
                          {userProfileStatus?.data?.percentage_completion} %
                        </small>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <ProgressBar
                      variant="white"
                      now={userProfileStatus?.data?.percentage_completion}
                      className="progress-bar-custom mb-2"
                    />
                  </div>
                </div>
                <Card>
                  <Card.Body>
                    <Row className="mt-3">
                      <Col md={2} sm={2} xs={2}>
                        <PiLockKeyOpenFill className="text-color-2 fs-4" />
                      </Col>
                      <Col md={10} xs={10} sm={10}>
                        <h6 className=" text-color-2 fw-bold">
                          Membership Registration
                        </h6>
                        <p className=" text-color-2 font-small line-height">
                          In order to experience the full potential of this app,
                          we need to verify your membership and make sure you
                          belong to a local Baptist Church.
                        </p>

                        <small
                          variant="transparent"
                          size="sm"
                          onClick={() => handleUserInfo()}
                          className="text-color-2 font-small fw-bold"
                        >
                          Continue
                        </small>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </div>
        )}
        <div className="mb-5">
          <Formik
            initialValues={{
              firstname: userDetails?.data?.firstname,
              lastname: userDetails?.data?.lastname,
              othername: userDetails?.data?.othername,
              email: userDetails?.data?.email,
              phone: userDetails?.data?.phone,
              address: userDetails?.data?.address,
              city: userDetails?.data?.city,
              state: userDetails?.data?.state,
              country: userDetails?.data?.country,
              dob: userDetails?.data?.dob,
              gender: userDetails?.data?.gender,
              marital_status: userDetails?.data?.marital_status,
              church: userDetails?.data?.membership?.church.name,
              association: userDetails?.data?.membership?.association?.name,
              conference:
                userDetails?.data?.membership?.association?.conference?.name,
              churchUnit: userDetails?.data?.church_unit,
              churchMinistry: userDetails?.data?.church_ministry,
              baptismalCertificate: userDetails?.data?.baptismal_certificate,
              password: "**********",
              authenticationPin: userDetails?.data?.authentication_pin,
            }}
            // validationSchema={Yup.object({
            //   firstname: Yup.string().required("Required"),
            //   lastname: Yup.string().required("Required"),
            //   othername: Yup.string().required("Required"),
            //   email: Yup.string().required("Required"),
            //   phone: Yup.string().required("Required"),
            //   address: Yup.string().required("Required"),
            //   city: Yup.string().required("Required"),
            //   state: Yup.string().required("Required"),
            //   country: Yup.string().required("Required"),
            //   dob: Yup.string().required("Required"),
            //   gender: Yup.string().required("Required"),
            //   marital_status: Yup.string().required("Required"),
            //   church: Yup.string().required("Required"),
            //   association: Yup.string().required("Required"),
            //   conference: Yup.string().required("Required"),
            //   churchUnit: Yup.string().required("Required"),
            //   churchMinistry: Yup.string().required("Required"),
            //   baptismalCertificate: Yup.string().required("Required"),
            //   password: Yup.string().required("Required"),
            //   authenticationPin: Yup.string().required("Required"),
            // })}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values);

              setSubmitting(false);
            }}
            validateOnChange
            validateOnBlur
            validateOnSubmit
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9">First Name</span>}
                      className="my-3 text-color-9 position-relative"
                    >
                      <Field
                        type="text"
                        name="firstname"
                        className={`form-control ${
                          touched.firstname && errors.firstname
                            ? "is-invalid"
                            : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.firstname && touched.firstname && (
                        <small className="text-danger">
                          {errors.firstname}
                        </small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9"> Last Name </span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="lastname"
                        className={`form-control ${
                          touched.lastname && errors.lastname
                            ? "is-invalid"
                            : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.lastname && touched.lastname && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9">Other Name</span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="othername"
                        className={`form-control ${
                          touched.othername && errors.othername
                            ? "is-invalid"
                            : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.othername && touched.othername && (
                        <small className="text-danger">
                          {errors.othername}
                        </small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9">Email</span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="email"
                        className={`form-control ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.email && touched.email && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9">Phone Number</span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="phone"
                        className={`form-control ${
                          touched.phone && errors.phone ? "is-invalid" : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.phone && touched.phone && (
                        <small className="text-danger">{errors.phone}</small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9"> Address</span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="address"
                        className={`form-control ${
                          touched.address && errors.address ? "is-invalid" : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.address && touched.address && (
                        <small className="text-danger">{errors.address}</small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9"> City</span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="city"
                        className={`form-control ${
                          touched.city && errors.city ? "is-invalid" : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.city && touched.city && (
                        <small className="text-danger">{errors.city}</small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9"> State</span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="state"
                        className={`form-control ${
                          touched.state && errors.state ? "is-invalid" : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.state && touched.state && (
                        <small className="text-danger">{errors.state}</small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9"> Country</span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="country"
                        className={`form-control ${
                          touched.country && errors.country ? "is-invalid" : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.country && touched.country && (
                        <small className="text-danger">{errors.country}</small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={
                        <span className="text-color-9"> Date Of Birth</span>
                      }
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="dob"
                        className={`form-control ${
                          touched.dob && errors.dob ? "is-invalid" : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.dob && touched.dob && (
                        <small className="text-danger">{errors.dob}</small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9">Gender</span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="gender"
                        className={`form-control ${
                          touched.gender && errors.gender ? "is-invalid" : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.gender && touched.gender && (
                        <small className="text-danger">{errors.gender}</small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                  <Col md="6" className="mt-2 ">
                    <FloatingLabel
                      controlId="floating"
                      label={
                        <span className="text-color-9">Marital Status</span>
                      }
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="marital_status"
                        className={`form-control ${
                          touched.marital_status && errors.marital_status
                            ? "is-invalid"
                            : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.marital_status && touched.marital_status && (
                        <small className="text-danger">
                          {errors.marital_status}
                        </small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={edit}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                </Row>

                <small className="fw-bold mx-3 mt-5">CHURCH VERIFICATION</small>
                <Row>
                  <Col md="4">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9">Church</span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="church"
                        className={`form-control ${
                          touched.church && errors.church ? "is-invalid" : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.church && touched.church && (
                        <small className="text-danger">{errors.church}</small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={done}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                  <Col md="4">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9">Association</span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="association"
                        className={`form-control ${
                          touched.association && errors.association
                            ? "is-invalid"
                            : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.association && touched.association && (
                        <small className="text-danger">
                          {errors.association}
                        </small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={done}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                  <Col md="4">
                    <FloatingLabel
                      controlId="floating"
                      label={<span className="text-color-9">Conference</span>}
                      className="my-3"
                    >
                      <Field
                        type="text"
                        name="conference"
                        className={`form-control ${
                          touched.conference && errors.conference
                            ? "is-invalid"
                            : ""
                        }`}
                        readonly="readonly"
                      />
                      {errors.conference && touched.conference && (
                        <small className="text-danger">
                          {errors.conference}
                        </small>
                      )}
                      <div className="position-absolute end-0 translate-middle-y">
                        <Image
                          src={done}
                          alt="Edit"
                          className="mb-5  pb-2 mx-3"
                        />
                      </div>
                    </FloatingLabel>
                  </Col>
                </Row>
                <div className="mt-5">
                  <small className="fw-bold mx-3">CHURCH PARTICIPATION</small>
                  <Row>
                    <Col md="6">
                      <FloatingLabel
                        controlId="floating"
                        label={
                          <span className="text-color-9">Church Unit</span>
                        }
                        className="my-3"
                      >
                        <Field
                          type="text"
                          name="churchUnit"
                          className={`form-control ${
                            touched.churchUnit && errors.churchUnit
                              ? "is-invalid"
                              : ""
                          }`}
                          readonly="readonly"
                        />
                        {errors.churchUnit && touched.churchUnit && (
                          <small className="text-danger">
                            {errors.churchUnit}
                          </small>
                        )}
                        <div className="position-absolute end-0 translate-middle-y">
                          <Image
                            src={done}
                            alt="Edit"
                            className="mb-5  pb-2 mx-3"
                          />
                        </div>
                      </FloatingLabel>
                    </Col>
                    <Col md="6">
                      <FloatingLabel
                        controlId="floating"
                        label={
                          <span className="text-color-9">Church Ministry</span>
                        }
                        className="my-3"
                      >
                        <Field
                          type="text"
                          name="churchMinistry"
                          className={`form-control ${
                            touched.churchMinistry && errors.churchMinistry
                              ? "is-invalid"
                              : ""
                          }`}
                          readonly="readonly"
                        />
                        {errors.churchMinistry && touched.churchMinistry && (
                          <small className="text-danger">
                            {errors.churchMinistry}
                          </small>
                        )}
                        <div className="position-absolute end-0 translate-middle-y">
                          <Image
                            src={done}
                            alt="Edit"
                            className="mb-5  pb-2 mx-3"
                          />
                        </div>
                      </FloatingLabel>
                    </Col>
                  </Row>
                </div>
                <div className="mt-5">
                  <small className="fw-bold mx-3">DOCUMENT</small>

                  <Row>
                    <Col>
                      <FloatingLabel
                        controlId="floating"
                        label={
                          <span className="text-color-9">
                            Baptismal Certificate
                          </span>
                        }
                        className="my-3"
                      >
                        <Field
                          type="text"
                          name="baptismalCertificate"
                          className={`form-control ${
                            touched.baptismalCertificate &&
                            errors.baptismalCertificate
                              ? "is-invalid"
                              : ""
                          }`}
                          readonly="readonly"
                        />
                        {errors.baptismalCertificate &&
                          touched.baptismalCertificate && (
                            <small className="text-danger">
                              {errors.baptismalCertificate}
                            </small>
                          )}
                        <div className="position-absolute end-0 translate-middle-y">
                          <Image
                            src={done}
                            alt="Edit"
                            className="mb-5  pb-2 mx-3"
                          />
                        </div>
                      </FloatingLabel>
                    </Col>
                  </Row>
                </div>
                <div className="mt-5">
                  <small className="fw-bold mx-3">VERIFIED BY</small>

                  {userDetails?.data?.membership?.is_verified ? (
                    <Row className="bg-color-3 mx-3 p-2 my-3">
                      <Col className="d-flex">
                        <Row className="bg-color-3 mx-1 p-2 my-3">
                          <Col className="d-flex">
                            <div className="">
                              <Image src={logo} width={50} height={50} />
                            </div>

                            <div className="mx-2">
                              <h6>
                                {" "}
                                {
                                  userDetails?.data?.membership?.verified_by
                                }{" "}
                              </h6>
                              {/* <small>Pastor Faith B.C Lugbe</small> */}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : null}
                </div>
                {/* <div className="mt-5">
                  <small className="fw-bold mx-3">SECURITY</small>

                  <Row>
                    <Col md="6">
                      <FloatingLabel
                        controlId="floating"
                        label={<span className="text-color-9">Password</span>}
                        className="my-3"
                      >
                        <Field
                          type="text"
                          name="password"
                          className={`form-control ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                        />
                        {errors.password && touched.password && (
                          <small className="text-danger">
                            {errors.password}
                          </small>
                        )}
                        <div className="position-absolute end-0 translate-middle-y">
                          <Image
                            src={edit}
                            alt="Edit"
                            className="mb-5  pb-2 mx-3"
                          />
                        </div>
                      </FloatingLabel>
                    </Col>
                    <Col md="6">
                      <FloatingLabel
                        controlId="floating"
                        label={
                          <span className="text-color-9">
                            Authentication PIN{" "}
                          </span>
                        }
                        className="my-3"
                      >
                        <Field
                          type="text"
                          name="authenticationPin"
                          className={`form-control ${
                            touched.authenticationPin &&
                            errors.authenticationPin
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                        />
                        {errors.authenticationPin &&
                          touched.authenticationPin && (
                            <small className="text-danger">
                              {errors.authenticationPin}
                            </small>
                          )}
                        <div className="position-absolute end-0 translate-middle-y">
                          <Image
                            src={edit}
                            alt="Edit"
                            className="mb-5  pb-2 mx-3"
                          />
                        </div>
                      </FloatingLabel>
                    </Col>
                  </Row>
                </div> */}
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </MemberLayout>
  );
}

export default UserProfile;
