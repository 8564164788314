import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Button, Col, Container, Row, Nav, Image } from "react-bootstrap";
import * as Yup from "yup";
import { FloatingLabel } from "react-bootstrap";
import MemberLayout from "../../components/layouts/member-layout";
import edit from "../../assets/Baptist One Components/Edit.png";
import info from "../../assets/Baptist One Components/info.png";
import back from "../../assets/Baptist One Components/back.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAuthUsersDetailsAsync,
  updateAuthUserBasicInfoAsync,
  updateAuthUserChurchInfoAsync,
} from "../../slices/user/userSlice";
import { toast } from "react-toastify";
import { getAllCountriesAsync } from "../../slices/data/dataSlice";
import { fetchAllUserChurchAsync } from "../../slices/church/churchSlice";
import {
  fetchAllAssociationAsync,
  fetchAllConferenceAsync,
} from "../../slices/config/configSlice";

function MembershipRegistration() {
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState(null);
  const [profilePic, setProfilePic] = useState();
  const countries = useSelector((state) => state.data.countriesReponse);
  const churches = useSelector((state) => state.church.allUserChurchResponse);
  const config = useSelector((state) => state.config);

  const [activeTab, setActiveTab] = useState("basic-profile");
  const userDetails = useSelector((state) => state.users.authUserResponse);

  useEffect(() => {
    setActiveTab("basic-profile");

    dispatch(
      fetchAuthUsersDetailsAsync({
        detailed: "true",
      })
    );
  }, [dispatch]);

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    // if (!file.type.startsWith("image/")) {
    //   toast.info("Please upload an image file.");
    //   return;
    // }

    const imgInKb = file.size / 1024;

    if (imgInKb > 500) {
      toast.info("Image size must be 500kb or less");

      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    setImageUrl(URL.createObjectURL(file));

    reader.onloadend = () => {
      setProfilePic(reader.result);
    };
  };

  useEffect(() => {
    try {
      dispatch(getAllCountriesAsync());
    } catch (error) {
      console.log(error);
    }

    try {
      dispatch(
        fetchAllUserChurchAsync({
          detailed: false,
          page: "all",
          size: "all",
        })
      );
    } catch (error) {
      console.log(error);
    }

    try {
      dispatch(
        fetchAllConferenceAsync({
          detailed: false,
          page: "all",
          size: "all",
        })
      );
    } catch (error) {
      console.log(error);
    }

    try {
      dispatch(
        fetchAllAssociationAsync({
          detailed: false,
          page: "all",
          size: "all",
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  return (
    <MemberLayout>
      <Container>
        <Row className="mt-3">
          <Col>
            <Nav
              variant="underline"
              activeKey={activeTab}
              onSelect={handleTabSelect}
              className="text-black"
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="basic-profile"
                  style={{
                    color: "black",
                    textDecoration: "none",
                    borderBottom:
                      activeTab === "basic-profile"
                        ? "2px solid #CAAF74"
                        : "2px solid transparent",
                  }}
                >
                  Basic Profile
                </Nav.Link>{" "}
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="church"
                  style={{
                    color: "black",
                    textDecoration: "none",
                    borderBottom:
                      activeTab === "church"
                        ? "2px solid #CAAF74"
                        : "2px solid transparent",
                  }}
                >
                  Church
                </Nav.Link>{" "}
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="preferences"
                  style={{
                    color: "black",
                    textDecoration: "none",
                    borderBottom:
                      activeTab === "preferences"
                        ? "2px solid #CAAF74"
                        : "2px solid transparent",
                  }}
                >
                  Preferences
                </Nav.Link>{" "}
              </Nav.Item>
              <Nav.Item className="ms-auto">
                <Image src={back} width={15} height={11} className="mt-3" />
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        {activeTab === "basic-profile" && (
          <div>
            <Row className="text-center mt-5 ">
              <Col md={4} xs={4}></Col>
              <Col ms={4} xs={4}>
                <div className=" text-center">
                  <div className="upload-picture-sec">
                    {imageUrl && (
                      <Image
                        src={imageUrl}
                        width={100}
                        className=" upload-picture-sec"
                      />
                    )}
                  </div>
                  <div class="fileUpload btn btn-light my-3">
                    <label class="upload">
                      <input
                        className="img-upload"
                        type="file"
                        accept="image/*"
                        placeholder=""
                        onChange={handleImageUpload}
                      />
                      <small>Edit</small>
                    </label>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={4}></Col>
            </Row>
            <Formik
              initialValues={{
                firstname: userDetails?.data?.firstname,
                lastname: userDetails?.data?.lastname,
                othername: userDetails?.data?.othername,
                email: userDetails?.data?.email,
                phone: userDetails?.data?.phone,
                address: userDetails?.data?.address,
                city: userDetails?.data?.city,
                state: userDetails?.data?.state,
                country: userDetails?.data?.country,
                dob: userDetails?.data?.dob,
                gender: userDetails?.data?.gender,
                marital_status: userDetails?.data?.marital_status,
                // password: userDetails?.data?.password,
                // authentication_pin: userDetails?.data?.authentication_pin,
              }}
              // validationSchema={Yup.object({
              //   firstname: Yup.string().required("Required"),
              //   lastname: Yup.string().required("Required"),
              //   othername: Yup.string().required("Required"),
              //   email: Yup.string().required("Required"),
              //   phone: Yup.string().required("Required"),
              //   address: Yup.string().required("Required"),
              //   city: Yup.string().required("Required"),
              //   state: Yup.string().required("Required"),
              //   country: Yup.string().required("Required"),
              //   dob: Yup.string().required("Required"),
              //   gender: Yup.string().required("Required"),
              //   marital_status: Yup.string().required("Required"),
              //   password: Yup.string().required("Required"),
              //   authentication_pin: Yup.string().required("Required"),
              // })}
              onSubmit={(values) => {
                const payload = {
                  ...values,
                  image: profilePic,
                };
                dispatch(
                  updateAuthUserBasicInfoAsync({
                    data: payload,
                  })
                );
              }}
              validateOnChange
              validateOnBlur
              validateOnSubmit
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <div>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <small className="fw-bold mx-3 mt-3">BASIC PROFILE</small>

                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9">First Name</span>
                          }
                          className="my-3 text-color-9 position-relative"
                        >
                          <Field
                            type="text"
                            name="firstname"
                            className={`form-control ${
                              touched.firstname && errors.firstname
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {errors.firstname && touched.firstname && (
                            <small className="text-danger">
                              {errors.firstname}
                            </small>
                          )}
                          <div className="position-absolute end-0 translate-middle-y">
                            <Image
                              src={edit}
                              alt="Edit"
                              className="mb-5  pb-2 mx-3"
                            />
                          </div>
                        </FloatingLabel>
                      </Col>
                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9"> Last Name </span>
                          }
                          className="my-3"
                        >
                          <Field
                            type="text"
                            name="lastname"
                            className={`form-control ${
                              touched.lastname && errors.lastname
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {errors.lastname && touched.lastname && (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          )}
                          <div className="position-absolute end-0 translate-middle-y">
                            <Image
                              src={edit}
                              alt="Edit"
                              className="mb-5  pb-2 mx-3"
                            />
                          </div>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9">Other Name</span>
                          }
                          className="my-3"
                        >
                          <Field
                            type="text"
                            name="othername"
                            className={`form-control ${
                              touched.othername && errors.othername
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {errors.othername && touched.othername && (
                            <small className="text-danger">
                              {errors.othername}
                            </small>
                          )}
                          <div className="position-absolute end-0 translate-middle-y">
                            <Image
                              src={edit}
                              alt="Edit"
                              className="mb-5  pb-2 mx-3"
                            />
                          </div>
                        </FloatingLabel>
                      </Col>
                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={<span className="text-color-9">Email</span>}
                          className="my-3"
                        >
                          <Field
                            type="email"
                            name="email"
                            className={`form-control ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                          />
                          {errors.email && touched.email && (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          )}
                          <div className="position-absolute end-0 translate-middle-y">
                            <Image
                              src={edit}
                              alt="Edit"
                              className="mb-5  pb-2 mx-3"
                            />
                          </div>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9">Phone Number</span>
                          }
                          className="my-3"
                        >
                          <Field
                            type="tel"
                            name="phone"
                            className={`form-control ${
                              touched.phone && errors.phone ? "is-invalid" : ""
                            }`}
                          />
                          {errors.phone && touched.phone && (
                            <small className="text-danger">
                              {errors.phone}
                            </small>
                          )}
                          <div className="position-absolute end-0 translate-middle-y">
                            <Image
                              src={edit}
                              alt="Edit"
                              className="mb-5  pb-2 mx-3"
                            />
                          </div>
                        </FloatingLabel>
                      </Col>
                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={<span className="text-color-9"> Address</span>}
                          className="my-3"
                        >
                          <Field
                            type="text"
                            name="address"
                            className={`form-control ${
                              touched.address && errors.address
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {errors.address && touched.address && (
                            <small className="text-danger">
                              {errors.address}
                            </small>
                          )}
                          <div className="position-absolute end-0 translate-middle-y">
                            <Image
                              src={edit}
                              alt="Edit"
                              className="mb-5  pb-2 mx-3"
                            />
                          </div>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={<span className="text-color-9"> Country</span>}
                          className="my-3"
                        >
                          <Field
                            as="select"
                            name="country"
                            className={`form-control ${
                              touched.country && errors.country
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Country</option>
                            {countries?.data?.map((country, index) => (
                              <option key={index} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </Field>

                          {errors.country && touched.country && (
                            <small className="text-danger">
                              {errors.country}
                            </small>
                          )}
                          <div className="position-absolute end-0 translate-middle-y">
                            <Image
                              src={edit}
                              alt="Edit"
                              className="mb-5  pb-2 mx-3"
                            />
                          </div>
                        </FloatingLabel>
                      </Col>
                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={<span className="text-color-9"> State</span>}
                          className="my-3"
                        >
                          <Field
                            as="select"
                            name="state"
                            className={`form-control ${
                              touched.state && errors.state ? "is-invalid" : ""
                            }`}
                          >
                            <option>Select State</option>
                            {countries?.data?.length > 0 ? (
                              countries.data[0]?.states?.map((state, index) => (
                                <option key={index} value={state}>
                                  {state}
                                </option>
                              ))
                            ) : (
                              <option value="" disabled>
                                No states available
                              </option>
                            )}
                          </Field>
                          {errors.state && touched.state && (
                            <small className="text-danger">
                              {errors.state}
                            </small>
                          )}
                          <div className="position-absolute end-0 translate-middle-y">
                            <Image
                              src={edit}
                              alt="Edit"
                              className="mb-5  pb-2 mx-3"
                            />
                          </div>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={<span className="text-color-9"> City</span>}
                          className="my-3"
                        >
                          <Field
                            type="text"
                            name="city"
                            className={`form-control ${
                              touched.city && errors.city ? "is-invalid" : ""
                            }`}
                            required
                          />
                          {errors.city && touched.city && (
                            <small className="text-danger">{errors.city}</small>
                          )}
                          <div className="position-absolute end-0 translate-middle-y">
                            <Image
                              src={edit}
                              alt="Edit"
                              className="mb-5  pb-2 mx-3"
                            />
                          </div>
                        </FloatingLabel>
                      </Col>

                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9"> Date Of Birth</span>
                          }
                          className="my-3"
                        >
                          <Field
                            type="date"
                            name="dob"
                            className={`form-control ${
                              touched.dob && errors.dob ? "is-invalid" : ""
                            }`}
                          />
                          {errors.dob && touched.dob && (
                            <small className="text-danger">{errors.dob}</small>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={<span className="text-color-9">Gender</span>}
                          className="my-3"
                        >
                          <Field
                            as="select"
                            name="gender"
                            className={`form-control ${
                              touched.gender && errors.gender
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </Field>
                          {errors.gender && touched.gender && (
                            <small className="text-danger">
                              {errors.gender}
                            </small>
                          )}
                        </FloatingLabel>
                      </Col>
                      <Col md="6" className="mt-2 ">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9">Marital Status</span>
                          }
                          className="my-3"
                        >
                          <Field
                            as="select"
                            name="marital_status"
                            className={`form-control ${
                              touched.marital_status && errors.marital_status
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Marital Status</option>
                            <option value="single">Single</option>
                            <option value="engaged">Engaged</option>
                            <option value="married">Married</option>
                            <option value="widowed">Widowed</option>
                          </Field>

                          {errors.marital_status && touched.marital_status && (
                            <small className="text-danger">
                              {errors.marital_status}
                            </small>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>

                    {/* <div className="mt-5">
                      <small className="fw-bold mx-3">SECURITY</small>

                      <Row>
                        <Col md="6">
                          <FloatingLabel
                            controlId="floating"
                            label={
                              <span className="text-color-9">Password</span>
                            }
                            className="my-3"
                          >
                            <Field
                              type="text"
                              name="password"
                              className={`form-control ${
                                touched.password && errors.password
                                  ? "is-invalid"
                                  : ""
                              }`}
                              required
                            />
                            {errors.password && touched.password && (
                              <small className="text-danger">
                                {errors.password}
                              </small>
                            )}
                            <div className="position-absolute end-0 translate-middle-y">
                              <Image
                                src={edit}
                                alt="Edit"
                                className="mb-5  pb-2 mx-3"
                              />
                            </div>
                          </FloatingLabel>
                        </Col>
                        <Col md="6">
                          <FloatingLabel
                            controlId="floating"
                            label={
                              <span className="text-color-9">
                                Authentication PIN{" "}
                              </span>
                            }
                            className="my-3"
                          >
                            <Field
                              type="text"
                              name="authenticationPin"
                              className={`form-control ${
                                touched.authenticationPin &&
                                errors.authenticationPin
                                  ? "is-invalid"
                                  : ""
                              }`}
                              required
                            />
                            {errors.authenticationPin &&
                              touched.authenticationPin && (
                                <small className="text-danger">
                                  {errors.authenticationPin}
                                </small>
                              )}
                            <div className="position-absolute end-0 translate-middle-y">
                              <Image
                                src={edit}
                                alt="Edit"
                                className="mb-5  pb-2 mx-3"
                              />
                            </div>
                          </FloatingLabel>
                        </Col>
                      </Row>
                    </div> */}
                    {/* <div className="mt-5">
                      <small className="fw-bold mx-3">ADD FAMILY DETAILS</small>

                      <Row>
                        <Col xs={7} className="my-3">
                          <Field
                            type="text"
                            name="wifeName"
                            placeholder="Wife's Name"
                            className={`form-control ${
                              touched.password && errors.password
                                ? "is-invalid"
                                : ""
                            }`}
                            required
                          />
                          {errors.password && touched.password && (
                            <small className="text-danger">
                              {errors.password}
                            </small>
                          )}
                        </Col>
                        <Col xs={5} className="my-3">
                          <Field
                            type="text"
                            name="wifeDOB"
                            placeholder="Date Of Birth"
                            className={`form-control ${
                              touched.wifeDOB && errors.wifeDOB
                                ? "is-invalid"
                                : ""
                            }`}
                            required
                          />
                          {errors.wifeDOB && touched.wifeDOB && (
                            <small className="text-danger">
                              {errors.wifeDOB}
                            </small>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={7} className="my-3">
                          <Field
                            type="text"
                            name="childName"
                            placeholder="Child's Name"
                            className={`form-control ${
                              touched.childName && errors.childName
                                ? "is-invalid"
                                : ""
                            }`}
                            required
                          />
                          {errors.childName && touched.childName && (
                            <small className="text-danger">
                              {errors.childName}
                            </small>
                          )}
                        </Col>
                        <Col xs={5} className="my-3">
                          <Field
                            type="text"
                            name="childDOB"
                            placeholder="Date Of Birth"
                            className={`form-control ${
                              touched.childDOB && errors.childDOB
                                ? "is-invalid"
                                : ""
                            }`}
                            required
                          />
                          {errors.childDOB && touched.childDOB && (
                            <small className="text-danger">
                              {errors.childDOB}
                            </small>
                          )}
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col>
                          <p>
                            <span>
                              <Image src={addMore} className="mx-2" />
                              Add more children
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </div> */}
                    <Row className="justify-content-between mb-5">
                      <Col sm={6} xs={6}>
                        <Button className="user-save  my-3" type="submit">
                          {" "}
                          Save
                        </Button>
                      </Col>
                      <Col sm={6} xs={6}>
                        <Button
                          // type="submit"
                          className=" user-continue my-3 float-end "
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        )}
        {activeTab === "church" && (
          <div className="mt-4">
            <small className="fw-bold mt-5">CHURCH VERIFICATION</small>
            <Formik
              initialValues={{
                church_id: userDetails?.data?.membership?.church?.id,
                join_date: userDetails?.data?.membership?.join_date,
                type: userDetails?.data?.membership?.type,
                association_id: userDetails?.data?.membership?.association?.id,
                conference_id:
                  userDetails?.data?.membership?.association?.conference?.id,
                unit: "",
                ministry: "",
              }}
              // validationSchema={Yup.object({
              //   church_id: Yup.string().required("Required"),
              //   join_date: Yup.string().required("Required"),
              //   type: Yup.string().required("Required"),
              //   association_id: Yup.string().required("Required"),
              //   conference_id: Yup.string().required("Required"),
              //   unit: Yup.string().required("Required"),
              //   ministry: Yup.string().required("Required"),
              // })}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(
                  updateAuthUserChurchInfoAsync({
                    data: values,
                  })
                );

                setSubmitting(false);
              }}
              validateOnChange
              validateOnBlur
              validateOnSubmit
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <div>
                  <Form onSubmit={handleSubmit}>
                    <Row className="">
                      <Col md={6} className="">
                        <FloatingLabel
                          controlId="floating"
                          label={<span className="text-color-9">Church</span>}
                          className="my-3"
                        >
                          <Field
                            as="select"
                            name="church_id"
                            className={`form-control ${
                              touched.church_id && errors.church_id
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select Church</option>
                            {churches?.data?.items?.map((church, index) => (
                              <option key={index} value={church.id}>
                                {church.name}
                              </option>
                            ))}
                          </Field>

                          {errors.church_id && touched.church_id && (
                            <small className="text-danger">
                              {errors.church_id}
                            </small>
                          )}
                        </FloatingLabel>
                      </Col>
                      <Col md={6} className="">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9">
                              Date of Joining the Church
                            </span>
                          }
                          className="my-3"
                        >
                          <Field
                            type="date"
                            name="join_date"
                            placeholder="Date of Joining the Church "
                            className={`form-control ${
                              touched.join_date && errors.join_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {errors.join_date && touched.join_date && (
                            <small className="text-danger">
                              {errors.join_date}
                            </small>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col md={6} className="">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9">
                              Membership Type
                            </span>
                          }
                          className="my-3"
                        >
                          <Field
                            as="select"
                            name="type"
                            className={`form-control ${
                              touched.type && errors.type ? "is-invalid" : ""
                            }`}
                          >
                            <option value="">Select Membership Type </option>
                            <option value="by birth">By Birth</option>
                            <option value="by baptism">By Baptism</option>
                            <option value="by relocation">By Relocation</option>
                          </Field>

                          {errors.type && touched.type && (
                            <small className="text-danger">
                              {errors.marital_status}
                            </small>
                          )}
                        </FloatingLabel>

                        {errors.type && touched.type && (
                          <small className="text-danger">{errors.type}</small>
                        )}
                      </Col>
                      <Col md={6} className="">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9">Association</span>
                          }
                          className="my-3"
                        >
                          <Field
                            as="select"
                            name="association_id"
                            className={`form-control ${
                              touched.association_id && errors.association_id
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select Association</option>
                            {config?.allAssociationResponse?.data?.items?.map(
                              (association, index) => (
                                <option key={index} value={association.id}>
                                  {association.name}
                                </option>
                              )
                            )}
                          </Field>

                          {errors.association_id && touched.association_id && (
                            <small className="text-danger">
                              {errors.association_id}
                            </small>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col md={6} className="">
                        <FloatingLabel
                          controlId="floating"
                          label={
                            <span className="text-color-9">Conference</span>
                          }
                          className="my-3"
                        >
                          <Field
                            as="select"
                            name="conference_id"
                            className={`form-control ${
                              touched.conference_id && errors.conference_id
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select Conference</option>
                            {config?.allConferenceResponse?.data?.items?.map(
                              (church, index) => (
                                <option key={index} value={church.id}>
                                  {church.name}
                                </option>
                              )
                            )}
                          </Field>

                          {errors.conference_id && touched.conference_id && (
                            <small className="text-danger">
                              {errors.conference_id}
                            </small>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <small className="fw-bold mt-5">
                      DO YOU BELONG TO ANY MINISTRY/UNIT IN YOUR CHURCH
                    </small>
                    <Row>
                      <Col>
                        <span>
                          {" "}
                          <Field type="checkbox" name="yes" />
                          <small className="mx-2">Yes</small>
                        </span>

                        <span>
                          {" "}
                          <Field type="checkbox" name="no" />
                          <small className="mx-2">No</small>
                        </span>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col md={6} className="">
                        <label>
                          <small>Unit I Belong</small>
                        </label>
                        <Field
                          type="select"
                          name="unit"
                          placeholder="General Missionary Union "
                          className={`form-control ${
                            touched.unit && errors.unit ? "is-invalid" : ""
                          }`}
                          required
                        />
                        {errors.unit && touched.unit && (
                          <small className="text-danger">{errors.unit}</small>
                        )}
                      </Col>
                      <Col md={6} className="my-3">
                        <label>
                          <small>Church Ministry</small>
                        </label>
                        <Field
                          type="select"
                          name="ministry"
                          placeholder="Men Missionary Union  "
                          className={`form-control ${
                            touched.ministry && errors.ministry
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                        />
                        {errors.ministry && touched.ministry && (
                          <small className="text-danger">
                            {errors.ministry}
                          </small>
                        )}
                      </Col>
                    </Row>
                    <div className="mx-1">
                      <small className="fw-bold mt-5">
                        SUPPORTING DOCUMENT
                      </small>{" "}
                      <br />
                      <Row className="mt-2">
                        <Col md={1} xs={1} className="mt-3">
                          <Image src={info} />
                        </Col>
                        <Col md={11} xs={11}>
                          <small
                            style={{ fontSize: "11px", color: "#21225C80" }}
                          >
                            The document could be a transfer letter from your
                            former church, certificate of baptism or any
                            document to proof your membership
                          </small>
                        </Col>
                      </Row>
                    </div>
                    <Row className="mt-3">
                      <Col md={6} className="my-3">
                        <label>
                          <small>Upload Documents</small>
                        </label>
                        <Field
                          type="file"
                          name="other"
                          className="form-control mt-2"
                          onChange={handleChange}
                          isInvalid={!!errors.other}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-between mb-5">
                      <Col sm={6} xs={6}>
                        <Button className="user-save  my-3">Back</Button>
                      </Col>
                      <Col sm={6} xs={6}>
                        <Button
                          type="submit"
                          className=" user-continue my-3 float-end "
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        )}
        {activeTab === "preferences" && (
          <Formik>
            <Form>
              <div className="p-3">
                <Row>
                  <Col md="6">
                    <span>
                      {" "}
                      <Field type="checkbox" name="notifications" />
                      <small className="mx-2">
                        Opt-in for receiving notifications and updates
                      </small>
                    </span>
                  </Col>
                  <Col md="6">
                    <span>
                      {" "}
                      <Field type="checkbox" name="donations" />
                      <small className="mx-2">
                        Opt-in for receiving receiving donations
                      </small>
                    </span>
                  </Col>
                </Row>
                <Row className="justify-content-between fixed-bottom mb-4 mx-2">
                  <Col sm={6} xs={6}>
                    <Button className="user-save  my-3">Back</Button>
                  </Col>
                  <Col sm={6} xs={6}>
                    <Button
                      type="submit"
                      className=" user-continue my-3 float-end "
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </Formik>
        )}
      </Container>
    </MemberLayout>
  );
}

export default MembershipRegistration;
