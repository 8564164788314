import React from "react";
import LandingLayout from "./components/landing-page-layout";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import Home from "../../assets/landing-page-images/home-img.png";
import Member from "../../assets/landing-page-images/member-img.png";
import Pastor from "../../assets/landing-page-images/pastor-img.png";
import Phone from "../../assets/landing-page-images/phone-img.png";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
function LandingPage() {
  return (
    <LandingLayout>
      <div className="hero-section">
        <Container>
          <Row>
            <Col md={7}>
              <div className="  mt-5 pt-5">
                <h1 className="display-4 fw-bold text-color-2 mt-5 pt-5">
                  Empowering Worship,{" "}
                </h1>
                <h1 className="display-4 fw-bold text-color-2">
                  Enriching Lives{" "}
                </h1>
                <p className="text-black my-5 font-18">
                  “I appeal to you, dear brothers and sisters, by the authority
                  of <br />
                  our Lord Jesus Christ, to live in harmony with each other. Let{" "}
                  <br />
                  there be no divisions in the church. Rather, be of one mind,{" "}
                  <br />
                  united in thought and purpose.” - 1 Corinthians 1:10
                </p>
                <Button
                  className=" reg-church-btn font-16 me-4 my-4"
                  as={Link}
                  to="/user-registration"
                >
                  <p className="font-16 text-white">
                    Get Started <FaArrowRightLong />
                  </p>
                </Button>
                <Button
                  className="reg-member-btn  text-white"
                  as={Link}
                  to="/user-login"
                >
                  <p className="font-16 text-white">
                    Login as a Member <FaArrowRightLong />{" "}
                  </p>
                </Button>
              </div>
            </Col>
            <Col md={5}>
              <Image
                src={Home}
                width={681}
                height={861}
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Row>
        <Container className="text-center bg-color-1">
          <div className="empower-texts">
            <h1 className=" display-3 fw-bold text-color-1">
              Empower Your Ministry
            </h1>
            <h1 className=" display-3 fw-bold text-white">
              Where Innovation Meets <br /> Devotion
            </h1>
          </div>
          <div className="my-5 py-5">
            <Button
              className=" reg-church-btn2 font-16"
              as={Link}
              to="/user-add-church"
            >
              <p className="font-16 text-color-10">Register as a Church</p>
            </Button>
            <Button
              className="reg-member-btn mx-3 my-3 text-white"
              as={Link}
              to="/user-registration"
            >
              <p className="font-16 text-white">Register as a Member</p>
            </Button>
          </div>
        </Container>
      </Row>
      <Row>
        <div className="bible-img">
          <div className="container mt-5 pt-5">
            <h4 className="fw-bold text-white">
              Elevate Your Ministry: <br />
              Introducing The All-in-One <br />
              Church Management Solution
            </h4>
            <p className="fw-bold  text-white mt-5 pt-2">
              Imagine a church where:
            </p>
            <span className="d-flex my-2">
              <div className="bullet-point"></div>{" "}
              <p className=" text-white">
                <span className="fw-bold ">Members connect: </span>
                Engage your community with a <br />
                user-friendly platform, fostering discussions, sharing <br />
                resources, and strengthening bonds.
              </p>
            </span>
            <span className="d-flex my-2">
              <div className="bullet-point"></div>{" "}
              <p className=" text-white">
                <span className="fw-bold ">Operations streamline: </span>
                Simplify everyday tasks <br />
                with intuitive tools for managing memberships, <br />
                donations, events, and administrative duties.
              </p>
            </span>
            <span className="d-flex my-2">
              <div className="bullet-point"></div>{" "}
              <p className=" text-white">
                <span className="fw-bold ">Faith deepens: </span>
                Nurture spiritual growth with <br />
                integrated Bible study, devotional materials, and <br />
                multimedia content.
              </p>
            </span>
          </div>
        </div>
      </Row>
      <Row className="my-5 py-3">
        <Container className="">
          <h1 className="fw-bold">Here’s how it empowers you</h1>
        </Container>
      </Row>
      <Row className="bg-color-9 p-5 m-5">
        <Col md={6} className="">
          <Image src={Member} width={540} height={520} className="img-fluid" />
        </Col>
        <Col md={6} className="mt-2">
          <h3 className="text-color-1 display-5 fw-bold">
            For Your <br /> Members
          </h3>
          <p className="text-black mt-4 font-16">
            Join discussions, share experiences, and participate in <br />
            ministries. Access a searchable Bible, hymn library, and <br />
            rich media resources. Get personalized updates, news, <br />
            and event notifications. Contribute to vibrant forums <br />
            and engage with other members. Donate securely with <br />
            recurring options and manage contributions seamlessly.
          </p>
          <div className="mt-5 ">
            <span className="d-flex  fw-bold">
              <div className="bullet-point2"></div>
              <p className="text-black">Connect and Grow</p>
            </span>
            <span className="d-flex  fw-bold">
              <div className="bullet-point2"></div>
              <p className="text-black"> Stay Informed</p>
            </span>
            <span className="d-flex fw-bold">
              <div className="bullet-point2"></div>
              <p className="text-black"> Simplify Giving</p>
            </span>
          </div>
        </Col>
      </Row>
      <Row className="mt-5 pt-5 p-5 m-5">
        <Col md={6} className="mt-5 pt-5">
          <h3 className="text-color-1 display-5 fw-bold">
            For Church <br /> Leaders
          </h3>
          <p className="text-black mt-4 font-16">
            Manage memberships, communication, events, and finances <br />
            with ease. Securely store documents and share resources <br />
            effortlessly. Organize ministries, schedule events, and tailor{" "}
            <br />
            communication to specific groups. Foster deeper
            <br />
            connections within your community. Track donations, and
            <br />
            user activity to make informed decisions. Monitor progress
            <br />
            and measure the impact of your ministries.
          </p>
          <div className="mt-5 ">
            <span className="d-flex  fw-bold">
              <div className="bullet-point2"></div>
              <p className="text-black">Streamline Operations</p>
            </span>
            <span className="d-flex  fw-bold">
              <div className="bullet-point2"></div>
              <p className="text-black"> Boost Engagement</p>
            </span>
            <span className="d-flex fw-bold">
              <div className="bullet-point2"></div>
              <p className="text-black"> Gain Insight</p>
            </span>
          </div>
        </Col>
        <Col md={6}>
          <Image src={Pastor} width={560} height={600} className="img-fluid" />
        </Col>
      </Row>
      <Row className="bg-color-10 mt-5 pt-3">
        <Col md={6} className="pl">
          <h3 className="text-color-2 display-6 fw-bold">
            Don’t just manage <br />
            your church,
            <br />
            thrive with <br />
            BaptistOne
          </h3>

          <div className="mt-3">
            <span className="d-flex fw-bold">
              <div className="bullet-point2"></div>
              <p className="text-black">Scalable</p>
            </span>
            <span className="d-flex fw-bold">
              <div className="bullet-point2"></div>
              <p className="text-black"> Secure and reliable</p>
            </span>
            <span className="d-flex fw-bold">
              <div className="bullet-point2"></div>
              <p className="text-black"> Intuitive and user-friendly</p>
            </span>
          </div>
          <div className="mt-3">
            <Button
              className=" reg-church-btn2 font-16"
              as={Link}
              to="/user-add-church"
            >
              <p className="font-16 text-color-10">Register as a Church</p>
            </Button>
            <Button
              className="reg-member-btn mx-3 my-3 text-white"
              as={Link}
              to="/user-registration"
            >
              <p className="font-16 text-white">Register as a Member</p>
            </Button>
          </div>
        </Col>

        <Col md={6} className="text-end">
          <Image src={Phone} width={700} height={600} className="img-fluid" />
        </Col>
      </Row>
    </LandingLayout>
  );
}

export default LandingPage;
