import React from 'react'
import LandingLayout from './components/landing-page-layout'
import { Container, Image } from "react-bootstrap"
import Back from "../../assets/landing-page-images/back.svg"
import { useNavigate } from "react-router-dom";


function PrivacyPolicy() {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <LandingLayout>
            <Container >
                <Image src={Back} onClick={handleBack} />
                <h1 className='text-center fw-bold display-4'>Privacy Policy</h1>
                <div className='mt-5 pb-5 mb-5'>
                    <p className='font-16 fw-bold text-black'>Effective Date: March 1, 2024</p>
                    <p className='font-16 text-black'>Introduction:</p>
                    <p className='font-16 text-black'>BaptistOne (“we” or “us”) respects the privacy of our users (“you” or “your”). This Privacy Policy describes how we collect, use, disclose, and store your personal information through our church management system (“System”).</p>
                    <p className='font-16 text-black'> Information We Collect: </p>
                    <p className='font-16 text-black'> We collect several types of information from you, including: </p>
                    <p className='font-16 text-black'>Personal Information: Name, email address, phone number, address, date of birth, gender, marital status, and other information you provide during registration or use of the System.<br />
                        User Activity: Data about your interaction with the System, such as searches, forums participation, downloaded resources, and event registrations.<br />
                        Device Information: IP address, browser type, operating system, and device identifiers. </p>
                    <p className='font-16 text-black'> How We Use Your Information:</p>
                    <p className='font-16 text-black'> We use your information to: </p>
                    <p className='font-16 text-black'> Provide and operate the System: Manage user accounts, facilitate communication, process donations, and offer our services. <br />
                        Enhance your experience: Personalize content, send relevant updates, and improve the System’s functionality.<br />
                        Communicate with you: Send news, event announcements, and respond to inquiries.<br />
                        Analyze usage: Understand how users interact with the System and improve our services. <br />
                        Fulfill legal and regulatory obligations: Comply with data protection laws and respond to lawful requests.<br /></p>
                    <p className='font-16 text-black'> Sharing Your Information:</p>
                    <p className='font-16 text-black'> We may share your information with: </p>
                    <p className='font-16 text-black'> Service providers: Third-party companies that help us operate the System, such as payment processors and data storage providers. These companies are obligated to protect your information and use it only for the purposes we authorize.<br />
                        Law enforcement and regulatory bodies: If required by law or to protect our rights or the rights of others. <br />
                        With your consent: We may share your information with other parties with your express permission.</p>
                    <p className='font-16 text-black'> Data Retention: </p>
                    <p className='font-16 text-black'> We retain your information for as long as you have an account with us or as necessary to fulfill the purposes described in this policy. We also retain certain information for legal or regulatory compliance purposes. </p>
                    <p className='font-16 text-black'> Your Choices: </p>
                    <p className='font-16 text-black'> You have choices regarding your information:</p>
                    <p className='font-16 text-black'> Access and Update: You can access and update your personal information through your account settings.<br />
                        Unsubscribe: You can unsubscribe from marketing communications by following the instructions in the emails we send you.<br />
                        Data Deletion: You can request deletion of your account and personal information through the System or by contacting us.<br />
                        Security: We implement reasonable security measures to protect your information from unauthorized access, use, disclosure, alteration, or destruction. However, no internet or electronic data transmission is completely secure.</p>
                    <p className='font-16 text-black'>Children’s Privacy: </p>
                    <p className='font-16 text-black'> The System is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.</p>
                    <p className='font-16 text-black'> Changes to this Policy:</p>
                    <p className='font-16 text-black'> We may update this policy from time to time. We will notify you of any changes by posting the updated policy on our website. </p>
                    <p className='font-16 text-black'> Contact Us:</p>
                    <p className='font-16 text-black'> If you have any questions about this policy, please contact us at support@rokswood.com.</p>
                </div>
            </Container>
        </LandingLayout>
    )
}

export default PrivacyPolicy