import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import home from "../../assets/Baptist One Components/home-2.png";
import report from "../../assets/Baptist One Components/report.png";
import wallet from "../../assets/Baptist One Components/wallet.png";
import profile from "../../assets/Baptist One Components/profile.png";

function Footer() {
  return (
    <footer>
      <div className="fixed-bottom bg-color-3 pt-2">
        <Container>
          <Row>
            <Col md={3} xs={3} sm={3}>
              <div>
                {" "}
                <div className="">
                  <Link to="/user-dashboard">
                    <Image src={home} />
                  </Link>
                  <br />
                </div>  {" "}
              </div>
            </Col>
            <Col md={3} xs={3} sm={3}>
              <div>
                {" "}
                <div className="">
                  <Link to="">
                    <Image src={report} />
                  </Link>
                  <br />
                </div>{" "}
              </div>
            </Col>
            <Col md={3} xs={3} sm={3}>
              <div>
                {" "}
                <div className="">
                  <Link>
                    <Image src={wallet} />
                  </Link>
                  <br />
                </div>{" "}
              </div>
            </Col>
            <Col md={3} xs={3} sm={3}>
              <div>
                {" "}
                <div className="">
                  <Link to="/user-profile">
                    <Image src={profile} />
                  </Link>
                  <br />
                </div>{" "}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}
export default Footer;
