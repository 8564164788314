import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  Image,
  Form,
  FormControl,
  Table,
  Nav,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminLayout from "../../../components/layouts/admin-layout";
import {
  FaArrowAltCircleLeft,
  FaSearch,
  FaThLarge,
  FaBars,
} from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import logo from "../../../assets/images/NRABC.png";
import pdf from "../../../assets/images/pdf (1).svg";
import icon1 from "../../../assets/images/icons/all-members-icon.png";
import icon2 from "../../../assets/images/icons/active-member-icon.png";
import icon3 from "../../../assets/images/icons/income-icon.png";
import icon4 from "../../../assets/images/icons/expenditure-icon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  activateChurchAsync,
  deactivateChurchAsync,
} from "../../../slices/church/churchSlice";

function ViewChurch(onSearch) {
  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    onSearch(searchTerm);
  };
  const [activeTab, setActiveTab] = useState("members");
  const churchDetails = useSelector(
    (state) => state.church.churchDetailsByIdResponse
  );

  const churchMembersDetails = useSelector(
    (state) => state.church.getAllChurchMembersResponse
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setActiveTab("members");
  }, []);

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleDeactivate = (id) => {
    dispatch(deactivateChurchAsync({ church_id: id }));
  };
  const handleActivate = (id) => {
    dispatch(activateChurchAsync({ church_id: id }));
  };
  return (
    <AdminLayout>
      <Container fluid>
        <Row className="my-3">
          <Col md={8}>
            <div>
              <h4>
                <span className="fw-bold text-color-2">
                  <FaArrowAltCircleLeft
                    className="mx-2 text-color-1"
                    onClick={handleBack}
                  />
                  {churchDetails?.data?.name}
                </span>
              </h4>
            </div>
          </Col>
          <Col md={4}>
            <div className="text-end">
              {churchDetails?.data?.status === "active" ? (
                <Button
                  className="rounded-pill  px-4 py-3 deactivate-btn border-0 mx-2"
                  onClick={() => handleDeactivate(churchDetails?.data?.id)}
                >
                  Deactivate
                </Button>
              ) : (
                <Button
                  className="rounded-pill  px-4 py-3 deactivate-btn border-0 mx-2"
                  onClick={() => handleActivate(churchDetails?.data?.id)}
                >
                  Activate
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Card className="view-church-card1">
          <Card.Body>
            <Row>
              <Col md={3} className="d-flex">
                <div className="mt-4 mx-1">
                  <Image src={logo} width={105} height={110} />
                </div>
                <div className="mt-2">
                  <small>Church Name</small>
                  <p className="fw-bold">{churchDetails?.data?.name}</p>
                  <small>City</small>
                  <p className="fw-bold">{churchDetails?.data?.city}</p>
                </div>
              </Col>
              <Col md={2}>
                <div className="mt-2">
                  <small>Country</small>
                  <p className="fw-bold">{churchDetails?.data?.country}</p>
                  <small>Address</small>
                  <p className="fw-bold">{churchDetails?.data?.street}</p>
                </div>
              </Col>

              <Col md={1}>
                <div className="mt-2">
                  <small>Date Planted</small>
                  <p className="fw-bold">{churchDetails?.data?.data_planted}</p>
                  <small>Date Organised</small>
                  <p className="fw-bold">
                    {churchDetails?.data?.date_organized}
                  </p>
                </div>
              </Col>
              <Col md={2}>
                <div className="mt-2">
                  <small>Mother Church</small>
                  <p className="fw-bold">
                    {churchDetails?.data?.mother_church}
                  </p>
                  <small>Contact Person</small>
                  <p className="fw-bold">Rev. John Doe</p>
                  <small>Church Pastor</small>
                </div>
              </Col>
              <Col md={2}>
                <div className="mt-2">
                  <small>Email</small>
                  <p className="fw-bold">
                    {churchDetails?.data?.contact_email}
                  </p>
                  <small>Phone Number</small>
                  <p className="fw-bold">
                    {churchDetails?.data?.contact_phone}
                  </p>
                </div>
              </Col>
              <Col md={2}>
                <div className="mt-2">
                  <small>
                    Verification <br /> Status
                  </small>
                  <Row>
                    <Col>
                      <Button className=" px-3 pending border-0 mt-2 py-2 ">
                        {churchDetails?.data?.status === "active"
                          ? "Acive"
                          : " No Active"}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {/* <Button className="rounded-pill  px-3 bg-color-2 border-0 mt-2 py-2">
                        Confirm
                      </Button> */}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* <Row className="mt-5">
          <Col md={3}>
            <Card className="card1">
              <Card.Body>
                <small>Church Pastor</small>
                <h4>Rev. John Doe</h4>
                <small>johndoe@gmail.com |+234 070 000 000 0000</small>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card2">
              <Card.Body>
                <small>Youth Pastor</small>
                <h4>Rev. John Doe</h4>
                <small>johndoe@gmail.com |+234 070 000 000 0000</small>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card3">
              <Card.Body>
                <small>Music Pastor</small>
                <h4>Rev. John Doe</h4>
                <small>johndoe@gmail.com |+234 070 000 000 0000</small>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="card4">
              <Card.Body>
                <small>Children Pastor</small>
                <h4>Rev. John Doe</h4>
                <small>johndoe@gmail.com |+234 070 000 000 0000</small>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
        <Row className="mt-5">
          <Col md={3}>
            <Card className="dash-card-1">
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <div className="">
                      <small>Total Members</small>
                      <h4> 0 </h4>
                    </div>
                  </Col>
                  <Col>
                    <div className="card-1-icon text-center py-3">
                      <Image
                        src={icon1}
                        alt="All members"
                        className="img-fluid"
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <div>
                      <small className="fw-bold">Active Members</small>
                      <h4>0</h4>
                    </div>
                  </Col>
                  <Col>
                    <div className="card-2-icon text-center py-3">
                      <Image
                        src={icon2}
                        alt="Active members"
                        className="img-fluid"
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <div>
                      <small className="fw-bold">Total Income</small>
                      <h4>0 </h4>
                    </div>
                  </Col>
                  <Col>
                    <div className="card-3-icon text-center py-3">
                      <Image
                        src={icon3}
                        alt=" Total income"
                        className="img-fluid"
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <div>
                      <small>Total Expenditure</small>
                      <h4>0 </h4>
                    </div>
                  </Col>
                  <Col>
                    <div className="card-4-icon text-center py-3">
                      <Image
                        src={icon4}
                        alt=" Total Expenditure"
                        className="img-fluid"
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Card className="mt-5">
            <Card.Body>
              <Row>
                <Col md={3} className="d-flex">
                  <Image src={pdf} width={40} className="mx-3" />
                  <div className="mt-2">
                    <Link>Document.pdf</Link>
                    <p>200KB</p>
                  </div>
                </Col>
                <Col md={3} className="d-flex">
                  <Image src={pdf} width={40} className="mx-3" />
                  <div className="mt-2">
                    <Link>Document.pdf</Link>
                    <p>200KB</p>
                  </div>
                </Col>
                <Col md={3} className="d-flex">
                  <Image src={pdf} width={40} className="mx-3" />
                  <div className="mt-2">
                    <Link>Document.pdf</Link>
                    <p>200KB</p>
                  </div>
                </Col>
                <Col md={3} className="d-flex">
                  <Image src={pdf} width={40} className="mx-3" />
                  <div className="mt-2">
                    <Link>Document.pdf</Link>
                    <p>200KB</p>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <Row className="mt-5">
          <Col md={3}>
            <Nav
              variant="underline"
              activeKey={activeTab}
              onSelect={handleTabSelect}
              className="text-black"
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="members"
                  style={{
                    color: "black",
                    textDecoration: "none",
                    borderBottom:
                      activeTab === "members"
                        ? "2px solid #CAAF74"
                        : "2px solid transparent",
                  }}
                >
                  Members
                </Nav.Link>{" "}
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="pastors"
                  style={{
                    color: "black",
                    textDecoration: "none",
                    borderBottom:
                      activeTab === "pastors"
                        ? "2px solid #CAAF74"
                        : "2px solid transparent",
                  }}
                >
                  Pastors
                </Nav.Link>{" "}
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={3}>
            <Form className="d-flex position-relative overflow-hidden">
              <FaSearch className="search-icon" />
              <FormControl
                type="search"
                placeholder="      Search Churches"
                className="rounded-pill "
                onChange={handleSearch}
              />
            </Form>
          </Col>
          <Col md={2}>
            <div className="text-center tab-icons rounded-pill">
              <FaThLarge className="mx-3" />

              <FaBars />
            </div>
          </Col>
          <Col className="">
            <Row>
              <Col>Sort by:</Col>
              <Col>Gender</Col>
              <Col>Marital Status</Col>
              <Col> Ministry</Col>
              <Col>Date</Col>
            </Row>
          </Col>
        </Row>
        {activeTab === "members" && (
          <Table borderless responsive className="mt-4 p-5">
            <thead>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>NAME</th>
                <th>GENDER</th>
                <th>MARITAL STATUS</th>
                <th>JOINED CHURCH</th>
                <th>EMAIL & PHONE NO</th>
                <th>DATE ADDED</th>
                <th>STATUS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {churchMembersDetails?.data?.items?.map((church, index) => (
                <tr key={index}>
                  <td> {index + 1} </td>
                  <td> {church?.sn} </td>
                  <td> {church?.firstname + " " + church.lastname} </td>
                  <td> {church?.gender} </td>
                  <td>{church.marital_status} </td>
                  <td>{church?.membership?.church?.name} </td>
                  <td>{church?.phone}</td>
                  <td>{church?.membership?.join_date} </td>
                  <td>
                    {church?.status === "active" ? (
                      <div className="outline-success" size="sm">
                        Active{" "}
                      </div>
                    ) : null}

                    {church?.is_active === "inactive" ? (
                      <div className="px-2  rounded outline-success" size="sm">
                        Not Active{" "}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" className=" border-0">
                        <HiDotsHorizontal />
                      </Dropdown.Toggle>

                      {/* <Dropdown.Menu>
                        <Dropdown.Item
                          className="small"
                          // onClick={() => handleView(church.id)}
                        >
                          View
                        </Dropdown.Item>

                        {church && (
                          <>
                            {church.is_active ? (
                              <Dropdown.Item
                                className="small"
                                onClick={() => handleDeactivate(church.id)}
                              >
                                Deactivate
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                className="small"
                                onClick={() => handleActivate(church.id)}
                              >
                                Activate
                              </Dropdown.Item>
                            )}
                          </>
                        )}
                      </Dropdown.Menu> */}
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Container>
    </AdminLayout>
  );
}

export default ViewChurch;
