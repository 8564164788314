import { Formik } from "formik";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { addMemberAsync, addPastorAsync } from "../../slices/user/userSlice";
import { useSelector, useDispatch } from "react-redux";

function UserRegister() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addMemberResponse = useSelector(
    (state) => state.users.addMemberResponse
  );
  const addPastorResponse = useSelector(
    (state) => state.users.addPastorResponse
  );

  if (
    addMemberResponse?.status === true ||
    addPastorResponse?.status === true
  ) {
    navigate("/user-verify");
  }
  const handleGoLogin = () => {
    window.location.href = "/user-login";
  };
  return (
    <>
      <Container className="bg-color-6">
        <div className="text-center my-4 mt-5">
          <h4 className="fw-bold">Welcome</h4>
          <p>Please sign up to begin</p>
        </div>
        <Tabs
          defaultActiveKey="member"
          id="fill-tab-example"
          className="mb-3"
          fill
        >
          <Tab eventKey="member" title="Member">
            <div className="my-5">
              <Formik
                initialValues={{
                  firstname: "",
                  lastname: "",
                  email: "",
                  phone: "",
                  password: "",
                }}
                validationSchema={Yup.object({
                  firstname: Yup.string().required("First name is required"),
                  lastname: Yup.string().required("last name is required"),
                  email: Yup.string().email("Email is required"),
                  phone: Yup.string().required("Phone is required"),
                  password: Yup.string()
                    .required("No password provided.")
                    .min(
                      6,
                      "Password is too short - should be 6 chars minimum."
                    ),
                  retypepassword: Yup.string()
                    .oneOf(
                      [Yup.ref("password"), null],
                      "Passwords do not match"
                    )
                    .required("Please confirm password."),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(addMemberAsync(values));
                  setSubmitting(false);
                }}
                validateOnChange
                validateOnBlur
                validateOnSubmit
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  Field,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mx-2">
                      <Row className="">
                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="First Name"
                              className="mb-3  text-color-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="First Name"
                                name="firstname"
                                value={values.firstname}
                                onChange={handleChange}
                                className="input-field"
                              />
                              {errors.firstname && touched.firstname ? (
                                <small className="text-danger">
                                  {errors.firstname}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>

                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Last Name"
                              className="mb-3 text-color-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Last Name"
                                name="lastname"
                                value={values.lastname}
                                onChange={handleChange}
                                className="input-field"
                              />
                              {errors.lastname && touched.lastname ? (
                                <small className="text-danger">
                                  {errors.lastname}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="">
                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Email"
                              className="mb-3 text-color-3"
                            >
                              <Form.Control
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                className="input-field"
                              />
                              {errors.email && touched.email ? (
                                <small className="text-danger">
                                  {errors.email}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Phone Number"
                              className="mb-3 text-color-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Phone Number"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                              />
                              {errors.phone && touched.phone ? (
                                <small className="text-danger">
                                  {errors.phone}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="">
                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Password"
                              className="mb-3 text-color-3"
                            >
                              <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                className="input-field"
                              />
                              {errors.password && touched.password ? (
                                <small className="text-danger">
                                  {errors.password}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Retype password "
                              className="mb-3 text-color-3 "
                            >
                              <Form.Control
                                type="password"
                                placeholder="Retype password"
                                name="retypepassword"
                                value={values.retypepassword}
                                onChange={handleChange}
                                className="input-field"
                              />
                              {errors.retypepassword &&
                              touched.retypepassword ? (
                                <small className="text-danger">
                                  {errors.retypepassword}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    <Row className="mt-3 text-center">
                      <Col md={12} xs={12}>
                        <span className="d-inline-block">
                          <small className="mx-1 small-font">
                            <label className="d-inline-block align-items-center">
                              <input
                                type="checkbox"
                                name="agreement"
                                className="me-2 align-middle"
                              />
                              <span className="align-middle text-color-4">
                                I agree to all{" "}
                                <a
                                  href="/terms-and-conditions-page"
                                  className="text-color-5"
                                >
                                  payment agreement
                                </a>{" "}
                                and{" "}
                                <a
                                  href="/privacy-policy-page"
                                  className="text-color-5"
                                >
                                  privacy policy
                                </a>
                              </span>
                            </label>
                          </small>
                        </span>
                      </Col>
                    </Row>

                    <Row className="p-2 mt-2">
                      <Col md={12}>
                        <div className="d-grid gap-2">
                          <Button
                            type="submit"
                            className=" my-4 py-3  border-0  bg-color-7"
                          >
                            <span className="text-center text-color-2 fw-bold">
                              Sign Up
                            </span>
                          </Button>
                        </div>{" "}
                      </Col>
                    </Row>
                    <div className="mb-3 text-center">
                      <div className="  p-2 text-muted">
                        Already have an account?{" "}
                        <span
                          className=" fw-bold text-color-2"
                          onClick={() => handleGoLogin()}
                        >
                          Sign in
                        </span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Tab>
          <Tab eventKey="pastor" title="Pastor">
            <div className="my-5">
              <Formik
                initialValues={{
                  firstname: "",
                  lastname: "",
                  email: "",
                  phone: "",
                  password: "",
                }}
                validationSchema={Yup.object({
                  firstname: Yup.string().required("First name is required"),
                  lastname: Yup.string().required("last name is required"),
                  email: Yup.string().required("Email is required"),
                  phone: Yup.string().required("Phone is required"),
                  password: Yup.string()
                    .required("No password provided.")
                    .min(
                      6,
                      "Password is too short - should be 6 chars minimum."
                    ),
                  retypepassword: Yup.string().oneOf(
                    [Yup.ref("password"), null],
                    "Passwords do not match"
                  ),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(addPastorAsync(values));
                  setSubmitting(false);
                }}
                validateOnChange
                validateOnBlur
                validateOnSubmit
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  Field,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mx-2">
                      <Row className="">
                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="First Name"
                              className="mb-3  text-color-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="First Name"
                                name="firstname"
                                value={values.firstname}
                                onChange={handleChange}
                                className="input-field"
                              />
                              {errors.firstname && touched.firstname ? (
                                <small className="text-danger">
                                  {errors.firstname}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>

                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Last Name"
                              className="mb-3 text-color-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Last Name"
                                name="lastname"
                                value={values.lastname}
                                onChange={handleChange}
                                className="input-field"
                              />
                              {errors.lastname && touched.lastname ? (
                                <small className="text-danger">
                                  {errors.lastname}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="">
                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Email"
                              className="mb-3 text-color-3"
                            >
                              <Form.Control
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                className="input-field"
                              />
                              {errors.email && touched.email ? (
                                <small className="text-danger">
                                  {errors.email}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Phone Number"
                              className="mb-3 text-color-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Phone Number"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                              />
                              {errors.phone && touched.phone ? (
                                <small className="text-danger">
                                  {errors.phone}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="">
                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Password"
                              className="mb-3 text-color-3"
                            >
                              <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                className="input-field"
                              />
                              {errors.password && touched.password ? (
                                <small className="text-danger">
                                  {errors.password}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12} sm={12}>
                          <Form.Group>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Retype password "
                              className="mb-3 text-color-3 "
                            >
                              <Form.Control
                                type="password"
                                placeholder="Retype password"
                                name="retypepassword"
                                value={values.retypepassword}
                                onChange={handleChange}
                                className="input-field"
                              />
                              {errors.retypepassword &&
                              touched.retypepassword ? (
                                <small className="text-danger">
                                  {errors.retypepassword}
                                </small>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    <Row className="mt-3 text-center">
                      <Col md={12} xs={12}>
                        <span className="d-inline-block">
                          <small className="mx-1 small-font">
                            <label className="d-inline-block align-items-center">
                              <input
                                type="checkbox"
                                name="agreement"
                                className="me-2 align-middle"
                              />
                              <span className="align-middle text-color-4">
                                I agree to all{" "}
                                <a
                                  href="/terms-and-conditions-page"
                                  className="text-color-5"
                                >
                                  payment agreement
                                </a>{" "}
                                and{" "}
                                <a
                                  href="/privacy-policy-page"
                                  className="text-color-5"
                                >
                                  privacy policy
                                </a>
                              </span>
                            </label>
                          </small>
                        </span>
                      </Col>
                    </Row>

                    <Row className="p-2 mt-2">
                      <Col md={12}>
                        <div className="d-grid gap-2">
                          <Button
                            type="submit"
                            className=" my-4 py-3  border-0  bg-color-7"
                          >
                            <span className="text-center text-color-2 fw-bold">
                              Sign Up
                            </span>
                          </Button>
                        </div>{" "}
                      </Col>
                    </Row>
                    <div className="mb-3 text-center">
                      <Link to="/user-login" className="  p-2 text-muted">
                        Already have an account?{" "}
                        <span className=" fw-bold text-color-2">Sign in</span>
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}

export default UserRegister;
