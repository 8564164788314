import { Button, Container, FloatingLabel, Form, Image } from "react-bootstrap";
import logo from "../../assets/images/baptust-one-logo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { resetPasswordAsync } from "../../slices/auth/authSlice";

function UserResetPassword({ match }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useParams();
  const passtoken = token.token;

  return (
    <div className="mobile-container bg-purple">
      <Container>
        <div className="mt-5 pt-5 text-center">
          <Image src={logo} className="img-fluid" />
        </div>
        <div className="text-center pt-4 mt-5">
          <h6 className="text-white">Welcome</h6>
          <p>Please fill the form below to reset your password </p>
        </div>

        <div className="mt-3 px-3">
          <Formik
            initialValues={{
              password: "",
              confirm_password: "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .required("No password provided.")
                .min(6, "Password is too short - should be 6 chars minimum."),
              confirm_password: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords do not match")
                .required("Please confirm password."),
            })}
            onSubmit={(values) => {
              dispatch(
                resetPasswordAsync({
                  credentials: values,
                  token: passtoken,
                })
              );
              navigate("/user-login");
            }}
            validateOnChange
            validateOnBlur
            validateOnSubmit
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              values,
              handleChange,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Password"
                    className="my-3"
                  >
                    <Form.Control
                      placeholder="Enter your password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      className="p-3 password-input"
                      type="password"
                    />
                    {errors.password && touched.password ? (
                      <small className="text-danger">{errors.password}</small>
                    ) : null}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Confirm Password"
                    className="my-3"
                  >
                    <Form.Control
                      placeholder="Confirm your password"
                      name="confirm_password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      className="p-3 password-input"
                      type="password"
                    />
                    {errors.confirm_password && touched.confirm_password ? (
                      <small className="text-danger">
                        {errors.confirm_password}
                      </small>
                    ) : null}
                  </FloatingLabel>
                </Form.Group>

                <div className="d-grid gap-2">
                  <Button
                    type="submit"
                    className=" my-4 py-3  border-0  bg-color-2"
                  >
                    <span className="text-center">Reset</span>
                  </Button>
                </div>
                <div className="my-4 text-center">
                  <Link to="/user-login" className="  p-2 text-white">
                    Already have an account?{" "}
                    <span className=" fw-bold text-warning">Login</span>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
}

export default UserResetPassword;
