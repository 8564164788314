import {
  DeleteRequest,
  GetRequest,
  PostRequest,
  PutRequest,
} from "../../util/apiMethods";

export const getAllChurch = async (detailed, status, page, size) => {
  const response = await GetRequest(
    `/manage/churches?detailed=${detailed}&status=${status}&page=${page}&size=${size}`
  );
  return response;
};

export const createChurch = async (body) => {
  const response = await PostRequest("/manage/churches", body);
  return response;
};

export const userCreateChurch = async (body) => {
  const response = await PostRequest("/registrations/churches", body);
  return response;
};

export const getChurchById = async (church_id, detailed) => {
  const response = await GetRequest(
    `/manage/churches/${church_id}?detailed=${detailed}`
  );
  return response;
};

export const updateChurch = async (church_id, body) => {
  const response = await PutRequest(`/manage/churches/${church_id}`, body);
  return response;
};

export const deleteChurch = async (church_id) => {
  const response = await DeleteRequest(`/manage/churches/${church_id}`);
  return response;
};
export const activateChurch = async (church_id) => {
  const response = await PutRequest(`/manage/churches/${church_id}/activate`);
  return response;
};

export const deactivateChurch = async (church_id) => {
  const response = await PutRequest(`/manage/churches/${church_id}/deactivate`);
  return response;
};

export const getAllUserChurches = async (detailed, status, page, size) => {
  const response = await GetRequest(
    `/churches?detailed=${detailed}&page=${page}&size=${size}`
  );
  return response;
};

export const getAllChurchMembers = async (
  church_id,
  detailed,
  status,
  page,
  size
) => {
  const response = await GetRequest(
    `/manage/churches/${church_id}/members?detailed=${detailed}&status=${status}&page=${page}&size=${size}`
  );
  return response;
};

export const updateChurchHistory = async (body) => {
  const response = await PutRequest("moderate/church/history", body);
  return response;
};
