import { Button, Card, Col, Container, Image, Row } from "react-bootstrap"
import AdminLayout from "../../components/layouts/admin-layout"
import icon1 from "../../assets/images/icons/all-members-icon.png"
import icon2 from "../../assets/images/icons/active-member-icon.png"
import icon3 from "../../assets/images/icons/income-icon.png"
import icon4 from "../../assets/images/icons/expenditure-icon.png"
import { BiSolidDashboard } from "react-icons/bi"
import { Link } from "react-router-dom"
import { AiFillPlusCircle } from "react-icons/ai";


function SuperAdminDashboard() {
  return (
    <AdminLayout>

      <Container fluid>

        <section>
          <Row className="my-3">
            <Col md={6}>
              <div>
                <span className="m-2 fw-bold">
                  <BiSolidDashboard />
                </span>
                Dashboard
              </div>
            </Col>
            <Col md={6}>
              <div className="text-end" >
                <Button
                  className="rounded-pill  px-3 bg-color-1 border-0"
                  as={Link}
                  to="/add-church"
                >
                  {" "}
                  <AiFillPlusCircle className="m-1" />
                  Add Church
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Card className="dash-card-1">
                <Card.Body>
                  <Row>
                    <Col md={8}>
                      <div className="">
                        <small>
                          Total Members
                        </small>
                        <h4> 4444 </h4>
                      </div>
                    </Col>
                    <Col>
                      <div className="card-1-icon text-center py-3">
                        <Image src={icon1} alt="All members" className="img-fluid" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={8}>
                      <div>
                        <small className="fw-bold">
                          Active Members
                        </small>
                        <h4>3333333</h4>
                      </div>
                    </Col>
                    <Col>
                      <div className="card-2-icon text-center py-3">
                        <Image src={icon2} alt="Active members" className="img-fluid" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={8}>
                      <div>
                        <small className="fw-bold">
                          Total Income
                        </small>
                        <h4>0 5665 </h4>
                      </div>
                    </Col>
                    <Col>
                      <div className="card-3-icon text-center py-3">
                        <Image src={icon3} alt=" Total income" className="img-fluid" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={8}>
                      <div>
                        <small>
                          Total Expenditure
                        </small>
                        <h4>4444 </h4>
                      </div>
                    </Col>
                    <Col>
                      <div className="card-4-icon text-center py-3">
                        <Image src={icon4} alt=" Total Expenditure" className="img-fluid" />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

          </Row>
        </section>
      </Container>
    </AdminLayout>
  )
}

export default SuperAdminDashboard