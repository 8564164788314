import React from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

function CookiePolicyModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body className="p-3">
        <Modal.Title className="pb-3 fw-bold">
          Privacy Preference Center
        </Modal.Title>

        <div>
          <p className=" text-black">
            When you visit websites, they may store or retrieve data in your
            browser. This storage is often necessary for the basic functionality
            of the website. The storage may be used for marketing, analytics,
            and personalization of the site, such as storing your preferences.
            Privacy is important to us, so you have the option of disabling
            certain types of storage that may not be necessary for the basic
            functioning of the website. Blocking categories may impact your
            experience on the website.
          </p>
          <Button className="cookie-reject-btn p-2">Reject all cookies</Button>
          <Button className="cookie-accept-btn p-2 mx-3">
            Allow all cookies
          </Button>
        </div>
        <div className="mt-3">
          <h5 className="fw-bold mb-4">
            Manage Consent Preferences by Category
          </h5>
          <Row className="my-5 ">
            <Col md={6} className="fw-bold">
              Essential
            </Col>
            <Col md={6} className="text-end fw-bold">
              Always Active
            </Col>
          </Row>
          <p className=" text-black mb-4">
            These items are required to enable basic website functionality.
          </p>
          <hr />
          <Row>
            <Col md={10}>
              <h6 className="fw-bold">Marketing</h6>
              <p className="text-black">
                These items are used to deliver advertising that is more
                relevant to you and your interests. They may also be used to
                limit the number of times you see an advertisement and measure
                the effectiveness of advertising campaigns. Advertising networks
                usually place them with the website operator’s permission.
              </p>
            </Col>
            <Col md={2}>
              <div>
                <Form.Check type="switch" id="Marketing" />
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={10}>
              <h6 className="fw-bold">Personalization</h6>
              <p className="text-black">
                These items allow the website to remember choices you make (such
                as your user name, language, or the region you are in) and
                provide enhanced, more personal features. For example, a website
                may provide you with local weather reports or traffic news by
                storing data about your current location.
              </p>
            </Col>
            <Col md={2}>
              <div>
                <Form.Check type="switch" id="Personalization" />
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={10}>
              <h6 className="fw-bold">Analytics</h6>
              <p className="text-black">
                These items help the website operator understand how its website
                performs, how visitors interact with the site, and whether there
                may be technical issues. This storage type usually doesn’t
                collect information that identifies a visitor.
              </p>
            </Col>
            <Col md={2}>
              <div>
                <Form.Check type="switch" id="Analytics" />
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CookiePolicyModal;
